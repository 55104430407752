import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {BuySellBlock} from "../BuySellBlock";

export const RightColumn = ({
  frameClassName,
  frame = "/img/dropdown-arrow.svg",
  img = "/img/right-column-wallet-icon.svg",
  vectorClassName,
  columnClassName,
  vectorClassNameOverride,
  columnClassNameOverride,
}) => {
  return (
    <div className={`element-columns right-column`}>
      <div className="column">
        <div className="text-wrapper">Configuration</div>
        <div className="frame">
          <BuySellBlock/>
          <div className="div-2">
            <div className="column-3">
              <div className="text-wrapper-4">Limit</div>
            </div>
            <div className="market-wrapper">
              <div className="market">Market</div>
            </div>
            <div className="frame-wrapper">
              <div className={`frame-2 ${frameClassName}`}>
                <div className="group">
                  <div className="text-wrapper-5">Stop-limit</div>
                </div>
                <img className="img" alt="Frame" src={frame} />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-3">
          <div className="frame-4">
            <img className="frame-5" alt="Frame" src={img} />
            <div className="text-wrapper-6">3.7 Eth</div>
          </div>
          <div className="frame-wrapper-2">
            <div className="frame-6">
              <div className="text-wrapper-6">Price</div>
              <div className="frame-7">
                <div className="text-wrapper-7">0.0005</div>
                <div className="text-wrapper-6">Eth</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-8">
        <div className="column-4">
          <div className="frame-9">
            <div className="buy">Buy</div>
            <div className="text-wrapper-8">14.9</div>
          </div>
        </div>
        <img className={`vector ${vectorClassName}`} alt="Vector" src="/img/right-column-vertical-line.svg" />
        <div className={`column-4 ${columnClassName}`}>
          <div className="text-wrapper-9">Amount</div>
        </div>
        <img className={`vector ${vectorClassNameOverride}`} alt="Vector" src="/img/right-column-vertical-line.svg" />
        <div className={`column-4 ${columnClassNameOverride}`}>
          <div className="text-wrapper-9">Sell</div>
        </div>
      </div>
      <div className="column-5">
        <div className="frame-3">
          <div className="frame-wrapper-2">
            <div className="frame-6">
              <div className="text-wrapper-6">Total</div>
              <div className="frame-7">
                <div className="text-wrapper-10">2.1</div>
                <div className="text-wrapper-6">Eth</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-10">
          <div className="text-wrapper-11">BUY</div>
        </div>
        <div className="frame-11">
          <div className="frame-12">
            <div className="div-2">
              <div className="text-wrapper-10">Assets</div>
              <div className="frame-13">
                <div className="text-wrapper-6">Buy With</div>
                <div className="text-wrapper-12">ETH</div>
                <img className="group-2" alt="Group" src="/img/arrow-right.png" />
              </div>
            </div>
          </div>
          <div className="frame-12">
            <div className="div-2">
              <div className="text-wrapper-10">ETH Available:</div>
              <div className="text-wrapper-10">0.000000000</div>
            </div>
            <div className="div-2">
              <div className="text-wrapper-10">USDT Available:</div>
              <div className="text-wrapper-10">38.78946616</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RightColumn.propTypes = {
  frame: PropTypes.string,
  img: PropTypes.string,
};
