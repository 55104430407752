import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {MoreButtonOld} from "./MoreButtonOld";

export const MoreButton = ({isMore = false, onClick = () => {}}) => {
  const caption = isMore ? "More info" : "Less info";
  const icon = isMore ? "/img/icon-1.svg" : "/img/icon-2.svg";
  return (
    <MoreButtonOld caption={caption} className={`fullwidth${!isMore ? ' is-less' : ''}`} icon={icon} onClick={onClick}/>
  )
};
