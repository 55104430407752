/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormField } from "../FormField";
import { FormFieldCompact } from "../FormFieldCompact";
import { RectangleButton } from "../RectangleButton";
import "./style.css";

export const PageContentCopyCeptionCopyWallet = ({ screen }) => {
  return (
    <div className={`page-content-copy-ception-copy-wallet ${screen}`}>
      {screen === "desktop" && (
        <>
          <div className="frame">
            <RectangleButton caption="Copied Wallet 1" className="rectangle-button-instance" type="selected" />
            <RectangleButton caption="Copied Wallet 2" className="rectangle-button-instance" type="default" />
            <RectangleButton caption="Copied Wallet 3" className="rectangle-button-instance" type="default" />
          </div>
          <div className="frame-wrapper">
            <div className="div">
              <FormField className="instance-node" label="Wallet Name" />
              <FormField className="instance-node" label="Wallet Address to track / Copy" />
              <FormField className="instance-node" label="Amount of Gas" />
              <div className="copy-ception-copy">
                <div className="text-wrapper">Copy by or sell</div>
                <div className="copy-or-sell">
                  <div className="copy-or-sell-block">
                    <div className="buy-wrapper">
                      <div className="buy">Buy</div>
                    </div>
                  </div>
                  <img className="vector" alt="Vector" src="/img/vector-68-2.svg" />
                  <div className="copy-or-sell-block">
                    <div className="text-wrapper-2">Sell</div>
                  </div>
                  <img className="vector" alt="Vector" src="/img/vector-69.svg" />
                  <div className="copy-or-sell-block">
                    <div className="text-wrapper-2">Track Only</div>
                  </div>
                </div>
              </div>
              <FormField className="instance-node" label="Max Slippage" />
              <FormField className="instance-node" label="Amount of ETH To use per Trade" />
              <FormField className="instance-node" label="Profit %" />
              <FormField className="instance-node" label="Stop Lose" />
            </div>
          </div>
          <div className="activate-deactivate-wrapper">
            <div className="activate-deactivate">ACTIVATE/ DEACTIVATE WALLET</div>
          </div>
        </>
      )}

      {screen === "mobile" && (
        <div className="copy-ception-copy-wrapper">
            <div className="copy-ception-copy-3">
              <RectangleButton caption="Copied Wallet 1" className="rectangle-button-2" type="selected" />
              <RectangleButton caption="Copied Wallet 2" className="rectangle-button-3" type="default" />
              <RectangleButton caption="Copied Wallet 3" className="rectangle-button-3" type="default" />
            </div>
            <div className="copy-ception-copy-4">
              <FormFieldCompact className="instance-node" text="Wallet Name" />
              <FormFieldCompact className="instance-node" text="Wallet Address to track / Copy" />
              <FormFieldCompact className="instance-node" text="Amount of Gas" />
              <FormFieldCompact className="instance-node" text="Max Slippage" />
              <FormFieldCompact className="instance-node" text="Amount of ETH To use per Trade" />
              <div className="copy-ception-copy-5">
                <div className="text-wrapper-3">Copy by or sell</div>
                <div className="copy-or-sell-2">
                  <div className="copy-or-sell-block">
                    <div className="buy-wrapper">
                      <div className="buy">Buy</div>
                    </div>
                  </div>
                  <img className="vector" alt="Vector" src="/img/vector-68-2.svg" />
                  <div className="copy-or-sell-block">
                    <div className="text-wrapper-2">Sell</div>
                  </div>
                  <img className="vector" alt="Vector" src="/img/vector-69.svg" />
                  <div className="copy-or-sell-block">
                    <div className="text-wrapper-2">Track Only</div>
                  </div>
                </div>
              </div>
              <div className="copy-ception-copy-5">
                <div className="text-wrapper-3">Sell Rules</div>
              </div>
              <FormFieldCompact className="instance-node" text="Copy by or sell" />
              <FormFieldCompact className="instance-node" text="Profit %" />
              <FormFieldCompact className="instance-node" text="Stop Lose" />
              <div className="div-wrapper">
                <div className="activate-deactivate">ACTIVATE/ DEACTIVATE WALLET</div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

PageContentCopyCeptionCopyWallet.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
