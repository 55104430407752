/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const AddressForm = () => {
  return (
    <div className="address-form">
      <div className="form">
        <input className="ETH-CONTRACT-ADDRESS" placeholder={"ETH-CONTACT-ADDRESS"} />
        <button className="button">
          <div className="text-wrapper">SNIPE</div>
        </button>
      </div>
    </div>
  );
};
