/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chart2 = ({ className }) => {
  return (
    <svg
      className={`chart-2 ${className}`}
      fill="none"
      height="111"
      viewBox="0 0 191 111"
      width="191"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M-8.99947 66.5335C-15.7622 67.2964 -14.3322 67.9123 -18.5222 69.429V110.104H210.022V37.4409C210.022 37.4409 203.723 47.3751 198.785 46.9546C191.142 46.3037 194.466 41.3402 186.787 41.7152C181.077 41.994 180.121 55.1478 171.931 54.8138C161.586 54.3918 165.649 47.8521 155.362 46.9546C148.138 46.3243 148.352 36.7503 141.078 36.6137C129.84 36.4026 128.259 28.575 118.604 27.9272C95.8195 26.3986 116.685 1.82157 93.8455 2.83311C71.5576 3.82021 96.437 43.3976 74.2288 41.7152C68.6356 41.2915 66.2774 37.5734 60.7066 38.1303C53.8265 38.8181 54.3946 45.4776 47.7557 46.9546C39.0774 48.8854 34.5361 40.3234 25.6631 41.7152C17.1633 43.0484 17.6001 52.3145 8.90317 52.3319C-2.80895 52.3554 2.57306 65.2282 -8.99947 66.5335Z"
        fill="url(#paint0_linear_2004_29477)"
        opacity="0.1"
      />
      <path
        className="path"
        d="M-18.5222 69.429C-14.3322 67.9123 -15.7622 67.2964 -8.99947 66.5335C2.57305 65.2282 -2.80895 52.3554 8.90317 52.3319C17.6001 52.3145 17.1633 43.0484 25.6631 41.7152C34.5361 40.3234 39.0774 48.8854 47.7557 46.9546C54.3946 45.4775 53.8265 38.8181 60.7066 38.1303C66.2774 37.5734 68.6356 41.2915 74.2288 41.7152C96.437 43.3976 71.5576 3.82021 93.8455 2.83311C116.685 1.82157 95.8195 26.3986 118.604 27.9272C128.259 28.5749 129.84 36.4026 141.078 36.6137C148.352 36.7503 148.138 46.3243 155.362 46.9546C165.649 47.8521 161.586 54.3917 171.931 54.8138C180.121 55.1478 181.077 41.994 186.787 41.7152C194.466 41.3402 191.142 46.3037 198.785 46.9546C203.723 47.3751 210.022 37.4409 210.022 37.4409"
        stroke="url(#paint1_linear_2004_29477)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M107.939 12.4844H142.22V110.103H107.939V12.4844Z"
        fill="url(#paint2_linear_2004_29477)"
        opacity="0.5"
      />
      <g className="g" filter="url(#filter0_d_2004_29477)">
        <mask className="mask" fill="white" id="path-4-inside-1_2004_29477">
          <ellipse className="ellipse-3" cx="125.08" cy="29.0308" rx="7.61815" ry="5.51519" />
        </mask>
        <ellipse className="ellipse-3" cx="125.08" cy="29.0308" fill="white" rx="7.61815" ry="5.51519" />
        <path
          className="path"
          d="M121.698 29.0308C121.698 26.1452 123.335 24.5139 124.017 24.0205C124.391 23.7498 124.68 23.639 124.825 23.5946C124.978 23.5477 125.063 23.546 125.08 23.546V45.546C129.245 45.546 133.466 44.3398 136.918 41.8408C140.356 39.3513 143.698 34.9624 143.698 29.0308H121.698ZM125.08 23.546C125.098 23.546 125.182 23.5477 125.336 23.5946C125.48 23.639 125.77 23.7498 126.144 24.0205C126.825 24.5139 128.462 26.1452 128.462 29.0308H106.462C106.462 34.9624 109.804 39.3513 113.243 41.8408C116.695 44.3398 120.916 45.546 125.08 45.546V23.546ZM128.462 29.0308C128.462 31.9165 126.825 33.5478 126.144 34.0411C125.77 34.3119 125.48 34.4227 125.336 34.467C125.182 34.514 125.098 34.5156 125.08 34.5156V12.5156C120.916 12.5156 116.695 13.7218 113.243 16.2208C109.804 18.7103 106.462 23.0992 106.462 29.0308H128.462ZM125.08 34.5156C125.063 34.5156 124.978 34.514 124.825 34.467C124.68 34.4227 124.391 34.3119 124.017 34.0411C123.335 33.5478 121.698 31.9165 121.698 29.0308H143.698C143.698 23.0992 140.356 18.7103 136.918 16.2208C133.466 13.7218 129.245 12.5156 125.08 12.5156V34.5156Z"
          fill="url(#paint3_linear_2004_29477)"
          mask="url(#path-4-inside-1_2004_29477)"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0312"
          id="filter0_d_2004_29477"
          width="65.2363"
          x="92.4622"
          y="3.51562"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29477" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29477"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29477"
          x1="95.75"
          x2="95.75"
          y1="2.80273"
          y2="110.104"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29477"
          x1="95.75"
          x2="95.75"
          y1="2.80273"
          y2="69.429"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29477"
          x1="125.08"
          x2="125.08"
          y1="12.4844"
          y2="110.103"
        >
          <stop className="stop" offset="0.0104167" stopColor="#FE6C6C" stopOpacity="0" />
          <stop className="stop" offset="0.28125" stopColor="#FE6C6C" />
          <stop className="stop" offset="0.666667" stopColor="#FE464B" />
          <stop className="stop" offset="0.984375" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29477"
          x1="125.08"
          x2="125.08"
          y1="23.5156"
          y2="34.546"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
