/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini2 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-2 ${className}`}
      fill="none"
      height="111"
      viewBox="0 0 128 111"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M-11.8338 66.5335C-16.7297 67.2964 -15.6944 67.9123 -18.7278 69.429V110.104H146.728V37.4409C146.728 37.4409 142.167 47.3751 138.593 46.9546C133.059 46.3037 135.466 41.3402 129.907 41.7152C125.773 41.994 125.081 55.1478 119.152 54.8138C111.662 54.3918 114.604 47.8521 107.156 46.9546C101.926 46.3243 102.082 36.7503 96.8154 36.6137C88.6797 36.4026 87.535 28.575 80.5456 27.9272C64.0504 26.3986 79.1562 1.82157 62.6212 2.83311C46.4859 3.82021 64.4974 43.3976 48.4196 41.7152C44.3704 41.2915 42.6632 37.5734 38.6302 38.1303C33.6493 38.8181 34.0606 45.4776 29.2544 46.9546C22.9717 48.8854 19.6839 40.3234 13.2603 41.7152C7.10685 43.0484 7.4231 52.3145 1.12689 52.3319C-7.35214 52.3554 -3.45582 65.2282 -11.8338 66.5335Z"
        fill="url(#paint0_linear_2004_29766)"
        opacity="0.1"
      />
      <path
        className="path"
        d="M-18.7278 69.429C-15.6944 67.9123 -16.7297 67.2964 -11.8338 66.5335C-3.45582 65.2282 -7.35214 52.3554 1.12689 52.3319C7.42309 52.3145 7.10685 43.0484 13.2603 41.7152C19.6839 40.3234 22.9717 48.8854 29.2544 46.9546C34.0606 45.4775 33.6493 38.8181 38.6302 38.1303C42.6632 37.5734 44.3704 41.2915 48.4196 41.7152C64.4974 43.3976 46.4859 3.82021 62.6212 2.83311C79.1563 1.82157 64.0504 26.3986 80.5456 27.9272C87.535 28.5749 88.6797 36.4026 96.8154 36.6137C102.082 36.7503 101.926 46.3243 107.156 46.9546C114.604 47.8521 111.662 54.3917 119.152 54.8138C125.081 55.1478 125.773 41.994 129.907 41.7152C135.466 41.3402 133.059 46.3037 138.593 46.9546C142.168 47.3751 146.728 37.4409 146.728 37.4409"
        stroke="url(#paint1_linear_2004_29766)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M72.824 12.4844H97.6423V110.103H72.824V12.4844Z"
        fill="url(#paint2_linear_2004_29766)"
        opacity="0.5"
      />
      <g className="g" filter="url(#filter0_d_2004_29766)">
        <circle className="circle-4" cx="85.2336" cy="29.0308" fill="white" r="5.51519" />
        <circle
          className="circle-4"
          cx="85.2336"
          cy="29.0308"
          r="5.5"
          stroke="url(#paint3_linear_2004_29766)"
          strokeWidth="0.0303764"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0312"
          id="filter0_d_2004_29766"
          width="61.0304"
          x="54.7184"
          y="3.51562"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29766" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29766"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29766"
          x1="64"
          x2="64"
          y1="2.80273"
          y2="110.104"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29766"
          x1="64"
          x2="64"
          y1="2.80273"
          y2="69.429"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29766"
          x1="85.2331"
          x2="85.2331"
          y1="12.4844"
          y2="110.103"
        >
          <stop className="stop" offset="0.0104167" stopColor="#FE6C6C" stopOpacity="0" />
          <stop className="stop" offset="0.28125" stopColor="#FE6C6C" />
          <stop className="stop" offset="0.666667" stopColor="#FE464B" />
          <stop className="stop" offset="0.984375" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29766"
          x1="85.2336"
          x2="85.2336"
          y1="23.5156"
          y2="34.546"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
