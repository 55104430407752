import PropTypes from "prop-types";
import React from "react";
import { Mission } from "../Mission";
import "./style.css";
import {Missions} from "../Missions/Missions";

export const PageContentMarketCeptionMissions = ({ screen, className }) => {
  return (
    <div className={`page-content-market-ception-missions screen-${screen} ${className}`}>
      <div className="caption">Market Ception Missions</div>
      <Missions screen={screen}/>
    </div>
  );
};

PageContentMarketCeptionMissions.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
