/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TabItem = ({ caption = "Caption", state, className, onClick = (isActive) => {} }) => {
  return (
    <div className={`tab-item ${className}`} onClick={() => onClick(state === "active")}>
      <div className={`tab-menu-item ${state}`}>
        <div className="caption">{caption}</div>
      </div>
    </div>
  );
};

TabItem.propTypes = {
  caption: PropTypes.string,
  state: PropTypes.oneOf(["active", "default"]),
};
