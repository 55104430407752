/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {Mission} from "../Mission";
import {Missions} from "../Missions/Missions";

export const PageContentGameCeptionMissions = ({ screen, className }) => {
  return (
    <div className={`page-content-game-ception-missions screen-${screen} `}>
      <div className="caption">Game Ception Missions</div>
      <Missions screen={screen}/>
    </div>
  );
};

PageContentGameCeptionMissions.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
