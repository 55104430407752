/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {LogoCoingecko} from "../../icons/LogoCoingecko";
import {LogoTelegram} from "../../icons/LogoTelegram";
import {LogoYoutube} from "../../icons/LogoYoutube";
import {LogoGitbook} from "../../icons/LogoGitbook";
import {LogoX} from "../../icons/LogoX";
import {LogoCoinmarketcap} from "../../icons/LogoCoinmarketcap";

export const SocialMission = ({
  pointsInfo = "Points info",
  coingecko = false,
  telegram = false,
  youtube = false,
  caption = "Caption",
  gitbook = false,
  twitterX = false,
  coinmarketcap = false,
  className,
}) => {
  return (
    <div className={`social-mission ${className}`}>
      <div className="social-mission-texts">
        <div className="caption">{caption}</div>
        <div className="points-info-wrapper">
          <div className="points-info">{pointsInfo}</div>
        </div>
      </div>
      {coingecko && <LogoCoingecko className="logo-coingecko" />}
      {telegram && <LogoTelegram className="logo-telegram"/> }
      {youtube && <LogoYoutube className="logo-youtube"/> }
      {gitbook && <LogoGitbook className="logo-gitbook"/> }
      {twitterX && <LogoX className="logo-x"/> }
      {coinmarketcap && <LogoCoinmarketcap className="logo-coinmarketcap"/> }
    </div>
  );
};

SocialMission.propTypes = {
  pointsInfo: PropTypes.string,
  coingecko: PropTypes.bool,
  telegram: PropTypes.bool,
  youtube: PropTypes.bool,
  caption: PropTypes.string,
  gitbook: PropTypes.bool,
  twitterX: PropTypes.bool,
  coinmarketcap: PropTypes.bool,
};
