import React, {useState} from "react";
import "./style.css";

export const BuySellBlock = ({isBuy = true}) => {
  const [isBuySelected, setIsBuySelected] = useState(isBuy)
  return (
    <div className="buy-sell-block">
      <div className="buy-sell-container">
        <div className={`buy-button buy-sell-button${isBuySelected ? ' selected' : ''}`} onClick={() => setIsBuySelected(true)}>
          <div className="button-caption">BUY</div>
        </div>
        <div className={`sell-button buy-sell-button${isBuySelected ? '' : ' selected'}`} onClick={() => setIsBuySelected(false)}>
          <div className="button-caption">SELL</div>
        </div>
      </div>
    </div>
  );
};
