/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const CircleGraph7 = ({ color = "url(#paint0_linear_2004_30122)", className }) => {
  return (
    <svg
      className={`circle-graph-7 ${className}`}
      fill="none"
      height="71"
      viewBox="0 0 70 71"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle-4" cx="35" cy="35.9531" r="33.5" stroke="#02FFFA" strokeWidth="3" />
      <path
        className="path"
        d="M64.546 38.0947C64.546 53.7331 51.318 66.4105 35.0005 66.4105C18.683 66.4105 5.45508 53.7331 5.45508 38.0947C5.45508 22.4563 10.0356 35.5627 26.3531 35.5627C42.4469 34.1813 64.546 22.4563 64.546 38.0947Z"
        fill={color}
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_30158"
          x1="64.546"
          x2="2.31707"
          y1="30.0469"
          y2="63.9108"
        >
          <stop className="stop" stopColor="#02FFFA" />
          <stop className="stop" offset="1" stopColor="#006F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

CircleGraph7.propTypes = {
  color: PropTypes.string,
};
