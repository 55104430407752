/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const TypeHeader = ({ className }) => {
  return (
    <div className={`type-header ${className}`}>
      <div className="content-2">
        <div className="text-4">{""}</div>
      </div>
    </div>
  );
};
