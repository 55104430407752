/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PageContentGameCeptionOverview = ({ screen, className }) => {
  return (
    <div className={`page-content-game-ception-overview ${screen} ${className}`}>
      {screen === "mobile" && (
        <>
          <div className="game-ception">
            <div className="div">
              <img className="img" alt="Icon telegram" src="/img/icon-telegram.svg" />
              <img className="img" alt="Icon x" src="/img/icon-x.svg" />
              <img className="img" alt="Icon instagram" src="/img/icon-instagram.svg" />
              <img className="img" alt="Icon medium" src="/img/icon-medium.svg" />
              <img className="img" alt="Icon github" src="/img/icon-github.svg" />
              <img className="img" alt="Icon reddit" src="/img/icon-reddit.svg" />
            </div>
            <div className="div-wrapper">
              <div className="text-wrapper">Add Social</div>
            </div>
          </div>
          <div className="game-ception-2">
            <div className="game-ception-3">
              <div className="game-ception-4">
                <div className="text-wrapper-2">Join TG</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
              </div>
              <div className="game-ception-4">
                <div className="text-wrapper-2">Add TG Bot</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick-disabled.svg" />
              </div>
              <div className="game-ception-5">
                <div className="text-wrapper-2">Join TG announcement Channel</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
              </div>
            </div>
            <div className="game-ception-3">
              <div className="game-ception-4">
                <div className="text-wrapper-2">Follow Gitbook</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
              </div>
              <div className="game-ception-4">
                <div className="text-wrapper-2">Follow Twitter</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
              </div>
              <div className="game-ception-4">
                <div className="text-wrapper-2">Follow Medium</div>
                <div className="frame">
                  <div className="text-wrapper-3">100 Point</div>
                </div>
                <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
              </div>
            </div>
          </div>
        </>
      )}

      {screen === "desktop" && (
        <>
          <div className="text-wrapper-4">Overview</div>
          <div className="game-ception-6">
            <div className="game-ception-7">
              <img className="img" alt="Icon telegram" src="/img/icon-telegram.svg" />
              <img className="img" alt="Icon x" src="/img/icon-x.svg" />
              <img className="img" alt="Icon instagram" src="/img/icon-instagram.svg" />
              <img className="img" alt="Icon medium" src="/img/icon-medium.svg" />
              <img className="img" alt="Icon github" src="/img/icon-github.svg" />
              <img className="img" alt="Icon reddit" src="/img/icon-reddit.svg" />
            </div>
            <div className="div-wrapper">
              <div className="text-wrapper">Add Social</div>
            </div>
          </div>
          <div className="game-ception-8">
            <div className="game-ception-9">
              <div className="text-wrapper-5">Join TG</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
            </div>
            <div className="game-ception-9">
              <div className="text-wrapper-5">Add TG Bot</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick disabled" src="/img/circle-tick-disabled.svg" />
            </div>
            <div className="game-ception-10">
              <div className="text-wrapper-5">Join TG announcement Channel</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
            </div>
          </div>
          <div className="game-ception-8">
            <div className="game-ception-4">
              <div className="text-wrapper-5">Follow Gitbook</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
            </div>
            <div className="game-ception-4">
              <div className="text-wrapper-5">Follow Twitter</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
            </div>
            <div className="game-ception-4">
              <div className="text-wrapper-5">Follow Medium</div>
              <div className="frame">
                <div className="text-wrapper-3">100 Point</div>
              </div>
              <img className="instance-node" alt="Circle tick" src="/img/circle-tick.svg" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PageContentGameCeptionOverview.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
