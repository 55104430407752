/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini22 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-2-2 ${className}`}
      fill="none"
      height="111"
      viewBox="0 0 144 111"
      width="144"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M-3.8338 66.7308C-8.72967 67.4936 -7.69443 68.1096 -10.7278 69.6263V110.301H154.728V37.6382C154.728 37.6382 150.167 47.5723 146.593 47.1519C141.059 46.501 143.466 41.5375 137.907 41.9125C133.773 42.1913 133.081 55.3451 127.152 55.011C119.662 54.589 122.604 48.0494 115.156 47.1519C109.926 46.5216 110.082 36.9475 104.815 36.8109C96.6797 36.5999 95.535 28.7722 88.5456 28.1245C72.0504 26.5959 87.1562 2.01883 70.6212 3.03038C54.4859 4.01748 72.4974 43.5949 56.4196 41.9125C52.3704 41.4888 50.6632 37.7707 46.6302 38.3276C41.6493 39.0154 42.0606 45.6748 37.2544 47.1519C30.9717 49.0827 27.6839 40.5207 21.2603 41.9125C15.1069 43.2457 15.4231 52.5118 9.12689 52.5292C0.647861 52.5526 4.54418 65.4254 -3.8338 66.7308Z"
        fill="url(#paint0_linear_2004_29650)"
        opacity="0.1"
      />
      <path
        className="path"
        d="M-10.7278 69.6263C-7.69443 68.1096 -8.72967 67.4936 -3.8338 66.7308C4.54418 65.4254 0.647861 52.5526 9.12689 52.5292C15.4231 52.5118 15.1069 43.2457 21.2603 41.9125C27.6839 40.5207 30.9717 49.0827 37.2544 47.1519C42.0606 45.6748 41.6493 39.0153 46.6302 38.3276C50.6632 37.7707 52.3704 41.4887 56.4196 41.9125C72.4974 43.5949 54.4859 4.01748 70.6212 3.03038C87.1563 2.01883 72.0504 26.5959 88.5456 28.1245C95.535 28.7722 96.6797 36.5999 104.815 36.8109C110.082 36.9475 109.926 46.5216 115.156 47.1519C122.604 48.0494 119.662 54.589 127.152 55.011C133.081 55.3451 133.773 42.1913 137.907 41.9125C143.466 41.5375 141.059 46.501 146.593 47.1519C150.168 47.5723 154.728 37.6382 154.728 37.6382"
        stroke="url(#paint1_linear_2004_29650)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M80.824 12.6816H105.642V110.3H80.824V12.6816Z"
        fill="url(#paint2_linear_2004_29650)"
        opacity="0.5"
      />
      <g className="g" filter="url(#filter0_d_2004_29650)">
        <circle className="circle-4" cx="93.2336" cy="29.2281" fill="white" r="5.51519" />
        <circle
          className="circle-4"
          cx="93.2336"
          cy="29.2281"
          r="5.5"
          stroke="url(#paint3_linear_2004_29650)"
          strokeWidth="0.0303764"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0312"
          id="filter0_d_2004_29650"
          width="61.0304"
          x="62.7184"
          y="3.71289"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29650" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29650"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29650"
          x1="72"
          x2="72"
          y1="3"
          y2="110.301"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29650"
          x1="72"
          x2="72"
          y1="3"
          y2="69.6263"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29650"
          x1="93.2331"
          x2="93.2331"
          y1="12.6816"
          y2="110.3"
        >
          <stop className="stop" offset="0.0104167" stopColor="#FE6C6C" stopOpacity="0" />
          <stop className="stop" offset="0.28125" stopColor="#FE6C6C" />
          <stop className="stop" offset="0.666667" stopColor="#FE464B" />
          <stop className="stop" offset="0.984375" stopColor="#FE464B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29650"
          x1="93.2336"
          x2="93.2336"
          y1="23.7129"
          y2="34.7433"
        >
          <stop className="stop" stopColor="#FE6C6C" />
          <stop className="stop" offset="1" stopColor="#FE464B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
