import React, {useState} from "react";
import "./style.css";
import {DropdownMenu} from "../DropdownMenu";
import {DropdownArrow} from "../../icons/DropdownArrow";
import {PAGE_CONFIGURATION, PAGE_HOME, PAGE_WALLET_MANAGEMENT} from "../../constants";
import {UserMenuPanel} from "../UserMenuPanel";

export const HeaderPanel = ({caption = "Dashboard", className, currentPage, currentProject = 'none', onProjectChange = () =>  {}, onPageChange = () => {} }) => {
  const [isDropdownMenu, setIsDropdownMenu] = useState(false);
  const handleDropdownMenu = () => setIsDropdownMenu(!isDropdownMenu);
  const handleProjectChange = project => {
    onProjectChange(project);
    setIsDropdownMenu(false);
  }

  const MenuItem = (caption, page, disabled = false) => (
    <div className={`header-menu-item${currentPage === page ? ' active' : ''}${disabled ? ' disabled' : ''}`}
         onClick={() => onPageChange(page)}>
      {caption}
    </div>
  )

  return (
    <div className={`header-panel-desktop ${className}`}>
      <div className="header-logo">
        <img className="tradeception" alt={caption + ' logo'} src="/img/tradeception-2.png" />
        <div className="tradeception-2">{caption}</div>
      </div>
      <div className="header-menu">
        {MenuItem('Home', PAGE_HOME)}
        {MenuItem('Configuration', PAGE_CONFIGURATION)}
        {MenuItem('Wallet Management', PAGE_WALLET_MANAGEMENT)}
        <div className="menu-item-dropdown" onClick={handleDropdownMenu}>
          <div className="header-menu-item-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="solar:code-scan-bold-duotone">
                <path id="Vector" d="M3.68483 3.68483C3.3335 4.03616 3.3335 4.60216 3.3335 5.7335C3.3335 6.4875 3.3335 6.86483 3.5675 7.0995C3.80216 7.3335 4.18016 7.3335 4.9335 7.3335H5.7335C6.4875 7.3335 6.86483 7.3335 7.0995 7.0995C7.3335 6.86483 7.3335 6.48683 7.3335 5.7335V4.9335C7.3335 4.1795 7.3335 3.80216 7.0995 3.5675C6.86483 3.3335 6.48683 3.3335 5.7335 3.3335C4.60216 3.3335 4.03616 3.3335 3.68483 3.68483ZM3.68483 12.3155C3.3335 11.9642 3.3335 11.3982 3.3335 10.2668C3.3335 9.51283 3.3335 9.1355 3.5675 8.90083C3.80216 8.66683 4.18016 8.66683 4.9335 8.66683H5.7335C6.4875 8.66683 6.86483 8.66683 7.0995 8.90083C7.3335 9.1355 7.3335 9.51283 7.3335 10.2668V11.0668C7.3335 11.8208 7.3335 12.1982 7.0995 12.4322C6.86483 12.6668 6.48683 12.6668 5.7335 12.6668C4.60216 12.6668 4.03616 12.6668 3.68483 12.3155ZM8.66683 4.9335C8.66683 4.1795 8.66683 3.80216 8.90083 3.5675C9.13616 3.3335 9.5135 3.3335 10.2668 3.3335C11.3982 3.3335 11.9642 3.3335 12.3155 3.68483C12.6668 4.03616 12.6668 4.60216 12.6668 5.7335C12.6668 6.4875 12.6668 6.86483 12.4322 7.0995C12.1988 7.3335 11.8208 7.3335 11.0668 7.3335H10.2668C9.51283 7.3335 9.1355 7.3335 8.90083 7.0995C8.66683 6.86483 8.66683 6.48683 8.66683 5.7335V4.9335ZM8.9015 12.4328C8.66683 12.1982 8.66683 11.8202 8.66683 11.0668V10.2668C8.66683 9.51283 8.66683 9.1355 8.90083 8.90083C9.13616 8.66683 9.5135 8.66683 10.2668 8.66683H11.0668C11.8208 8.66683 12.1982 8.66683 12.4322 8.90083C12.6668 9.1355 12.6668 9.51283 12.6668 10.2668C12.6668 11.3982 12.6668 11.9642 12.3155 12.3155C11.9642 12.6668 11.3982 12.6668 10.2668 12.6668C9.51283 12.6668 9.1355 12.6668 8.90083 12.4322" fill="#ffffff"/>
              </g>
            </svg>
          </div>
          <div className="header-menu-item">Products</div>
          <DropdownArrow className="menu-item-dropdown-icon"/>
          {isDropdownMenu && <DropdownMenu currentProject={currentProject} onMenuItemClick={handleProjectChange}/>}
        </div>
        <UserMenuPanel/>
      </div>
    </div>
  );
};
