/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const DivWrapper = ({ className, buyIcon = "/img/buy-icon-1.png" }) => {
  return (
    <div className={`div-wrapper ${className}`}>
      <div className="total-input-wrapper">
        <div className="total-input">
          <div className="input-row">
            <div className="text-wrapper-15">Total</div>
            <div className="value-container">
              <div className="text-wrapper-16">2.1</div>
              <div className="text-wrapper-15">Eth</div>
            </div>
          </div>
        </div>
      </div>
      <button className="button">
        <div className="text-wrapper-17">BUY</div>
      </button>
      <div className="total-info-block">
        <div className="div-2">
          <div className="div-3">
            <div className="text-wrapper-16">Assets</div>
            <div className="buy-block">
              <div className="text-wrapper-15">Buy With</div>
              <div className="text-wrapper-18">ETH</div>
              <img className="buy-icon" alt="Buy icon" src={buyIcon} />
            </div>
          </div>
        </div>
        <div className="div-2">
          <div className="div-3">
            <div className="text-wrapper-16">ETH Available:</div>
            <div className="text-wrapper-16">0.000000000</div>
          </div>
          <div className="div-3">
            <div className="text-wrapper-16">USDT Available:</div>
            <div className="text-wrapper-16">38.78946616</div>
          </div>
        </div>
      </div>
    </div>
  );
};

DivWrapper.propTypes = {
  buyIcon: PropTypes.string,
};
