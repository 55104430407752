/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { RectangleButton } from "../RectangleButton";
import "./style.css";
import {Checkbox} from "../Checkbox/Checkbox";

export const Cell = ({ className, textClassName, text }) => {
  return (
    <div className={`type-default ${className}`}>
      <div className="copy-wallet-cell">
        <div className={`text-2 ${textClassName}`}>{text}</div>
      </div>
    </div>
  );
};

export const PageContentCopyCeptionCopyWalletData = ({ screen, className }) => {
  return (
    <div className={`page-content-copy-ception-copy-wallet-data ${screen} ${className}`}>
      <div className="div">
        {screen === "desktop" && (
          <div className="copy-wallet-data">
            <div className="copy-wallet-data-2">
              <RectangleButton caption="Copied Wallet 1" className="rectangle-button-instance" type="selected" />
              <RectangleButton caption="Copied Wallet 2" className="rectangle-button-instance" type="default" />
              <RectangleButton caption="Copied Wallet 3" className="rectangle-button-instance" type="default" />
            </div>
            <div className="copy-wallet-data-3">
              <div className="info-block-wrapper">
                <div className="two-columns">
                  <div className="info-block">
                    <div className="info-text">
                      <div className="text-wrapper">ETH Address</div>
                      <div className="text-wrapper-2">0x95222290DD7278Aa3Ddd389Cc1E1d165CC4BAfe5</div>
                    </div>
                  </div>
                  <div className="info-block">
                    <div className="info-text">
                      <div className="text-wrapper">Wallet Nickname</div>
                      <div className="text-wrapper-2">Whale 1</div>
                    </div>
                  </div>
                </div>
                <div className="info-block-wrapper">
                  <div className="two-columns">
                    <div className="info-block">
                      <div className="info-text">
                        <div className="text-wrapper">ETH Balance</div>
                        <div className="text-wrapper-2">0.55ETH</div>
                      </div>
                    </div>
                    <div className="info-block">
                      <div className="info-text">
                        <div className="text-wrapper">ETH Value</div>
                        <div className="text-wrapper-2">$1000</div>
                      </div>
                    </div>
                  </div>
                  <div className="info-block">
                    <div className="info-text">
                      <div className="text-wrapper">Token Holding</div>
                      <div className="text-wrapper-2">63 Tokens</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-wallet-data-4">
              <div className="copy-wallet-data-wrapper">
                <div className="copy-wallet-data-row">
                  <div className="div-wrapper"></div>
                  <Cell className="type-default-instance" text="Coin Name" textClassName="instance-node" />
                  <Cell className="type-default-instance" text="Method" textClassName="instance-node" />
                  <Cell className="type-default-instance" text="Age" textClassName="copy-wallet-data-6" />
                  <Cell className="type-default-instance" text="Quantity" textClassName="copy-wallet-data-6" />
                  <Cell className="type-default-instance" text="Copied" textClassName="copy-wallet-data-6" />
                  <Cell className="type-default-instance" text="P&amp;L" textClassName="copy-wallet-data-6" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-instance" text="Lorem Ipsum" textClassName="type-default-3" />
                </div>
              </div>
              <div className="rectangle-3" />
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="cancel-trade">CANCEL TRADE</div>
              </div>
            </div>
          </div>
        )}

        {screen === "mobile" && (
          <>
            <div className="copy-wallet-data-8">
              <RectangleButton caption="Copied Wallet 1" className="rectangle-button-2" type="selected" />
              <RectangleButton caption="Copied Wallet 2" className="rectangle-button-2" type="default" />
              <RectangleButton caption="Copied Wallet 3" className="rectangle-button-2" type="default" />
            </div>
            <div className="copy-wallet-data-3">
              <div className="element-columns-2">
                <div className="frame-wrapper">
                  <div className="frame-5">
                    <div className="text-wrapper">ETH Address</div>
                    <div className="text-wrapper-2">0x95222290DD7278Aa3Ddd389Cc1E1d165CC4BAfe5</div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-3">
                    <div className="text-wrapper">Wallet Nickname</div>
                    <div className="text-wrapper-2">Whale 1</div>
                  </div>
                </div>
              </div>
              <div className="element-columns-2">
                <div className="frame-wrapper">
                  <div className="frame-3">
                    <div className="text-wrapper">ETH Balance</div>
                    <div className="text-wrapper-2">0.55ETH</div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-3">
                    <div className="text-wrapper">ETH Value</div>
                    <div className="text-wrapper-2">$1000</div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-3">
                    <div className="text-wrapper">Token Holding</div>
                    <div className="text-wrapper-2">63 Tokens</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-wallet-data-9">
              <div className="text-wrapper-3">Active Contract</div>
            </div>
            <div className="copy-wallet-data-10">
              <div className="copy-wallet-data-wrapper">
                <div className="copy-wallet-data-row">
                  <Cell className="type-default-4" text="Coin Name" textClassName="cell" />
                  <Cell className="type-default-4" text="Method" textClassName="cell" />
                  <Cell className="type-default-4" text="Age" textClassName="cell-2" />
                  <Cell className="type-default-4" text="Quantity" textClassName="cell-2" />
                  <Cell className="type-default-4" text="Copied" textClassName="cell-2" />
                  <Cell className="type-default-4" text="P&amp;l" textClassName="cell-2" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="copy-wallet-data-11" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="copy-wallet-data-11" />
                </div>
              </div>
              <div className="copy-wallet-data-row-container">
                <div className="copy-wallet-data-row">
                  <div className="content-wrapper-2">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="Lorem" textClassName="type-default-2" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="15" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="type-default-3" />
                  <Cell className="type-default-4" text="Lorem Ipsum" textClassName="copy-wallet-data-11" />
                </div>
              </div>
              <div className="rectangle-3" />
            </div>
            <div className="cancel-trade-wrapper">
              <div className="cancel-trade-2">CANCEL TRADE</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PageContentCopyCeptionCopyWalletData.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
