import PropTypes from "prop-types";
import React from "react";
import { Mission } from "../Mission";
import "./style.css";

export const Missions = ({ screen }) => {
  return (
    <div className={`missions-container screen-${screen}`}>
      {screen === "desktop" && (
        <div className="missions">
          <div className="missions-row">
            <Mission
              bronze={false}
              caption="Auto-Approved Amount"
              format="default"
              silver={false}
              status="SNIPER"
              value1="3/10 ETH"
              value2="2500 Points"
            />
            <Mission
              bronze={false}
              caption="Total MEVS Participated"
              format="default"
              silver={false}
              status="SNIPER"
              value1="40/300"
              value2="2500 Points"
            />
            <Mission
              bronze={false}
              caption="Current Active Tokens"
              format="default"
              gold={false}
              status="SNIPER"
              value1="100/200 ETH"
              value2="600 Points"
            />
          </div>
          <div className="missions-row">
            <Mission
              caption="Volume"
              format="default"
              gold={false}
              silver={false}
              status="TRADER"
              value1="23/100 ETH"
              value2="100 Points"
            />
            <Mission
              bronze={false}
              caption="Projects MEV Approved"
              format="default"
              silver={false}
              status="TRADER"
              value1="53/200"
              value2="600 Points"
            />
            <Mission
              caption="Total Profit"
              format="default"
              gold={false}
              silver={false}
              status="COPY"
              value1="4.5/10"
              value2="2500 Points"
            />
          </div>
        </div>
      )}

      {screen === "mobile" && (
        <>
          <div className="missions-row-2">
            <Mission
              bronze={false}
              caption="Auto-Approved Amount"
              format="small"
              silver={false}
              status="SNIPER"
              value1="3/10 ETH"
              value2="2500 Points"
            />
            <Mission
              bronze={false}
              caption="Total MEVS Participated"
              format="small"
              silver={false}
              status="SNIPER"
              value1="40/300"
              value2="2500 Points"
            />
          </div>
          <div className="missions-row-2">
            <Mission
              bronze={false}
              caption="Current Active Tokens"
              format="small"
              gold={false}
              status="SNIPER"
              value1="100/200 ETH"
              value2="600 Points"
            />
            <Mission
              caption="Volume"
              format="small"
              gold={false}
              silver={false}
              status="TRADER"
              value1="23/100 ETH"
              value2="100 Points"
            />
          </div>
          <div className="missions-row-2">
            <Mission
              bronze={false}
              caption="Projects MEV Approved"
              format="small"
              silver={false}
              status="TRADER"
              value1="53/200"
              value2="600 Points"
            />
            <Mission
              caption="Total Profit"
              format="small"
              gold={false}
              silver={false}
              status="COPY"
              value1="4.5/10"
              value2="2500 Points"
            />
          </div>
        </>
      )}
    </div>
  );
};

Missions.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
