/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TypeType = ({ className, textClassName, text, vector = "/img/crypto-market-arrow-down.svg", frameClassName }) => {
  return (
    <div className={`type-type ${className}`}>
      <div className="frame-wrapper">
        <div className={`frame ${frameClassName}`}>
          <div className={`text-3 ${textClassName}`}>{text}</div>
          <img className="vector" alt="Vector" src={vector} />
        </div>
      </div>
    </div>
  );
};

TypeType.propTypes = {
  text: PropTypes.string,
  vector: PropTypes.string,
};
