/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoCoingecko = ({ className }) => {
  return (
    <svg
      className={`logo-coingecko ${className}`}
      fill="none"
      height="75"
      viewBox="0 0 76 75"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2030_16699)">
        <path
          className="path"
          d="M75.4999 37.3352C75.5915 58.0463 58.8762 74.9087 38.1663 75.0004C17.4551 75.092 0.591524 58.3767 0.499887 37.6656C0.40825 16.9568 17.1211 0.0944234 37.8299 0.000375013C58.541 -0.0912619 75.4058 16.6228 75.4999 37.334V37.3352Z"
          fill="#8DC63F"
        />
        <path
          className="path"
          d="M72.6749 37.3416C72.7617 56.4925 57.3077 72.0864 38.158 72.1745C19.0082 72.2625 3.41309 56.8072 3.32507 37.6575C3.23826 18.5078 18.6911 2.91261 37.8421 2.82459C56.993 2.73657 72.5881 18.1907 72.6749 37.3404V37.3416Z"
          fill="#F9E988"
        />
        <path
          className="path"
          d="M38.6318 5.07215C40.8359 4.67908 43.0931 4.67908 45.2972 5.07215C47.523 5.42423 49.6741 6.14647 51.6624 7.20632C53.6374 8.2927 55.3483 9.76613 57.0472 11.1576C58.7461 12.549 60.4414 13.9549 62.0535 15.5043C63.6969 17.0272 65.1318 18.761 66.3206 20.6601C67.5457 22.5471 68.5501 24.5691 69.3121 26.6852C70.794 30.9138 71.216 35.4413 70.5408 39.8701H70.3189C69.6678 35.6391 68.5802 31.4865 67.0706 27.4798C66.3472 25.4987 65.4959 23.5659 64.5217 21.6958C63.498 19.8305 62.3718 18.0231 61.148 16.2832C59.9 14.5216 58.3699 12.977 56.6204 11.7122C54.8624 10.4594 52.8392 9.64556 50.8798 8.87508C48.9205 8.10461 46.9599 7.33775 44.9258 6.74935C42.8917 6.16094 40.8082 5.72205 38.6391 5.29521L38.6306 5.07335L38.6318 5.07215Z"
          fill="white"
        />
        <path
          className="path"
          d="M55.5821 25.0926C53.0657 24.362 50.4456 23.331 47.8147 22.2869C47.664 21.6225 47.0756 20.7978 45.8903 19.7898C44.1637 18.2922 40.9238 18.332 38.1229 19C35.0325 18.2729 31.9784 18.0161 29.0351 18.719C5.07208 25.3169 18.6633 41.4498 9.86011 57.6491C11.1129 60.3041 24.6137 75.8245 44.148 71.6478C44.148 71.6478 37.4706 55.5945 52.5436 47.8897C64.7723 41.6198 73.6105 30.0109 55.5821 25.0914V25.0926Z"
          fill="#8BC53F"
        />
        <path
          className="path"
          d="M58.5411 35.6491C58.5472 36.4522 57.9021 37.1081 57.0991 37.1153C56.296 37.1214 55.6401 36.4763 55.6329 35.6732C55.6256 34.8702 56.2719 34.2143 57.0749 34.2071C57.0774 34.2071 57.0798 34.2071 57.0834 34.2071C57.884 34.2022 58.5375 34.8485 58.5411 35.6491Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.1218 18.9922C39.8678 19.1188 46.1859 21.1806 47.8136 22.2875C46.4668 18.3363 41.8874 17.8226 38.1218 18.9922Z"
          fill="#009345"
        />
        <path
          className="path"
          d="M39.7858 28.9594C39.7858 32.6695 36.7786 35.6766 33.0685 35.6766C29.3585 35.6766 26.3513 32.6695 26.3513 28.9594C26.3513 25.2493 29.3585 22.2422 33.0685 22.2422C36.7786 22.2422 39.7858 25.2493 39.7858 28.9594Z"
          fill="white"
        />
        <path
          className="path"
          d="M37.7988 29.018C37.8012 31.6236 35.6911 33.7373 33.0855 33.7397C30.4799 33.7421 28.3662 31.632 28.3638 29.0264C28.3614 26.4208 30.4714 24.3071 33.077 24.3047C33.0782 24.3047 33.0795 24.3047 33.0807 24.3047C35.6839 24.3071 37.7939 26.4147 37.7988 29.018Z"
          fill="#58595B"
        />
        <path
          className="path"
          d="M63.9742 38.6044C58.5411 42.4326 52.3617 45.3216 43.5983 45.3216C39.4975 45.3216 38.6668 40.9749 35.9562 43.1006C34.554 44.2027 29.6345 46.6563 25.7026 46.4827C21.7706 46.3091 15.4296 43.9977 13.6716 35.6406C12.9723 43.9977 12.6129 50.153 9.47559 57.2103C15.7262 67.218 30.6256 74.9348 44.1469 71.6166C42.6964 61.4702 51.5635 51.5288 56.5613 46.4441C58.4495 44.5198 62.0933 41.3752 63.973 38.5899V38.6056L63.9742 38.6044Z"
          fill="#8BC53F"
        />
        <path
          className="path"
          d="M63.7607 38.8554C62.0305 40.3445 60.0989 41.5816 58.0238 42.5342C55.9559 43.5169 53.7988 44.303 51.5839 44.8806C49.3713 45.4533 47.0563 45.8837 44.7123 45.6703C42.3683 45.4569 39.9713 44.6587 38.4255 42.9043L38.5003 42.8175C40.4126 44.0546 42.5974 44.4887 44.7786 44.5526C46.9936 44.6165 49.2073 44.4332 51.3813 44.004C53.577 43.5458 55.7256 42.885 57.7983 42.029C59.9047 41.2247 61.8882 40.1287 63.6896 38.7734L63.7607 38.8566V38.8554Z"
          fill="#58595B"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2030_16699">
          <rect className="rect" fill="white" height="75" transform="translate(0.5)" width="75" />
        </clipPath>
      </defs>
    </svg>
  );
};
