/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { MarketStats } from "../MarketStats";
import "./style.css";

export const SettingsSummaryWrapper = ({
  className,
  group = "/img/group-36762-2.png",
  img = "/img/group-36762-2.png",
  marketStatsGraph = "/img/graph-3.svg",
}) => {
  return (
    <div className={`settings-summary-wrapper ${className}`}>
      <div className="settings-summary-2">
        <p className="POOL-UNISWAP-v">
          <span className="text-wrapper-22">POOL </span>
          <span className="text-wrapper-23">UNISWAP V3</span>
        </p>
        <div className="group-4">
          <div className="overlap-group-3">
            <div className="text-wrapper-24">0.3%</div>
          </div>
        </div>
      </div>
      <div className="settings-summary-3">
        <p className="div-3">
          <span className="text-wrapper-22">PNDC:</span>
          <span className="text-wrapper-23"> 0X423...1EEA</span>
        </p>
        <img className="group-5" alt="Group" src={group} />
      </div>
      <div className="settings-summary-3">
        <p className="div-3">
          <span className="text-wrapper-22">PAIR: </span>
          <span className="text-wrapper-23">0X423...1EEA</span>
        </p>
        <img className="group-5" alt="Group" src={img} />
      </div>
      <div></div>
      <div></div>
      <MarketStats className="market-stats-instance" graph={marketStatsGraph} />
    </div>
  );
};

SettingsSummaryWrapper.propTypes = {
  group: PropTypes.string,
  img: PropTypes.string,
  marketStatsGraph: PropTypes.string,
};
