/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LogoTelegram = ({ color = "url(#paint0_linear_2030_16662)", className }) => {
  return (
    <svg className={`logo-telegram ${className}`} width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2027_15844)">
        <path d="M37.5 0C27.5566 0 18.0117 3.95332 10.9863 10.9834C3.95369 18.0163 0.00194807 27.5542 0 37.5C0 47.4416 3.95508 56.9865 10.9863 64.0166C18.0117 71.0467 27.5566 75 37.5 75C47.4434 75 56.9883 71.0467 64.0137 64.0166C71.0449 56.9865 75 47.4416 75 37.5C75 27.5584 71.0449 18.0135 64.0137 10.9834C56.9883 3.95332 47.4434 0 37.5 0Z" fill="url(#paint0_linear_2027_15844)"/>
        <path d="M16.9746 37.1036C27.9082 32.3411 35.1973 29.2011 38.8418 27.6841C49.2598 23.3523 51.4219 22.5999 52.834 22.5744C53.1446 22.5695 53.836 22.6462 54.2871 23.011C54.6621 23.3186 54.7676 23.7346 54.8203 24.0267C54.8672 24.3185 54.9317 24.9835 54.8789 25.5027C54.3164 31.4324 51.8731 45.8218 50.6309 52.4634C50.1094 55.2736 49.0723 56.2157 48.0703 56.3077C45.8906 56.5081 44.2383 54.8687 42.1289 53.4864C38.8301 51.3226 36.9668 49.9761 33.7617 47.865C30.0586 45.4251 32.461 44.0839 34.5703 41.8925C35.1211 41.3189 44.7188 32.5913 44.9004 31.7997C44.9239 31.7007 44.9473 31.3316 44.7246 31.137C44.5078 30.9419 44.1856 31.0087 43.9512 31.0615C43.6172 31.1365 38.3496 34.6216 28.1309 41.5163C26.6367 42.5441 25.2832 43.045 24.0645 43.0187C22.7285 42.99 20.1504 42.2616 18.2344 41.6394C15.8906 40.8759 14.0215 40.4722 14.1856 39.1755C14.2676 38.5005 15.1992 37.8097 16.9746 37.1036Z" fill="white"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2027_15844" x1="3750" y1="0" x2="3750" y2="7500" gradientUnits="userSpaceOnUse">
          <stop stop-color="#2AABEE"/>
          <stop offset="1" stop-color="#229ED9"/>
        </linearGradient>
        <clipPath id="clip0_2027_15844">
          <rect width="75" height="75" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

LogoTelegram.propTypes = {
  color: PropTypes.string,
};
