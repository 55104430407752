import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const MoreButtonOld = ({ caption = "Less info", className, icon = "/img/icon-2.svg", onClick = () => {} }) => {
  return (
    <div className={`more-button ${className}`} onClick={onClick}>
      <div className="more-button-caption">
        {caption}
      </div>
      <img className="icon" alt="Icon" src={icon} />
    </div>
  );
};

MoreButtonOld.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.string,
};
