/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const RectangleButton = ({ caption = "Caption", type, className }) => {
  return (
    <div className={`rectangle-button ${type} ${className}`}>
      <div className="caption">{caption}</div>
    </div>
  );
};

RectangleButton.propTypes = {
  caption: PropTypes.string,
  type: PropTypes.oneOf(["selected", "default"]),
};
