/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useState} from "react";
import { AdvancedSniperPropertyIcon31 } from "../../icons/AdvancedSniperPropertyIcon31";
import { AdvancedSniperProperty } from "../AdvancedSniperProperty";
import { AdvancedSniperTabPanel } from "../AdvancedSniperTabPanel";
import { RectangleButton } from "../RectangleButton";
import { SettingsInput } from "../SettingsInput";
import { Toggle } from "../Toggle";
import "./style.css";
import {
  ADVANCED_SNIPER_TAB_ADVANCED_SNIPER,
  ADVANCED_SNIPER_TAB_AUTO_SNIPES, ADVANCED_SNIPER_TAB_FAST_CONFIG,
  ADVANCED_SNIPER_TAB_TRADE_MONITOR
} from "../../constants";
import {Button} from "../Button";

export const PageContentAdvancedSniper = ({ selectedTab = ADVANCED_SNIPER_TAB_AUTO_SNIPES, href }) => {
  const [currentTab, setCurrentTab] = useState(selectedTab)
  const handleTabChange = (newSelectedTab) => setCurrentTab(newSelectedTab);
  return (
    <div className="page-content-advanced-sniper">
      <div className="div">
        <AdvancedSniperTabPanel
          className="instance-node"
          selectedTab={currentTab}
          onTabChange={handleTabChange}
        />
        {[ADVANCED_SNIPER_TAB_ADVANCED_SNIPER, ADVANCED_SNIPER_TAB_TRADE_MONITOR].includes(currentTab) && (
          <div className={`advanced-sniper-options-container selected-tab-${currentTab}`}>
            {currentTab === ADVANCED_SNIPER_TAB_ADVANCED_SNIPER && (
              <div className="advanced-sniper-3">
                <AdvancedSniperProperty
                  caption="First Bundle or Fail Backup Miner Tip"
                  className="instance-node"
                  value="Current value"
                />
                <AdvancedSniperProperty caption="Manual Buyer Extra" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Approve Extra" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Sell Rug Extra" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Max Allowed Sell Tax" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Max Allowed LIquidity" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Default Wallet" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Slippage" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Sell Extra" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Max Allowed Buy Tax" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Default Manual Wallets" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Default Manual Wallets" className="instance-node" value="Current value" />
                <AdvancedSniperProperty
                  caption="Default First bundle or Fail Backup Deadblocks/MEV Launch"
                  className="instance-node"
                  value="Current value"
                />
                <AdvancedSniperProperty
                  caption="Default First bundle or Fail"
                  className="instance-node"
                  value="Current value"
                />
                <AdvancedSniperProperty caption="Min Allow Liquidity" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Wallet Setup" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Default Auto Wallets" className="instance-node" value="Current value" />
                <AdvancedSniperProperty caption="Default Anti-Rug" className="instance-node" value="Current value" />
              </div>
            )}

            {currentTab === ADVANCED_SNIPER_TAB_TRADE_MONITOR && (
              <>
                <div className="text-wrapper-2">Trade Monitor Settings</div>
                <a href={href} rel="noopener noreferrer" target="_blank">
                  <p className="select-the-setting">Select The Setting You Would Like To View In Your Trade Monitor</p>
                </a>
                <div className="advanced-sniper-4">
                  <div className="advanced-sniper-5">
                    <div className="text">Token Amount</div>
                    <Toggle state="disabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <div className="text">Amount Spent</div>
                    <Toggle state="enabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <div className="text">Profit, Loss</div>
                    <Toggle state="enabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <div className="text">% To Break Even</div>
                    <Toggle state="enabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <div className="text-2">Marketcap</div>
                    <Toggle state="enabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <div className="text">Dca To Break-even</div>
                    <Toggle state="enabled" />
                  </div>
                  <div className="advanced-sniper-5">
                    <p className="text">Overall Profitability Across All Trades</p>
                    <Toggle state="enabled" />
                  </div>
                </div>
                <div className="button-wrapper">
                  <Button caption={"SAVE"} className={"configuration-button"}/>
                </div>
              </>
            )}
          </div>
        )}

        {currentTab === ADVANCED_SNIPER_TAB_AUTO_SNIPES && (
          <>
            <p className="p">Select Wallet To Buy With</p>
            <div className="advanced-sniper-6">
              <RectangleButton caption="Wallet 1" className="rectangle-button-instance" type="selected" />
              <RectangleButton caption="Wallet 2" className="rectangle-button-instance" type="default" />
              <RectangleButton caption="Wallet 3" className="rectangle-button-instance" type="default" />
            </div>
            <div className="advanced-sniper-7">
              <p className="text-wrapper-4">Select Amount To Snipe With</p>
              <div className="advanced-sniper-8">
                <div className="advanced-sniper-9">
                  <RectangleButton
                    caption="0.1 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.4ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.7 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="1 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                </div>
                <div className="advanced-sniper-9">
                  <RectangleButton
                    caption="0.2 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.5ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.8 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="Max Wallet"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                </div>
                <div className="advanced-sniper-9">
                  <RectangleButton
                    caption="0.3 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.6 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                  <RectangleButton
                    caption="0.9 ETH"
                    className="rectangle-button-2"
                    divClassName="rectangle-button-3"
                    type="default"
                  />
                </div>
              </div>
              <SettingsInput className="settings-input-instance" placeholder="Enter Custom Amount"/>
              <div className="button-container">
                <Button caption={"SAVE"} className={"configuration-button"} fullwidth={false}/>
              </div>
            </div>
          </>
        )}

        {currentTab === ADVANCED_SNIPER_TAB_FAST_CONFIG && (
          <>
            <div className="advanced-sniper-10">
              <div className="text-wrapper-2">Fast Config Settings</div>
              <div className="advanced-sniper-4">
                <div className="advanced-sniper-11">
                  <div className="text-2">Anti-rug</div>
                  <Toggle state="disabled" />
                </div>
                <div className="advanced-sniper-11">
                  <div className="text-2">Smart-slippage</div>
                  <Toggle state="enabled" />
                </div>
                <div className="advanced-sniper-11">
                  <div className="text-2">Anti-mev</div>
                  <Toggle state="enabled" />
                </div>
                <div className="advanced-sniper-11">
                  <div className="text-2">Auto Approve</div>
                  <Toggle state="enabled" />
                </div>
              </div>
            </div>
            <div className="advanced-sniper-12">
              <div className="advanced-sniper-13">
                <div className="advanced-sniper-wrapper">
                  <div className="advanced-sniper-14">
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">Buy Settings</div>
                    </div>
                    <div className="advanced-sniper-16">
                      <div className="text-wrapper-5">Current Value</div>
                    </div>
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">New Value</div>
                    </div>
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Min Liquidity</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Min MC/Liq</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Max Buy Tax</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Max Sell Tax</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Gas Delta</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Max MC</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
              </div>
              <div className="advanced-sniper-13">
                <div className="advanced-sniper-wrapper">
                  <div className="advanced-sniper-14">
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">Selll Settings</div>
                    </div>
                    <div className="advanced-sniper-16">
                      <div className="text-wrapper-5">Current Value</div>
                    </div>
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">New Value</div>
                    </div>
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Sell-High</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Sell-Low</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Sell-High Amount</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Sell-Low Amount</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Gas Delta</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
              </div>
              <div className="advanced-sniper-13">
                <div className="advanced-sniper-wrapper">
                  <div className="advanced-sniper-14">
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">Gas Settings</div>
                    </div>
                    <div className="advanced-sniper-16">
                      <div className="text-wrapper-5">Current Value</div>
                    </div>
                    <div className="advanced-sniper-15">
                      <div className="text-wrapper-5">New Value</div>
                    </div>
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Max Gas Price</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Slippage</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
                <div className="advanced-sniper-row">
                  <div className="advanced-sniper-17">
                    <div className="text-wrapper-6">Max Gas Limit</div>
                    <AdvancedSniperPropertyIcon31 className="advanced-sniper-property-icon-31" />
                  </div>
                  <div className="advanced-sniper-18">
                    <div className="text-wrapper-6">33,000</div>
                  </div>
                  <div className="settings-input-wrapper">
                    <SettingsInput className="settings-input-2" />
                  </div>
                </div>
              </div>
              <Button className={"configuration-button"} fullwidth={false} caption={"SAVE"}/>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PageContentAdvancedSniper.propTypes = {
  selectedTab: PropTypes.oneOf(["trade-monitor", "advanced-sniper", "fast-config-sniper", "auto-snipes"]),
  href: PropTypes.string,
};
