/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoCoinmarketcap = ({ className }) => {
  return (
    <svg
      className={`logo-coinmarketcap ${className}`}
      fill="none"
      height="75"
      viewBox="0 0 76 75"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M65.3064 44.8156C63.997 45.6437 62.4563 45.7469 61.2876 45.0875C59.8001 44.2438 58.9813 42.2813 58.9813 39.5438V31.3625C58.9813 27.4125 57.4188 24.6 54.8063 23.8406C50.3813 22.55 47.0501 27.9656 45.8001 30.0031L37.9938 42.6594V27.1906C37.9063 23.6281 36.7501 21.5 34.5563 20.8562C33.1001 20.4344 30.9282 20.6031 28.8157 23.8344L11.3251 51.9188C8.98363 47.4736 7.76286 42.5241 7.76885 37.5C7.76885 20.5812 21.3282 6.81875 37.9938 6.81875C54.6563 6.81875 68.2126 20.5812 68.2126 37.5C68.2251 37.5562 68.2188 37.6063 68.222 37.6656C68.3782 40.9438 67.3157 43.55 65.3064 44.8219V44.8156ZM74.9313 37.5031L74.9282 37.3313C74.8345 16.7281 58.3032 0 37.9907 0C17.6251 0 1.05322 16.8219 1.05322 37.5C1.05322 58.175 17.6251 75 37.9907 75C47.3376 75 56.2595 71.4469 63.1063 65C64.4657 63.7187 64.5438 61.5625 63.2845 60.1844C62.9874 59.8566 62.6279 59.5913 62.227 59.4042C61.8261 59.2171 61.3919 59.1119 60.9498 59.0947C60.5077 59.0776 60.0666 59.1488 59.6524 59.3042C59.2382 59.4597 58.8592 59.6962 58.5376 60C52.9882 65.2543 45.6361 68.1822 37.9938 68.1813C29.0688 68.1813 21.0407 64.2344 15.5032 57.9687L31.2782 32.6406V44.3188C31.2782 49.925 33.4532 51.7375 35.2782 52.2687C37.1032 52.8 39.8907 52.4375 42.8188 47.6813C45.9376 42.6375 49.147 37.3781 52.2657 32.4313V39.5438C52.2657 44.7875 54.3657 48.9812 58.0251 51.0437C61.3251 52.9094 65.4751 52.7406 68.8532 50.6063C72.9532 48.0188 75.1595 43.25 74.9282 37.5031H74.9313Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};
