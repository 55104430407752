import PropTypes from "prop-types";
import React, {useState} from "react";
import { RectangleButton } from "../RectangleButton";
import { TabItem } from "../TabItem";
import "./style.css";
import {Checkbox} from "../Checkbox/Checkbox";
import {SCREEN_DESKTOP} from "../../constants";
import {SettingsInput} from "../SettingsInput";

const TAB_GENERATE = 'generate';
const TAB_IMPORT = 'import';
const TAB_VIEW = 'view';
const TAB_TRANSFER = 'transfer';

export const WalletManagement = ({ selectedTab = TAB_GENERATE, screen = SCREEN_DESKTOP }) => {
  const [tab, setCurrentTab] = useState(selectedTab);
  const handleTabClick = newTab => setCurrentTab(newTab);
  return (
    <div className={`wallet-management ${screen}`}>
      <div className="div">
        <div className="tab-header-container">
          <div className="tabs-wrapper">
            <div className="tabs-container">
              <TabItem
                caption="Generate Wallet"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_GENERATE)}
                state={tab === TAB_GENERATE ? "active" : "default"}
              />
              <TabItem
                caption="Import Wallet"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_IMPORT)}
                state={tab === TAB_IMPORT ? "active" : "default"}
              />
              <TabItem
                caption="View Wallet"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_VIEW)}
                state={tab === TAB_VIEW ? "active" : "default"}
              />
              <TabItem
                caption="Transfer"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_TRANSFER)}
                state={tab === TAB_TRANSFER ? "active" : "default"}
              />
            </div>
          </div>
        </div>
        <div className="div-2">
          {[TAB_GENERATE, TAB_IMPORT, TAB_VIEW].includes(tab) && (
            <div className="view-wallet">
              {tab === TAB_VIEW && <>View Wallet</>}

              {tab === TAB_GENERATE && <>Generate Wallet</>}

              {tab === TAB_IMPORT && <>Import Wallet</>}
            </div>
          )}

          {tab === TAB_TRANSFER && <div className="text-wrapper-2">Transfer</div>}

          {tab !== TAB_TRANSFER && <div className="buttons-container">
            <RectangleButton
              caption={tab === TAB_GENERATE ? "Generate Wallet 1" : tab === TAB_IMPORT ? "Import Wallet 1" : "Wallet 1"}
              className="rectangle-button-instance"
              type="selected"
            />
            <RectangleButton
              caption={tab === TAB_GENERATE ? "Generate Wallet 1" : tab === TAB_IMPORT ? "Import Wallet 1" : "Wallet 2"}
              className="rectangle-button-instance"
              type="default"
            />
            <RectangleButton
              caption={tab === TAB_GENERATE ? "Generate Wallet 1" : tab === TAB_IMPORT ? "Import Wallet 1" : "Wallet 3"}
              className="rectangle-button-instance"
              type="default"
            />
          </div>}
          {tab === TAB_TRANSFER && (
            <>
              <div className="selects-container">
                <div className="text-wrapper-3">From</div>
                <div className="selects">
                  <div className="select">
                    <div className="text-wrapper-4">Choose Wallet 1</div>
                    <div className="frame">
                      <img className="polygon" alt="Polygon" src="/img/polygon-2-2.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="selects-container">
                <div className="text-wrapper-3">To</div>
                <div className="selects">
                  <div className="select">
                    <div className="text-wrapper-4">Choose Wallet 1</div>
                    <div className="frame">
                      <img className="polygon" alt="Polygon" src="/img/polygon-2-2.svg" />
                    </div>
                  </div>
                  <div className="select-2">
                    <div className="text-wrapper-4">ETH Address</div>
                    <div className="frame">
                      <img className="polygon" alt="Polygon" src="/img/polygon-2-2.svg" />
                    </div>
                  </div>
                  <div className="text-input">
                    <SettingsInput placeholder="Amount"/>
                  </div>
                </div>
              </div>
              <button className="button-container">
                <button className="button">
                  <div className="text-wrapper-5">SUBMIT</div>
                </button>
              </button>
            </>
          )}

          {tab === TAB_VIEW && (
            <>
              {screen === SCREEN_DESKTOP ?
                <div className="wallet-info-table-wrapper">
                  <div className="wallet-info-table">
                    <div className="wallet-info-table-header">
                        <div></div>
                        <div>ETH BALANCE</div>
                        <div>ETH VALUE</div>
                        <div>TOKEN HOLDINGS</div>
                    </div>
                    <div className="wallet-info-table-row">
                      <div><Checkbox/></div>
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                    <div className="wallet-info-table-row">
                      <div><Checkbox/></div>
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                    <div className="wallet-info-table-row">
                      <div><Checkbox/></div>
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                    <div className="wallet-info-table-row">
                      <div><Checkbox/></div>
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                  </div>
                </div>
                :
                <div className="wallet-info-table-mobile">
                  <div className="wallet-info-table-mobile-row header">
                    <div className="wallet-info-table-checkbox">

                    </div>
                    <div className="wallet-info-table-data">
                      <div>ETH BALANCE</div>
                      <div>ETH VALUE</div>
                      <div>TOKEN HOLDINGS</div>
                    </div>
                  </div>
                  <div className="wallet-info-table-mobile-row">
                    <div className="wallet-info-table-checkbox">
                      <Checkbox/>
                    </div>
                    <div className="wallet-info-table-data">
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                  </div>
                  <div className="wallet-info-table-mobile-row">
                    <div className="wallet-info-table-checkbox">
                      <Checkbox/>
                    </div>
                    <div className="wallet-info-table-data">
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                  </div>
                  <div className="wallet-info-table-mobile-row">
                    <div className="wallet-info-table-checkbox">
                      <Checkbox/>
                    </div>
                    <div className="wallet-info-table-data">
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                  </div>
                  <div className="wallet-info-table-mobile-row">
                    <div className="wallet-info-table-checkbox">
                      <Checkbox/>
                    </div>
                    <div className="wallet-info-table-data">
                      <div>165.458142077451170598 ETH</div>
                      <div>$328,126.76 (@ $1,983.14/ETH)</div>
                      <div>$15,000,000 456 - Tokens</div>
                    </div>
                  </div>
                </div>}
              <div className="button-wrapper">
                <button className="remove-from-view-wrapper">
                  <div className="text-wrapper-5">REMOVE FROM VIEW</div>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

WalletManagement.propTypes = {
  tab: PropTypes.oneOf(["import", "view", "generate", "transfer"]),
};
