/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chart1 = ({ className }) => {
  return (
    <svg
      className={`chart-1 ${className}`}
      fill="none"
      height="102"
      viewBox="0 0 191 102"
      width="191"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.6184 38.3804C4.01187 38.3804 -9.02216 47.3298 -9.02216 47.3298V100.778H199.522V26.9993C199.522 26.9993 192.745 20.7088 182.027 18.6717C162.708 14.9999 160.012 41.8053 143.041 41.8054C130.576 41.8054 129.715 25.0562 113.094 23.5294C96.4735 22.0027 88.9761 60.4658 62.1823 60.0322C42.1106 59.7074 29.9061 38.3804 18.6184 38.3804Z"
        fill="url(#paint0_linear_2004_29444)"
      />
      <path
        className="path"
        d="M-8.67505 47.215C-8.67505 47.215 4.0114 38.347 18.618 38.347C30.7746 38.347 42.1102 59.6385 62.1818 59.9627C88.9756 60.3956 96.4731 21.9966 113.094 23.5208C129.715 25.045 130.576 41.7663 143.041 41.7663C160.012 41.7662 162.708 15.0055 182.026 18.6711C192.744 20.7048 199.522 26.9848 199.522 26.9848"
        stroke="url(#paint1_linear_2004_29444)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M110.543 14.0299C110.543 6.35153 116.768 0.126953 124.446 0.126953C132.125 0.126953 138.349 6.35152 138.349 14.0299V100.779H110.543V14.0299Z"
        fill="url(#paint2_linear_2004_29444)"
        opacity="0.3"
      />
      <g className="g" filter="url(#filter0_d_2004_29444)">
        <ellipse
          className="ellipse-3"
          cx="124.447"
          cy="27.4272"
          fill="url(#paint3_linear_2004_29444)"
          rx="8.68935"
          ry="6.89399"
        />
        <path
          className="path"
          d="M130.136 27.4272C130.136 28.9675 128.274 31.3212 124.447 31.3212C120.62 31.3212 118.757 28.9675 118.757 27.4272C118.757 25.8869 120.62 23.5332 124.447 23.5332C128.274 23.5332 130.136 25.8869 130.136 27.4272Z"
          stroke="white"
          strokeWidth="6"
        />
      </g>
      <path className="path" d="M-9.02216 100.779H199.522" opacity="0.1" stroke="#454459" />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.7871"
          id="filter0_d_2004_29444"
          width="67.3787"
          x="90.7574"
          y="0.533203"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29444" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29444"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29444"
          x1="95.25"
          x2="95.25"
          y1="18.3262"
          y2="100.778"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#8D79F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29444"
          x1="95.4233"
          x2="95.4233"
          y1="18.3262"
          y2="59.9664"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29444"
          x1="124.446"
          x2="124.446"
          y1="0.126953"
          y2="100.779"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.15" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29444"
          x1="124.447"
          x2="124.447"
          y1="20.5332"
          y2="34.3212"
        >
          <stop className="stop" stopColor="#FD95D3" />
          <stop className="stop" offset="1" stopColor="#FF5CBE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
