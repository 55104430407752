import React from "react";
import "./style.css";
import {PROJECT_COPY, PROJECT_GAME, PROJECT_MARKET, PROJECT_MEV} from "../../constants";

export const ProductsMenu = ({currentProject, onProductClick = () => {}}) => {
  const getMenuItemClassName = project => `product-menu-item${project === currentProject ? ' active' : ''}`;
  return (
    <div className="products-menu">
      <div className="products-column">
        <a className={getMenuItemClassName(PROJECT_MEV)} onClick={() => onProductClick(PROJECT_MEV)}>
          <img className="product-menu-item-icon" alt="Solar chart bold" src="/img/solar-chart-2-bold-duotone.svg" />
          <div className="product-menu-item-content">
            <div className="product-menu-item-title">MevCeption</div>
            <div className="product-menu-item-description">Monitor your metrics</div>
          </div>
        </a>
        <a className={getMenuItemClassName(PROJECT_MARKET)} onClick={() => onProductClick(PROJECT_MARKET)}>
          <img className="product-menu-item-icon" alt="Solar chart square" src="/img/solar-chart-square-bold-duotone.svg" />
          <div className="product-menu-item-content">
            <div className="product-menu-item-title">MarketCeption</div>
            <div className="product-menu-item-description">Lorem Ipsum</div>
          </div>
        </a>
      </div>
      <div className="products-column">
        <a className={getMenuItemClassName(PROJECT_COPY)} onClick={() => onProductClick(PROJECT_COPY)}>
          <img className="product-menu-item-icon" alt="Solar copy bold" src="/img/solar-copy-bold-duotone.svg" />
          <div className="product-menu-item-content">
            <div className="product-menu-item-title">CopyCeption</div>
            <div className="product-menu-item-description">Lorem Ipsum</div>
          </div>
        </a>
        <a className={getMenuItemClassName(PROJECT_GAME)} onClick={() => onProductClick(PROJECT_GAME)}>
          <img className="product-menu-item-icon" alt="Solar cup star bold" src="/img/solar-cup-star-bold-duotone.svg" />
          <div className="product-menu-item-content">
            <div className="product-menu-item-title">GameCeption</div>
            <div className="product-menu-item-description">Sniper &amp; Trading Tools</div>
          </div>
        </a>
      </div>
    </div>
  );
};
