/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, {useState} from "react";
import "./style.css";

export const SettingsInput = ({ className, value = '', placeholder = '', onChange = null}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const handleChange = ({value}) => {
    if (onChange) {
      onChange(value);
    } else {
      setCurrentValue(value);
    }
  }
  return <input onChange={handleChange} value={currentValue} placeholder={placeholder} className={`settings-input ${className}`} />;
};
