import PropTypes from "prop-types";
import React, {useState} from "react";
import "./style.css";

export const Checkbox = ({ className, checked = false, onClick = null, label = ''}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleClick = () => {
    console.log('on click');
    if (onClick) {
      onClick(!isChecked);
    } else {
      setIsChecked(!isChecked);
    }
  }
  className = className + isChecked ? ' checked' : '';
  return (
    <label className={`checkbox-container ${className}`}>
      <input type="checkbox" checked={isChecked} onClick={handleClick}/>
      <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2080_15546)">
          <rect className={'checkbox-border'} x="0.5" y="-0.5" width="20" height="20" rx="5.5" transform="matrix(-1 0 0 1 21 1)" stroke="#B4B4B4"/>
          <rect className={'active-rectangle'} width="11" height="11" rx="2" transform="matrix(-1 0 0 1 16 4.93164)" fill="#02FFFA"/>
        </g>
        <defs>
          <clipPath id="clip0_2080_15546">
            <rect width="21" height="21" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      {label && <span className="checkbox-label-text">{label}</span>}
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func
}
