/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PositionBlock } from "../PositionBlock";
import "./style.css";

export const PageContentGameCeptionLeaderboard = ({ screen }) => {
  return (
    <div className={`page-content-game-ception-leaderboard ${screen}`}>
      {screen === "desktop" && (
        <div className="div-wrapper">
          <div className="leaderboard-menu">
            <div className="div">
              <div className="text-wrapper">Current Position</div>
            </div>
            <div className="div-wrapper-2">
              <div className="text-wrapper-2">Position Change 24hrs</div>
            </div>
            <div className="div-wrapper-2">
              <div className="text-wrapper-2">Position Change 7hrs</div>
            </div>
          </div>
        </div>
      )}

      <div className="leaderboard-position">
        <PositionBlock
          caption="Current Position"
          className="position-block-instance"
          divClassName="position-block-2"
          positionCircleClassName={`${screen === "mobile" && "class"}`}
          value="7"
        />
        <PositionBlock
          caption="Position Change 24 Hours"
          className="position-block-instance"
          divClassName="position-block-2"
          positionCircleClassName={`${screen === "mobile" && "class"}`}
          value="-2"
        />
        <PositionBlock
          caption="Position Change 7 Days"
          className="position-block-instance"
          divClassName="position-block-3"
          positionCircleClassName={`${screen === "mobile" && "class"}`}
          value="+10"
        />
      </div>
      {screen === "desktop" && (
        <div className="leaderboard-table">
          <div className="div-wrapper-3">
            <div className="text-wrapper-3">Leaderboard</div>
          </div>
          <div className="div-2">
            <div className="leaderboard-table-header">
              <div className="leaderboard-table-header-row">
                <div className="div-3">
                  <div className="frame">
                    <div className="text-wrapper-4">USER</div>
                  </div>
                </div>
                <div className="column">
                  <div className="frame-2">
                    <div className="text-wrapper-4">Amount of Mission Completed</div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame">
                    <div className="text-wrapper-4">Badges</div>
                  </div>
                </div>
                <div className="div-4">
                  <div className="frame-3">
                    <div className="text-wrapper-4">Point</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-5">
              <div className="div-6">
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">8496</div>
                  </div>
                </div>
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">22</div>
                  </div>
                </div>
                <div className="badges-cell">
                  <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                  <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                  <img className="badge" alt="Badge" src="/img/badge-user.png" />
                  <img className="badge" alt="Badge" src="/img/badge-health.png" />
                  <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                </div>
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="text-wrapper-5">300</div>
                  </div>
                </div>
              </div>
              <div className="div-6">
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">8496</div>
                  </div>
                </div>
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">22</div>
                  </div>
                </div>
                <div className="badges-cell">
                  <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                  <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                  <img className="badge" alt="Badge" src="/img/badge-user.png" />
                  <img className="badge" alt="Badge" src="/img/badge-health.png" />
                  <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                </div>
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="text-wrapper-5">300</div>
                  </div>
                </div>
              </div>
              <div className="div-6">
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">8496</div>
                  </div>
                </div>
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">22</div>
                  </div>
                </div>
                <div className="badges-cell">
                  <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                  <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                  <img className="badge" alt="Badge" src="/img/badge-user.png" />
                  <img className="badge" alt="Badge" src="/img/badge-health.png" />
                  <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                </div>
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="text-wrapper-5">300</div>
                  </div>
                </div>
              </div>
              <div className="div-6">
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">8496</div>
                  </div>
                </div>
                <div className="div-3">
                  <div className="frame-4">
                    <div className="text-wrapper-5">22</div>
                  </div>
                </div>
                <div className="badges-cell">
                  <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                  <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                  <img className="badge" alt="Badge" src="/img/badge-user.png" />
                  <img className="badge" alt="Badge" src="/img/badge-health.png" />
                  <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                </div>
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="text-wrapper-5">300</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {screen === "mobile" && (
        <div className="leaderboard-mobile">
          <div className="div-wrapper">
            <div className="leaderaboard-mobile">
              <div className="div">
                <div className="text-wrapper-6">Current Position</div>
              </div>
              <div className="div-wrapper-2">
                <div className="text-wrapper-2">Position Change 24hrs</div>
              </div>
              <div className="div-wrapper-2">
                <div className="text-wrapper-2">Position Change 7hrs</div>
              </div>
            </div>
          </div>
          <div className="leaderaboard-mobile-2">
            <div className="div-wrapper-3">
              <div className="text-wrapper-3">Leaderboard</div>
            </div>
            <div className="div-2">
              <div className="leaderaboard-mobile-3">
                <div className="leaderboard-table-header-row">
                  <div className="div-3">
                    <div className="frame">
                      <div className="text-wrapper-4">USER</div>
                    </div>
                    <div className="frame">
                      <div className="amount-of-mission">
                        Amount of Mission <br />
                        Completed
                      </div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="frame">
                      <div className="text-wrapper-4">Badges</div>
                    </div>
                    <div className="frame-3">
                      <div className="text-wrapper-5">Point</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-5">
                <div className="div-6">
                  <div className="div-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">8496</div>
                    </div>
                    <div className="frame-4">
                      <div className="text-wrapper-5">22</div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="badges">
                      <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                      <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                      <img className="badge" alt="Badge" src="/img/badge-user.png" />
                      <img className="badge" alt="Badge" src="/img/badge-health.png" />
                      <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">300</div>
                    </div>
                  </div>
                </div>
                <div className="div-6">
                  <div className="div-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">8496</div>
                    </div>
                    <div className="frame-4">
                      <div className="text-wrapper-5">22</div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="badges">
                      <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                      <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                      <img className="badge" alt="Badge" src="/img/badge-user.png" />
                      <img className="badge" alt="Badge" src="/img/badge-health.png" />
                      <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">300</div>
                    </div>
                  </div>
                </div>
                <div className="div-6">
                  <div className="div-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">8496</div>
                    </div>
                    <div className="frame-4">
                      <div className="text-wrapper-5">22</div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="badges">
                      <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                      <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                      <img className="badge" alt="Badge" src="/img/badge-user.png" />
                      <img className="badge" alt="Badge" src="/img/badge-health.png" />
                      <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">300</div>
                    </div>
                  </div>
                </div>
                <div className="div-6">
                  <div className="div-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">8496</div>
                    </div>
                    <div className="frame-4">
                      <div className="text-wrapper-5">22</div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="badges">
                      <img className="badge" alt="Badge" src="/img/badge-tick.png" />
                      <img className="badge" alt="Badge" src="/img/badge-phone.png" />
                      <img className="badge" alt="Badge" src="/img/badge-user.png" />
                      <img className="badge" alt="Badge" src="/img/badge-health.png" />
                      <img className="badge" alt="Badge" src="/img/badge-refresh.png" />
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">300</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PageContentGameCeptionLeaderboard.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
