import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Button = ({className = '', caption, onClick = () => {}, fullwidth = false}) => {

  return (
    <button className={`default-button ${className}${fullwidth ? 'fullwidth' : ''}`} onClick={onClick}>{caption}</button>
  )
}
