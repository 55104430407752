/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArcGraph5 = ({ className }) => {
  return (
    <svg
      className={`arc-graph-5 ${className}`}
      fill="none"
      height="152"
      viewBox="0 0 151 152"
      width="151"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M102.312 124.409C112.898 118.287 121.049 106.407 125.593 95.0416C130.136 83.6761 130.735 71.1112 127.292 59.3642C123.849 47.6172 116.565 37.3697 106.609 30.2671C96.6525 23.1644 84.6021 19.6187 72.3923 20.1993C60.1825 20.7798 48.5217 25.4529 39.2821 33.4682C30.0426 41.4835 23.7603 52.3761 21.4441 64.3969C19.1278 76.4177 20.9119 88.8694 26.51 99.7526C32.108 110.636 41.1952 119.319 52.3125 124.409"
        stroke="#00FBCE"
        strokeLinecap="round"
        strokeWidth="12"
      />
      <path
        className="path"
        d="M127.292 59.3642C123.849 47.6172 116.565 37.3697 106.609 30.2671C96.6525 23.1644 84.6021 19.6187 72.3923 20.1993C60.1825 20.7798 48.5217 25.4529 39.2821 33.4682C30.0426 41.4835 23.7603 52.3761 21.444 64.3969C19.1278 76.4177 20.9119 88.8694 26.51 99.7526C32.108 110.636 41.1952 119.319 52.3125 124.409"
        stroke="#65C6F7"
        strokeLinecap="round"
        strokeWidth="12"
      />
    </svg>
  );
};
