/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini12 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-1-2 ${className}`}
      fill="none"
      height="102"
      viewBox="0 0 144 102"
      width="144"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.2018 38.2534C-0.386806 38.2534 -10.7278 47.2028 -10.7278 47.2028V100.651H154.728V26.8723C154.728 26.8723 149.351 20.5818 140.847 18.5447C125.52 14.873 123.381 41.6784 109.917 41.6784C100.027 41.6784 99.344 24.9292 86.1574 23.4025C72.9708 21.8758 67.0224 60.3389 45.7647 59.9053C29.8401 59.5804 20.1573 38.2534 11.2018 38.2534Z"
        fill="url(#paint0_linear_2004_29626)"
      />
      <path
        className="path"
        d="M-10.4523 47.088C-10.4523 47.088 -0.387052 38.22 11.2016 38.22C20.8464 38.22 29.8399 59.5115 45.7644 59.8358C67.0221 60.2687 72.9705 21.8696 86.1571 23.3938C99.3438 24.918 100.027 41.6393 109.917 41.6393C123.381 41.6393 125.52 14.8785 140.847 18.5441C149.35 20.5778 154.728 26.8579 154.728 26.8579"
        stroke="url(#paint1_linear_2004_29626)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M84.1337 11.0304C84.1337 4.93848 89.0721 0 95.164 0C101.256 0 106.194 4.93847 106.194 11.0304V100.652H84.1337V11.0304Z"
        fill="url(#paint2_linear_2004_29626)"
        opacity="0.3"
      />
      <g className="g" filter="url(#filter0_d_2004_29626)">
        <circle className="circle-4" cx="95.1642" cy="27.3002" fill="url(#paint3_linear_2004_29626)" r="6.89399" />
        <circle className="circle-4" cx="95.1642" cy="27.3002" r="3.89399" stroke="white" strokeWidth="6" />
      </g>
      <path className="path" d="M-10.7278 100.652H154.728" opacity="0.1" stroke="#454459" />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.7871"
          id="filter0_d_2004_29626"
          width="63.788"
          x="63.2703"
          y="0.40625"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29626" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29626"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29626"
          x1="72"
          x2="72"
          y1="18.1992"
          y2="100.651"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#8D79F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29626"
          x1="72.1377"
          x2="72.1377"
          y1="18.1992"
          y2="59.8394"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29626"
          x1="95.164"
          x2="95.164"
          y1="0"
          y2="100.652"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.15" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29626"
          x1="95.1642"
          x2="95.1642"
          y1="20.4062"
          y2="34.1942"
        >
          <stop className="stop" stopColor="#FD95D3" />
          <stop className="stop" offset="1" stopColor="#FF5CBE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
