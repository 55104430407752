/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import {Xceptionswap} from "../Xceptionswap";
import TradingViewWidget from "../PageContentTradingView/PageContentTradingView";

export const PageContentChart = ({  }) => {
  return (
    <div className={`page-content-chart`}>
      <TradingViewWidget/>
      <Xceptionswap state={"opened"} showCollapse={false} withBackground={false}/>
    </div>
  );
};
