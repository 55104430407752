/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini31 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-3-1 ${className}`}
      fill="none"
      height="112"
      viewBox="0 0 144 112"
      width="144"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20.3639 50.6275C7.18149 49.7066 -10.6591 64.1398 -10.6591 64.1398V111.433H154.659V50.6275C154.659 50.6275 141.955 59.0996 133.287 57.6594C119.452 55.3605 127.277 31.5036 113.846 27.4638C104.118 24.5375 97.8008 36.262 88.3386 32.5653C78.8177 28.8457 82.0608 16.1275 73.0339 11.3318C52.6186 0.485908 69.3983 64.5921 46.2853 64.1398C34.8717 63.9164 31.7519 51.4231 20.3639 50.6275Z"
        fill="url(#paint0_linear_2004_29670)"
      />
      <path
        className="path"
        d="M-10.6591 64.1398C-10.6591 64.1398 7.18149 49.7066 20.3639 50.6275C31.7519 51.4231 34.8717 63.9164 46.2853 64.1398C69.3983 64.5921 52.6186 0.485909 73.0339 11.3318C82.0608 16.1275 78.8177 28.8457 88.3386 32.5653C97.8008 36.262 104.118 24.5375 113.846 27.4638C127.277 31.5036 119.452 55.3605 133.287 57.6594C141.955 59.0996 154.659 50.6275 154.659 50.6275"
        stroke="url(#paint1_linear_2004_29670)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M-10.7968 34.496C-10.7968 34.496 2.97766 44.5137 12.367 43.7339C24.0973 42.7597 24.7338 26.117 36.496 25.6717C50.4679 25.1426 50.2776 52.4052 63.7962 48.8355C71.3885 46.8306 71.1801 37.6269 78.6872 35.3232C91.7004 31.33 95.2457 54.7211 108.745 52.9718C119.896 51.5269 119.771 34.3844 130.944 33.1172C140.506 32.0326 143.766 38.4945 154.797 41.5278"
        stroke="url(#paint2_linear_2004_29670)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M3.40442 110.605V0.300781"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M26.1553 110.605V0.300781"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M48.9053 110.605V0.300781"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M71.6553 111.433V1.12891"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M94.4054 111.433V1.12891"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M117.155 111.433V1.12891"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M139.906 111.433V1.12891"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <g className="g" filter="url(#filter0_d_2004_29670)">
        <circle className="circle-4" cx="71.6552" cy="10.2281" fill="url(#paint3_linear_2004_29670)" r="5.51519" />
        <circle className="circle-4" cx="71.6552" cy="10.2281" r="4" stroke="white" strokeWidth="3.03038" />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0312"
          id="filter0_d_2004_29670"
          width="61.0304"
          x="41.14"
          y="-15.2871"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29670" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29670"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29670"
          x1="71.9998"
          x2="71.9998"
          y1="10.0957"
          y2="111.433"
        >
          <stop className="stop" stopColor="#FF9364" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#F25F33" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29670"
          x1="71.9998"
          x2="71.9998"
          y1="10.0957"
          y2="64.1421"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29670"
          x1="72"
          x2="72"
          y1="25.6641"
          y2="53.0655"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29670"
          x1="71.6552"
          x2="71.6552"
          y1="4.71289"
          y2="15.7433"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
      </defs>
    </svg>
  );
};
