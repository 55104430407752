/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Settings } from "../Settings";
import { SettingsInput } from "../SettingsInput";
import { Toggle } from "../Toggle";
import "./style.css";

export const PageContentMevCeptionSettings = ({ screen }) => {
  return (
    <div className={`page-content-mev-ception-settings ${screen}`}>
      {screen === "desktop" && (
        <>
          <div className="mev-ception-settings">
            <Settings />
          </div>
          <div className="div">
            <div className="toggle-container">
              <div className="toggle-2">
                <div className="text">Auto Approved</div>
                <Toggle state="enabled" />
              </div>
            </div>
            <div className="input-container">
              <p className="p">Minimum ETH Value to auto Approve</p>
              <SettingsInput settingsInputClassName="settings-input-instance" />
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-2">Open Token Balance</div>
          </div>
          <div className="div-2">
            <div className="mev-ception-settings-2">
              <div className="text-3">Revoke</div>
            </div>
            <div className="mev-ception-settings-3">
              <div className="text-3">Token Name</div>
            </div>
            <div className="div-3">
              <div className="text-3">Open Balance</div>
            </div>
            <div className="div-3">
              <div className="text-3">Total Tokens</div>
            </div>
            <div className="div-3">
              <div className="text-3">Eth&nbsp;&nbsp;Value</div>
            </div>
          </div>
          <div className="mev-ception-settings-4">
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Usdt</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Pepe</div>
              </div>
              <div className="div-3">
                <SettingsInput value="0.0058" className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">1.78</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Shib</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">3.2</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="disabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Grok</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">1</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Froge</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">3.8</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Dawg</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">2.7</div>
                </div>
              </div>
            </div>
            <div className="div-2">
              <div className="mev-ception-settings-2">
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-3">
                <div className="text-3">Gfy</div>
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
              </div>
              <div className="div-3">
                <div className="frame">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-2">
            <div className="text-wrapper-3">Save</div>
          </div>
        </>
      )}

      {screen === "mobile" && (
        <div className="mev-ception-settings-5">
          <div className="mev-ception-settings-6">
            <div className="text-wrapper-4">Settings</div>
          </div>
          <div className="mev-ception-settings-7">
            <div className="div">
              <div className="mev-ception-settings-8">
                <div className="text-3">Auto Approved</div>
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-9">
                <p className="p">Minimum ETH Value to auto Approve</p>
                <div className="frame-3">
                  <div className="text-wrapper-2">1.05</div>
                </div>
                <SettingsInput settingsInputClassName="settings-input-instance" />
              </div>
            </div>
            <div className="div-wrapper">
              <div className="column">
                <div className="toggle-2">
                  <div className="text-2">Open Token Balance</div>
                </div>
              </div>
            </div>
            <div className="mev-ception-settings-10">
              <div className="mev-ception-settings-11">
                <div className="text-3">Revoke</div>
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-11">
                <div className="text-3">Token Name</div>
                <div className="text-3">Usdt</div>
              </div>
              <div className="mev-ception-settings-10">
                <div className="mev-ception-settings-12">
                  <div className="text-3">Open Balance</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
                <div className="mev-ception-settings-13">
                  <div className="text-3">Total Tokens</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
              </div>
              <div className="mev-ception-settings-14">
                <div className="text-4">Eth&nbsp;&nbsp;Value</div>
                <div className="frame-4">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
            <div className="mev-ception-settings-10">
              <div className="mev-ception-settings-11">
                <div className="text-3">Revoke</div>
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-11">
                <div className="text-3">Token Name</div>
                <div className="text-3">Usdt</div>
              </div>
              <div className="mev-ception-settings-10">
                <div className="mev-ception-settings-12">
                  <div className="text-3">Open Balance</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
                <div className="mev-ception-settings-13">
                  <div className="text-3">Total Tokens</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
              </div>
              <div className="mev-ception-settings-14">
                <div className="text-4">Eth&nbsp;&nbsp;Value</div>
                <div className="frame-4">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
            <div className="mev-ception-settings-10">
              <div className="mev-ception-settings-11">
                <div className="text-3">Revoke</div>
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-11">
                <div className="text-3">Token Name</div>
                <div className="text-3">Usdt</div>
              </div>
              <div className="mev-ception-settings-10">
                <div className="mev-ception-settings-12">
                  <div className="text-3">Open Balance</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
                <div className="mev-ception-settings-13">
                  <div className="text-3">Total Tokens</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
              </div>
              <div className="mev-ception-settings-14">
                <div className="text-4">Eth&nbsp;&nbsp;Value</div>
                <div className="frame-4">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
            <div className="mev-ception-settings-10">
              <div className="mev-ception-settings-11">
                <div className="text-3">Revoke</div>
                <Toggle state="enabled" />
              </div>
              <div className="mev-ception-settings-11">
                <div className="text-3">Token Name</div>
                <div className="text-3">Usdt</div>
              </div>
              <div className="mev-ception-settings-10">
                <div className="mev-ception-settings-12">
                  <div className="text-3">Open Balance</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
                <div className="mev-ception-settings-13">
                  <div className="text-3">Total Tokens</div>
                  <SettingsInput className="instance-node" settingsInputClassName="settings-input-instance" />
                </div>
              </div>
              <div className="mev-ception-settings-14">
                <div className="text-4">Eth&nbsp;&nbsp;Value</div>
                <div className="frame-4">
                  <div className="text-wrapper-2">2.5</div>
                </div>
              </div>
            </div>
            <div className="mev-ception-settings-wrapper">
              <div className="mev-ception-settings-15">
                <div className="text-wrapper-3">Save</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PageContentMevCeptionSettings.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
