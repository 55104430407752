/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import {SocialMission} from "../SocialMission";
import "./style.css";

export const PageContentGameCeptionSocialMissions = ({screen}) => {
  const isDesktop = screen === 'desktop';
  const isMobile = screen === 'mobile';
  return (
    <div className={`page-content-game-ception-social-missions ${screen}`}>
      <div className="social-missions-row">
        <SocialMission
          caption="Follow Gitbook"
          className="social-mission-instance"
          gitbook={true}
          pointsInfo="Point 1"
        />
        <SocialMission
          caption="Follow Twitter"
          className="social-mission-instance"
          twitterX={true}
          pointsInfo="Point 1"
        />
        {isDesktop && (
          <SocialMission
            caption="Comment to Youtube Video"
            className="social-mission-instance"
            youtube={true}
            pointsInfo="Point 1"
          />
        )}
      </div>
      <div className="div">
        <SocialMission
          caption={isDesktop ? "Add XCeption on CMC Watchlist" : "Comment to Youtube Video"}
          className="social-mission-instance"
          coinmarketcap={isDesktop}
          youtube={isMobile}
          pointsInfo="Point 1"
        />
        <SocialMission
          caption={isMobile ? "Add XCeption on CMC Watchlist" : "Like tweet"}
          className="social-mission-instance"
          coinmarketcap={isMobile}
          twitterX={isDesktop}
          pointsInfo="Point 1"
        />
        {isDesktop && (
          <SocialMission
            caption="RT Tweet"
            className="social-mission-instance"
            pointsInfo="Point 1"
            twitterX={true}
          />
        )}
      </div>
      <div className="social-missions-row-2">
        <SocialMission
          caption={isDesktop ? "Follow Youtube Page" : "Like tweet"}
          youtube={isDesktop}
          twitterX={isMobile}
          className="social-mission-instance"
          pointsInfo="Point 1"
        />
        <SocialMission
          caption={isMobile ? "RT Tweet" : "Follow Youtube Video"}
          twitterX={isMobile}
          youtube={isDesktop}
          className="social-mission-instance"
          coingecko={false}
          pointsInfo="Point 1"
        />
        {isDesktop && (
          <SocialMission
            caption="Watch Youtube Video"
            className="social-mission-instance"
            pointsInfo="Point 1"
            youtube={true}
          />
        )}
      </div>
      <div className="social-missions-row-3">
        <SocialMission
          caption={isDesktop ? "Join TG" : "Follow Youtube Page"}
          telegram={isDesktop}
          youtube={isMobile}
          className="social-mission-instance"
          pointsInfo="Point 1"
        />
        <SocialMission
          caption={isMobile ? "Follow Youtube Video" : "Add TG Bot"}
          youtube={isMobile}
          telegram={isDesktop}
          className="social-mission-instance"
          pointsInfo="Point 1"
        />
        {isDesktop && (
          <SocialMission
            caption="Join TG Announcement Channel"
            className="social-mission-instance"
            pointsInfo="Point 1"
            telegram={true}
          />
        )}
      </div>
      {screen === "mobile" && (
        <>
          <div className="social-missions-row-4">
            <SocialMission
              caption="Watch Youtube Video"
              className="social-mission-instance"
              pointsInfo="Point 1"
            />
            <SocialMission
              caption="Join TG"
              className="social-mission-instance"
              pointsInfo="Point 1"
            />
          </div>
          <div className="social-missions-row-4">
            <SocialMission
              caption="Add TG Bot"
              className="social-mission-instance"
              pointsInfo="Point 1"
            />
            <SocialMission
              caption="Join TG Channel"
              className="social-mission-instance"
              pointsInfo="Point 1"
            />
          </div>
        </>
      )}
    </div>
  );
};

PageContentGameCeptionSocialMissions.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
