import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {
  getPageCaption,
  PAGE_COPY_COPY_WALLET,
  PAGE_COPY_MISSIONS,
  PAGE_COPY_OVERVIEW, PAGE_COPY_VIEW_COPIED_WALLET, PAGE_COPY_WALLET_MANAGEMENT,
  PAGE_GAME_LEADERBOARD, PAGE_GAME_MISSIONS,
  PAGE_GAME_OVERVIEW, PAGE_GAME_REFERRALS, PAGE_GAME_SOCIAL_MISSIONS, PAGE_GAME_TRADER_STATISTICS,
  PAGE_HOME, PAGE_MARKET_POINTS, PAGE_MARKET_SETTINGS, PAGE_MEV_COPY_WALLET,
  PAGE_MEV_MISSIONS,
  PAGE_MEV_OVERVIEW,
  PAGE_MEV_SETTINGS, PAGE_MEV_VIEW_COPIED_WALLET, PAGE_MEV_WALLET_MANAGEMENT, PAGE_TELEGRAM,
  PROJECT_COPY,
  PROJECT_GAME, PROJECT_MARKET,
  PROJECT_MEV, PROJECT_NONE
} from "../../constants";

export const SidebarMenu = ({
  currentProject,
  currentPage,
  onPageChange = () => {}
}) => {
  if (currentProject === PROJECT_NONE) {
    return (<></>);

  }
  const item = (caption = null, icon, page, disabled = false) => ({caption: caption || getPageCaption(page), icon, page, disabled});
  const telegramMenuItem = item('Telegram', 'iconoir-telegram-circle-1.svg', PAGE_TELEGRAM)
  const menusByProject = {
    [PROJECT_MEV]: [
      item(null, 'solar-graph-linear-1.svg', PAGE_MEV_OVERVIEW),
      item(null, 'solar-medal-star-linear-2.svg', PAGE_MEV_MISSIONS),
      item(null, 'solar_settings-linear.svg', PAGE_MEV_SETTINGS),
      item(null, 'solar-wallet-linear-1.svg', PAGE_MEV_WALLET_MANAGEMENT),
      telegramMenuItem
    ],
    [PROJECT_GAME]: [
      item(null, 'solar-graph-linear-1.svg', PAGE_GAME_OVERVIEW),
      item(null, 'solar-cup-star-linear-1.svg', PAGE_GAME_LEADERBOARD),
      item(null, 'solar-golf-linear-1.svg', PAGE_GAME_MISSIONS),
      item(null, 'solar-mention-square-linear-1.svg', PAGE_GAME_SOCIAL_MISSIONS),
      item(null, 'solar-link-circle-line-duotone-1.svg', PAGE_GAME_REFERRALS),
      item(null, 'solar-chart-2-linear-1.svg', PAGE_GAME_TRADER_STATISTICS),
      telegramMenuItem
    ],
    [PROJECT_COPY]: [
      item(null, 'solar-graph-linear-1.svg', PAGE_COPY_OVERVIEW),
      item(null, 'solar-medal-star-linear-2.svg', PAGE_COPY_MISSIONS),
      item(null, 'solar-copy-linear-1.svg', PAGE_COPY_COPY_WALLET),
      item(null, 'solar-eye-scan-linear-1.svg', PAGE_COPY_VIEW_COPIED_WALLET),
      item(null, 'solar-wallet-linear-1.svg', PAGE_COPY_WALLET_MANAGEMENT),
      telegramMenuItem
    ],
    [PROJECT_MARKET]: [
      item(null, 'solar-graph-linear-1.svg', PAGE_COPY_OVERVIEW),
      item(null, 'solar_settings-linear.svg', PAGE_MARKET_SETTINGS),
      item(null, 'solar-medal-star-linear-2.svg', PAGE_MARKET_POINTS),
      item(null, 'solar-wallet-linear-1.svg', PAGE_COPY_WALLET_MANAGEMENT),
      telegramMenuItem
    ]
  }

  const menuItems = menusByProject[currentProject];

  console.log('sidebar current page', currentPage);

  return (
    <div className="sidebar-menu">
      {menuItems.map(({caption, icon, page, disabled}) => (
        <div className={`sidebar-menu-item${page === currentPage ? ' active' : ''}${disabled ? ' disabled' : ''}`} onClick={() => !disabled && onPageChange(page)} key={`menu-item-${page}`}>
          <img className={`sidebar-menu-item-icon`} alt={`icon for ${caption} menu item`} src={`/img/${icon}`}/>
          <div className="sidebar-menu-item-text">{caption}</div>
        </div>
      ))}
    </div>
  );
}

SidebarMenu.propTypes = {

}
