/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Vector = ({ color = "#A8B0C1", className }) => {
  return (
    <svg
      className={`vector ${className}`}
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.5 0C2.91579 0 0 2.91579 0 6.5C0 10.0842 2.91579 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91579 10.0842 0 6.5 0ZM7.93975 9.90385C7.58173 10.0446 6.34075 10.6372 5.62575 10.0073C5.41235 9.81987 5.30614 9.5821 5.30614 9.29338C5.30614 8.75277 5.48385 8.28152 5.80398 7.15C5.86027 6.93602 5.9291 6.65873 5.9291 6.43877C5.9291 6.0591 5.785 5.95835 5.39448 5.95835C5.20377 5.95835 4.99254 6.02602 4.80134 6.09752L4.90696 5.66475C5.33325 5.49139 5.86839 5.28014 6.32664 5.28014C7.01402 5.28014 7.51998 5.62302 7.51998 6.27523C7.51998 6.46314 7.48748 6.7925 7.41923 7.02L7.02377 8.4186C6.942 8.70135 6.79413 9.32477 7.02325 9.5095C7.2486 9.69202 7.78212 9.59511 8.04537 9.47102L7.93975 9.90385ZM7.2865 4.33334C6.838 4.33334 6.474 3.96934 6.474 3.52084C6.474 3.07234 6.838 2.70834 7.2865 2.70834C7.735 2.70834 8.099 3.07234 8.099 3.52084C8.099 3.96934 7.735 4.33334 7.2865 4.33334Z"
        fill={color}
      />
    </svg>
  );
};

Vector.propTypes = {
  color: PropTypes.string,
};
