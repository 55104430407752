import PropTypes from "prop-types";
import React, {useState} from "react";
import "./style.css";

export const UserMenuPanel = () => {
  return (<div className="user-menu-panel">
    <span className="user-wallet-number">0X423...1EEA</span>
    <span className="user-menu-panel-dropdown-icon">
      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.57556 3.62449L0.175815 1.22474C0.00333299 1.05225 -0.0472867 0.795406 0.0464535 0.570429C0.140194 0.345453 0.357671 0.199219 0.601395 0.199219H5.39902C5.64087 0.199219 5.86022 0.345453 5.95396 0.570429C6.0477 0.795406 5.99521 1.05225 5.8246 1.22474L3.42485 3.62449C3.1905 3.85884 2.80991 3.85884 2.57556 3.62449Z" fill="#02FFFA"/>
      </svg>
    </span>
  </div>)
};
