import PropTypes from "prop-types";
import React, {useState} from "react";
import {TabItem} from "../TabItem";
import "./style.css";
import {SCREEN_DESKTOP} from "../../constants";
import {Checkbox} from "../Checkbox/Checkbox";
import {RectangleButton} from "../RectangleButton";

const TAB_OPEN_SNIPES = 'open-snipes';
const TAB_PREVIOUS_SNIPES = 'previous-snipes';
const TAB_SNIPE_HISTORY = 'snipe-history';
const TAB_WALLETS = 'wallets';
const TAB_OPEN_TRADES = 'open-trades';
const TAB_NUKE_VALUE = 'nuke-value';

export const PageContentSnipes = ({selectedTab = TAB_OPEN_SNIPES, className, screen = SCREEN_DESKTOP}) => {

  const [tab, setCurrentTab] = useState(selectedTab);

  const handleTabClick = newTab => setCurrentTab(newTab);

  return (
    <div className={`page-content-snipes ${className} ${screen}`}>
      <div className="page-content-snipes-container">
        <div className="tab-header-container">
          <div className="tabs-wrapper">
            <div className="tabs-container">
              <TabItem
                caption="Open Snipes"
                className="tab-item-instance"
                divClassName="instance-node"
                onClick={() => handleTabClick(TAB_OPEN_SNIPES)}
                state={tab === TAB_OPEN_SNIPES ? "active" : "default"}
              />
              <TabItem
                caption="Previous Snipes"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_PREVIOUS_SNIPES)}
                state={tab === TAB_PREVIOUS_SNIPES ? "active" : "default"}
              />
              <TabItem
                caption="Snipe History"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_SNIPE_HISTORY)}
                state={tab === TAB_SNIPE_HISTORY ? "active" : "default"}
              />
              <TabItem
                caption="Wallets"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_WALLETS)}
                state={tab === TAB_WALLETS ? "active" : "default"}
              />
              <TabItem
                caption="Open Trades"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_OPEN_TRADES)}
                state={tab === TAB_OPEN_TRADES ? "active" : "default"}
              />
              <TabItem
                caption="Nuke Value"
                className="tab-item-instance"
                onClick={() => handleTabClick(TAB_NUKE_VALUE)}
                state={tab === TAB_NUKE_VALUE ? "active" : "default"}
              />
            </div>
            <div className="filter-container">
              <Checkbox label={"Hide Other Pairs"}/>
              <img className="close-container" alt="Close container" src="/img/close-container-5.svg"/>
            </div>
          </div>
        </div>
        <div className="page-content-snipes-content">
          {(tab === TAB_OPEN_SNIPES || tab === TAB_PREVIOUS_SNIPES || tab === TAB_SNIPE_HISTORY || tab === TAB_OPEN_TRADES) && <table className="snipes-table">
            <thead>
            <tr>
              <th>#</th>
              <th>Token Name</th>
              <th>Token Amount</th>
              <th>Amount Spend</th>
              <th>Profit Loss</th>
              <th>Break Even</th>
              <th>Market Cap</th>
              <th>DCA Break Even</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>Token Name Here</td>
              <td>£ 150</td>
              <td>£ 150</td>
              <td>£ 50</td>
              <td>234</td>
              <td>34</td>
              <td>345</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Token Name Here</td>
              <td>£ 150</td>
              <td>£ 150</td>
              <td>£ 50</td>
              <td>234</td>
              <td>34</td>
              <td>345</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Token Name Here</td>
              <td>£ 150</td>
              <td>£ 150</td>
              <td>£ 50</td>
              <td>234</td>
              <td>34</td>
              <td>345</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Token Name Here</td>
              <td>£ 150</td>
              <td>£ 150</td>
              <td>£ 50</td>
              <td>234</td>
              <td>34</td>
              <td>345</td>
            </tr>
            </tbody>
          </table>}
          {tab === TAB_NUKE_VALUE && <table className="snipes-table nuke-value">
            <thead>
            <tr>
              <th></th>
              <th>Current Value</th>
              <th>Nuke Value</th>
              <th>Current P&L</th>
              <th>Nuke P&L</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><Checkbox/></td>
              <td>1.0 ETH</td>
              <td>0.9 ETH</td>
              <td>550%</td>
              <td>450%</td>
            </tr>
            <tr>
              <td><Checkbox/></td>
              <td>1.0 ETH</td>
              <td>0.9 ETH</td>
              <td>550%</td>
              <td>450%</td>
            </tr>
            <tr>
              <td><Checkbox/></td>
              <td>1.0 ETH</td>
              <td>0.9 ETH</td>
              <td>550%</td>
              <td>450%</td>
            </tr>
            <tr>
              <td><Checkbox/></td>
              <td>1.0 ETH</td>
              <td>0.9 ETH</td>
              <td>550%</td>
              <td>450%</td>
            </tr>
            </tbody>
          </table> }
          {tab === TAB_WALLETS && <div>
            <div className="buttons-container">
              <RectangleButton caption="Wallet 1" type="selected"/>
              <RectangleButton caption="Wallet 2" type="default"/>
              <RectangleButton caption="Wallet 3" type="default"/>
            </div>
            <div>
              <table className="snipes-wallets-table">
                <tr>
                  <td>Token Name:</td>
                  <td>Token Name Here</td>
                </tr>
                <tr>
                  <td>Balance:</td>
                  <td>er5hs</td>
                </tr>
                <tr>
                  <td>ETH Value:</td>
                  <td>er5hs</td>
                </tr>
              </table>
            </div>
          </div>}
        </div>
        <div className="page-content-snipes-footer">
          <p className="div">
            <span className="span">BNB/BUSD </span>
            <span className="text-wrapper-2">+8.39 %</span>
            <span className="span">&nbsp;</span>
            <span className="text-wrapper-3">384.8200</span>
          </p>
          <p className="div">
            <span className="span">BTC/BUSD </span>
            <span className="text-wrapper-2">+0.53 %</span>
            <span className="span">&nbsp;</span>
            <span className="text-wrapper-3">39728.27</span>
          </p>
          <p className="div">
            <span className="span">ETH/BUSD </span>
            <span className="text-wrapper-2">+4.53 %</span>
            <span className="span">&nbsp;</span>
            <span className="text-wrapper-3">2728.14</span>
          </p>
        </div>
      </div>
    </div>
  );
};

PageContentSnipes.propTypes = {
  selectedTab: PropTypes.oneOf(["snipe-history", "open-snipes", "previous-snipes", "nuke-value", "open-trades", "wallets"]),
};
