/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ArcGraph } from "../../icons/ArcGraph";
import { ArcGraphSimple } from "../../icons/ArcGraphSimple";
import { Chart1 } from "../../icons/Chart1";
import { Chart2 } from "../../icons/Chart2";
import { Chart3 } from "../../icons/Chart3";
import { Chart4 } from "../../icons/Chart4";
import { ChartMini1 } from "../../icons/ChartMini1";
import { ChartMini2 } from "../../icons/ChartMini2";
import { ChartMini3 } from "../../icons/ChartMini3";
import { ChartMini4 } from "../../icons/ChartMini4";
import { CircleGraph } from "../../icons/CircleGraph";
import { TypeDefault } from "../TypeDefault";
import "./style.css";
import {Checkbox} from "../Checkbox/Checkbox";

export const PageContentCopyCeptionDashboard = ({ screen }) => {
  return (
    <div className={`page-content-copy-ception-dashboard ${screen}`}>
      {screen === "desktop" && (
        <>
          <div className="copy-ception">
            <div className="text-wrapper">Overview</div>
          </div>
          <div className="div">
            <div className="overview-charts">
              <div className="frame">
                <div className="frame-wrapper">
                  <div className="frame-2">
                    <div className="column">
                      <div className="frame-3">
                        <div className="text-wrapper-2">123</div>
                        <div className="text-wrapper-3">$5.984</div>
                      </div>
                      <div className="frame-4">
                        <Chart1 className="chart" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="frame-3">
                        <div className="text-wrapper-2">123</div>
                        <div className="text-wrapper-3">$5.984</div>
                      </div>
                      <div className="frame-4">
                        <Chart2 className="chart-2" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="frame-3">
                        <div className="text-wrapper-2">123</div>
                        <div className="text-wrapper-3">$5.984</div>
                      </div>
                      <div className="frame-4">
                        <Chart3 className="chart-3" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                    <div className="column-3">
                      <div className="frame-3">
                        <div className="text-wrapper-2">123</div>
                        <div className="text-wrapper-3">$5.984</div>
                      </div>
                      <div className="frame-4">
                        <Chart4 className="chart" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-5">
                  <div className="column-wrapper">
                    <div className="column-4">
                      <div className="frame-6">
                        <div className="text-wrapper-5">ETH Gained</div>
                      </div>
                      <div className="frame-7">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group">
                            <div className="text-wrapper-6">99</div>
                            <ArcGraphSimple className="arc-graph-simple" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-8">
                    <div className="column-5">
                      <div className="frame-6">
                        <div className="text-wrapper-5">ETH Bought</div>
                      </div>
                      <div className="circle-graph-wrapper">
                        <CircleGraph className="circle-graph" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                    <div className="column-5">
                      <div className="frame-6">
                        <div className="text-wrapper-5">ETH Sold</div>
                      </div>
                      <div className="circle-graph-wrapper">
                        <CircleGraph className="circle-graph" color="url(#paint0_linear_2041_6277)" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                    <div className="column-5">
                      <div className="frame-6">
                        <div className="text-wrapper-5">ETH Sold</div>
                      </div>
                      <div className="circle-graph-wrapper">
                        <CircleGraph className="circle-graph" />
                      </div>
                      <div className="div-wrapper">
                        <div className="text-wrapper-4">$5.984</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-9">
                  <div className="frame-10">
                    <div className="column-6">
                      <div className="frame-6">
                        <div className="text-wrapper-5">Buy vs Sells</div>
                      </div>
                      <div className="group-wrapper">
                        <div className="group">
                          <ArcGraph className="arc-graph" />
                        </div>
                      </div>
                      <div className="frame-11">
                        <div className="frame-12">
                          <div className="ellipse" />
                          <div className="text-wrapper-7">Buy</div>
                        </div>
                        <div className="frame-13">
                          <div className="ellipse-2" />
                          <div className="text-wrapper-7">Sells</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-10">
                    <div className="column-7">
                      <div className="frame-6">
                        <div className="text-wrapper-5">Biggest Win</div>
                      </div>
                      <div className="frame-14">
                        <div className="arc-graph-wrapper">
                          <ArcGraph className="arc-graph" />
                        </div>
                      </div>
                      <div className="frame-11">
                        <div className="frame-12">
                          <div className="ellipse" />
                          <div className="text-wrapper-7">Win</div>
                        </div>
                        <div className="frame-13">
                          <div className="ellipse-2" />
                          <div className="text-wrapper-7">Loses</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-15">
                    <div className="column-4">
                      <div className="frame-6">
                        <div className="text-wrapper-5">Active contract</div>
                      </div>
                      <div className="frame-16">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group-2">
                            <div className="text-wrapper-8">100</div>
                            <ArcGraphSimple className="arc-graph-simple-16" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-15">
                    <div className="column-4">
                      <div className="frame-6">
                        <div className="text-wrapper-5">ETH Gained</div>
                      </div>
                      <div className="frame-16">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group-2">
                            <div className="text-wrapper-9">99</div>
                            <ArcGraphSimple className="arc-graph-simple-16" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-ception">
            <div className="copy-ception-2">
              <div className="text-wrapper-10">Open Trades</div>
            </div>
            <div className="copy-ception-3">
              <div className="copy-ception-wrapper">
                <div className="copy-ception-4">
                  <div className="cell">
                    <div className="content-2">
                      <div className="frame-17">
                        <div className="rectangle-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TypeDefault className="type-default-instance" text="Coin Name" textClassName="instance-node" />
                  <TypeDefault className="type-default-instance" text="Quantity" textClassName="instance-node" />
                  <TypeDefault className="type-default-instance" text="Wallet Copied" textClassName="cell-2" />
                  <TypeDefault className="type-default-2" text="Value" textClassName="cell-2" />
                  <TypeDefault className="type-default-instance" text="P&amp;L" textClassName="cell-2" />
                </div>
              </div>
              <div className="copy-ception-wrapper-2">
                <div className="copy-ception-4">
                  <div className="content-wrapper">
                    <div className="content-2">
                      <Checkbox/>
                    </div>
                  </div>
                  <TypeDefault className="type-default-instance" text="Lorem Ipsum" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="15" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="Whalet 1" textClassName="copy-ception-6" />
                  <TypeDefault
                    className="type-default-2"
                    text="$328,126.76 (@ $1,983.14/ETH)"
                    textClassName="copy-ception-6"
                  />
                  <TypeDefault className="type-default-instance" text="$15,000,000" textClassName="copy-ception-6" />
                </div>
              </div>
              <div className="copy-ception-wrapper-2">
                <div className="copy-ception-4">
                  <div className="content-wrapper">
                    <div className="content-2">
                      <Checkbox/>
                    </div>
                  </div>
                  <TypeDefault className="type-default-instance" text="Lorem Ipsum" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="15" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="Whalet 2" textClassName="copy-ception-6" />
                  <TypeDefault
                    className="type-default-2"
                    text="$328,126.76 (@ $1,983.14/ETH)"
                    textClassName="copy-ception-6"
                  />
                  <TypeDefault className="type-default-instance" text="$15,000,000" textClassName="copy-ception-6" />
                </div>
              </div>
              <div className="copy-ception-wrapper-2">
                <div className="copy-ception-4">
                  <div className="content-wrapper">
                    <div className="content-2">
                      <Checkbox/>
                    </div>
                  </div>
                  <TypeDefault className="type-default-instance" text="Lorem Ipsum" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="15" textClassName="copy-ception-5" />
                  <TypeDefault className="type-default-instance" text="Whalet 3" textClassName="copy-ception-6" />
                  <TypeDefault
                    className="type-default-2"
                    text="$328,126.76 (@ $1,983.14/ETH)"
                    textClassName="copy-ception-6"
                  />
                  <TypeDefault className="type-default-instance" text="$15,000,000" textClassName="copy-ception-6" />
                </div>
              </div>
              <div className="rectangle-3" />
            </div>
          </div>
        </>
      )}

      {screen === "mobile" && (
        <>
          <div className="copy-ception-2">
            <div className="text-wrapper-10">Overview</div>
          </div>
          <div className="overview-charts-2">
            <div className="element-columns">
              <div className="element-columns-2">
                <div className="column-8">
                  <div className="text-wrapper-11">123</div>
                  <div className="text-wrapper-12">Day</div>
                  <div className="chart-mini-wrapper">
                    <ChartMini1 className="chart-mini" />
                  </div>
                  <div className="text-wrapper-13">Lorem</div>
                </div>
                <div className="column-8">
                  <div className="text-wrapper-11">123</div>
                  <div className="text-wrapper-12">Week</div>
                  <div className="chart-mini-2-wrapper">
                    <ChartMini2 className="chart-mini-2" />
                  </div>
                  <div className="text-wrapper-13">Lorem</div>
                </div>
              </div>
              <div className="element-columns-2">
                <div className="column-8">
                  <div className="text-wrapper-11">123</div>
                  <div className="text-wrapper-12">Month</div>
                  <div className="text-wrapper-11">
                    <ChartMini3 className="chart-mini-3" />
                  </div>
                  <div className="text-wrapper-13">Lorem</div>
                </div>
                <div className="column-8">
                  <div className="text-wrapper-11">123</div>
                  <div className="text-wrapper-12">Year</div>
                  <div className="chart-mini-wrapper">
                    <ChartMini4 className="chart-mini" />
                  </div>
                  <div className="text-wrapper-13">Lorem</div>
                </div>
              </div>
            </div>
            <div className="element-columns-wrapper">
              <div className="element-columns-2">
                <div className="column-9">
                  <div className="text-wrapper-14">Total Market&nbsp;&nbsp;Maker Trades</div>
                  <div className="circle-graph">
                    <div className="group-2">
                      <div className="overlap-group-wrapper-2">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-15">99</div>
                          <ArcGraphSimple className="arc-graph-simple-14" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="element-columns">
              <div className="element-columns-2">
                <div className="column-11">
                  <div className="text-wrapper-14">ETH Bought</div>
                  <div className="circle-graph-instance-wrapper">
                    <CircleGraph className="circle-graph-2" />
                  </div>
                  <div className="text-wrapper-16">$5.984</div>
                </div>
                <div className="column-11">
                  <div className="text-wrapper-14">ETH Bought</div>
                  <div className="circle-graph-instance-wrapper">
                    <CircleGraph className="circle-graph-2" />
                  </div>
                  <div className="text-wrapper-16">$5.984</div>
                </div>
                <div className="column-11">
                  <div className="text-wrapper-14">Gas Fees Paid</div>
                  <div className="circle-graph-instance-wrapper">
                    <CircleGraph className="circle-graph-2" />
                  </div>
                  <div className="text-wrapper-16">$5.984</div>
                </div>
              </div>
            </div>
            <div className="element-columns-wrapper">
              <div className="element-columns-2">
                <div className="column-9">
                  <div className="text-wrapper-14">Buy vs Sells</div>
                  <div className="frame-18">
                    <div className="group-3">
                      <div className="">
                        <div className="ellipse-3" />
                        <ArcGraph className="arc-graph-11" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-12">
                      <div className="ellipse" />
                      <div className="text-wrapper-7">Buy</div>
                    </div>
                    <div className="frame-13">
                      <div className="ellipse-2" />
                      <div className="text-wrapper-7">Sells</div>
                    </div>
                  </div>
                </div>
                <div className="column-9">
                  <div className="text-wrapper-14">Biggest Win</div>
                  <div className="frame-18">
                    <div className="group-3">
                      <div className="">
                        <div className="ellipse-3" />
                        <ArcGraph className="arc-graph-11" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-12">
                      <div className="ellipse" />
                      <div className="text-wrapper-7">Win</div>
                    </div>
                    <div className="frame-13">
                      <div className="ellipse-2" />
                      <div className="text-wrapper-7">Loses</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="element-columns-wrapper">
              <div className="element-columns-2">
                <div className="column-9">
                  <div className="text-wrapper-14">Active Contract</div>
                  <div className="frame-18">
                    <div className="group-4">
                      <div className="overlap-group-wrapper-2">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-17">100</div>
                          <ArcGraphSimple className="arc-graph-simple-14" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-9">
                  <div className="text-wrapper-14">Current P&amp;L</div>
                  <div className="frame-18">
                    <div className="group-4">
                      <div className="overlap">
                        <div className="arc-graph-11">
                          <div className="overlap-group-3">
                            <div className="element-ETH">
                              0.75
                              <br />
                              ETH
                            </div>
                            <ArcGraphSimple className="arc-graph-simple-14" />
                          </div>
                        </div>
                        <div className="ellipse-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-ception-2">
            <div className="text-wrapper-10">Open Trades</div>
          </div>
          <div className="copy-ception-8">
            <div className="copy-ception-wrapper">
              <div className="copy-ception-9">
                <div className="cell">
                  <div className="content-2">
                    <div className="frame-17">
                      <div className="rectangle-wrapper">
                        <Checkbox/>
                      </div>
                    </div>
                  </div>
                </div>
                <TypeDefault className="type-default-3" text="Coin Name" textClassName="instance-node" />
                <TypeDefault className="type-default-3" text="Quantity" textClassName="instance-node" />
                <TypeDefault className="type-default-4" text="Wallet Copied" textClassName="cell-2" />
                <TypeDefault className="type-default-4" text="Value" textClassName="cell-2" />
                <TypeDefault className="type-default-4" text="P&amp;L" textClassName="cell-2" />
              </div>
            </div>
            <div className="copy-ception-wrapper-2">
              <div className="copy-ception-9">
                <div className="content-wrapper">
                  <div className="content-2">
                    <Checkbox/>
                  </div>
                </div>
                <TypeDefault className="type-default-3" text="Lorem Ipsum" textClassName="copy-ception-5" />
                <TypeDefault className="type-default-3" text="15" textClassName="copy-ception-5" />
                <TypeDefault className="type-default-4" text="Whale 1" textClassName="copy-ception-6" />
                <TypeDefault
                  className="type-default-4"
                  text="$328,126.76 (@ $1,983.14/ETH)"
                  textClassName="copy-ception-6"
                />
                <TypeDefault className="type-default-4" text="$15,000,000" textClassName="copy-ception-6" />
              </div>
            </div>
            <div className="copy-ception-wrapper-2">
              <div className="copy-ception-9">
                <div className="content-wrapper">
                  <div className="content-2">
                    <Checkbox/>
                  </div>
                </div>
                <TypeDefault className="type-default-3" text="Lorem Ipsum" textClassName="copy-ception-5" />
                <TypeDefault className="type-default-3" text="15" textClassName="copy-ception-5" />
                <TypeDefault className="type-default-4" text="Whale 2" textClassName="copy-ception-6" />
                <TypeDefault
                  className="type-default-4"
                  text="$328,126.76 (@ $1,983.14/ETH)"
                  textClassName="copy-ception-6"
                />
                <TypeDefault className="type-default-4" text="$15,000,000" textClassName="copy-ception-6" />
              </div>
            </div>
            <div className="copy-ception-wrapper-2">
              <div className="copy-ception-9">
                <div className="content-wrapper">
                  <div className="content-2">
                    <Checkbox/>
                  </div>
                </div>
                <TypeDefault className="type-default-3" text="Lorem Ipsum" textClassName="copy-ception-5" />
                <TypeDefault className="type-default-3" text="15" textClassName="copy-ception-5" />
                <TypeDefault className="copy-ception-10" text="Whale 3" textClassName="copy-ception-6" />
                <TypeDefault
                  className="copy-ception-10"
                  text="$328,126.76 (@ $1,983.14/ETH)"
                  textClassName="copy-ception-6"
                />
                <TypeDefault className="copy-ception-10" text="$15,000,000" textClassName="copy-ception-6" />
              </div>
            </div>
            <div className="rectangle-3" />
          </div>
        </>
      )}
    </div>
  );
};

PageContentCopyCeptionDashboard.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
