/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AdvancedSniperPropertyIcon31 = ({ className }) => {
  return (
    <svg
      className={`advanced-sniper-property-icon-31 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.768 2.30309L12.2006 1.87042C12.5449 1.52626 13.0117 1.33295 13.4985 1.33301C13.9853 1.33307 14.4521 1.5265 14.7963 1.87075C15.1405 2.21501 15.3338 2.68188 15.3337 3.16866C15.3336 3.65544 15.1402 4.12226 14.796 4.46642L14.3626 4.89909C14.3626 4.89909 13.444 4.84509 12.6333 4.03375C11.822 3.22309 11.768 2.30375 11.768 2.30375L7.79129 6.27975C7.52196 6.54909 7.38729 6.68375 7.27129 6.83242C7.13463 7.00709 7.01796 7.19709 6.92196 7.39775C6.84129 7.56775 6.78129 7.74842 6.66063 8.10975L6.27463 9.26642M6.27463 9.26642L6.02529 10.0144C5.99597 10.1017 5.99156 10.1953 6.01257 10.2849C6.03358 10.3746 6.07917 10.4565 6.14421 10.5216C6.20925 10.5867 6.29117 10.6324 6.38075 10.6535C6.47033 10.6746 6.56403 10.6703 6.65129 10.6411L7.39996 10.3918M6.27463 9.26642L7.39996 10.3918M14.3633 4.89842L10.3866 8.87509C10.1173 9.14442 9.98263 9.27909 9.83396 9.39509C9.65876 9.53171 9.46919 9.64885 9.26863 9.74442C9.09863 9.82509 8.91796 9.88509 8.55663 10.0058L7.39996 10.3918"
        stroke="#A8B0C1"
      />
      <path
        className="path"
        d="M15.3333 7.99967C15.3333 11.6817 12.3487 14.6663 8.66667 14.6663C4.98467 14.6663 2 11.6817 2 7.99967C2 4.31767 4.98467 1.33301 8.66667 1.33301"
        stroke="#A8B0C1"
        strokeLinecap="round"
      />
    </svg>
  );
};
