/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Cell } from "../Cell";
import { Row } from "../Row";
import { Type } from "../Type";
import { TypeDefault } from "../TypeDefault";
import { TypeType } from "../TypeType";
import "./style.css";
import {Checkbox} from "../Checkbox/Checkbox";

export const PageContentCryptoStatsDesktop = ({
  property1,
  className,
  row = <TypeDefault className="design-component-instance-node" text="Break Even" textClassName="cell-2" />,
  override = <TypeDefault className="design-component-instance-node" text="DCA Break Even" textClassName="cell-2" />,
  row1 = <TypeDefault className="design-component-instance-node" text="Market Cap" textClassName="cell-2" />,
  row2 = (
    <TypeDefault className="design-component-instance-node" text="Token Amount" textClassName="type-default-instance" />
  ),
  row3 = (
    <TypeDefault className="design-component-instance-node" text="Amount Spend" textClassName="type-default-instance" />
  ),
  row4 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name"
      textClassName="cell-2"
    />
  ),
  row5 = (
    <TypeType
      className="design-component-instance-node"
      frameClassName="type-instance"
      text="Profit Loss"
      textClassName="type-type-instance"
      vector="/img/crypto-market-arrow-down.svg"
    />
  ),
  row6 = <Cell className="cell-type" text="#" textClassName="cell-type3" />,
  row7 = <TypeDefault className="design-component-instance-node" text="34%" textClassName="cell-3" />,
  row8 = <TypeDefault className="design-component-instance-node" text="345" textClassName="cell-3" />,
  row9 = <TypeDefault className="design-component-instance-node" text="34" textClassName="cell-3" />,
  row10 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row11 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row12 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name Here"
      textClassName="cell-3"
    />
  ),
  row13 = <TypeDefault className="design-component-instance-node" text="$ 50" textClassName="cell-3" />,
  row14 = <Cell className="cell-type" text="1" textClassName="cell-instance" />,
  row15 = <TypeDefault className="design-component-instance-node" text="34%" textClassName="cell-3" />,
  row16 = <TypeDefault className="design-component-instance-node" text="345" textClassName="cell-3" />,
  row17 = <TypeDefault className="design-component-instance-node" text="34" textClassName="cell-3" />,
  row18 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row19 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row20 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name Here"
      textClassName="cell-3"
    />
  ),
  row21 = <TypeDefault className="design-component-instance-node" text="$ 50" textClassName="cell-3" />,
  row22 = <Cell className="cell-type" text="2" textClassName="cell-instance" />,
  row23 = <TypeDefault className="design-component-instance-node" text="34%" textClassName="cell-3" />,
  row24 = <TypeDefault className="design-component-instance-node" text="345" textClassName="cell-3" />,
  row25 = <TypeDefault className="design-component-instance-node" text="34" textClassName="cell-3" />,
  row26 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row27 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row28 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name Here"
      textClassName="cell-3"
    />
  ),
  row29 = <TypeDefault className="design-component-instance-node" text="$ 50" textClassName="cell-3" />,
  row30 = <Cell className="cell-type" text="3" textClassName="cell-instance" />,
  row31 = <TypeDefault className="design-component-instance-node" text="34%" textClassName="cell-3" />,
  row32 = <TypeDefault className="design-component-instance-node" text="345" textClassName="cell-3" />,
  row33 = <TypeDefault className="design-component-instance-node" text="34" textClassName="cell-3" />,
  row34 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row35 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row36 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name Here"
      textClassName="cell-3"
    />
  ),
  row37 = <TypeDefault className="design-component-instance-node" text="$ 50" textClassName="cell-3" />,
  row38 = <Cell className="cell-type" text="4" textClassName="cell-instance" />,
  row39 = <TypeDefault className="design-component-instance-node" text="34%" textClassName="cell-3" />,
  row40 = <TypeDefault className="design-component-instance-node" text="345" textClassName="cell-3" />,
  row41 = <TypeDefault className="design-component-instance-node" text="34" textClassName="cell-3" />,
  row42 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row43 = <TypeDefault className="design-component-instance-node" text="$ 150" textClassName="cell-4" />,
  row44 = (
    <Type
      className="design-component-instance-node"
      contentClassName="type-type4"
      text="Token Name Here"
      textClassName="cell-3"
    />
  ),
  row45 = <TypeDefault className="design-component-instance-node" text="$ 50" textClassName="cell-3" />,
  row46 = <Cell className="cell-type" text="5" textClassName="cell-instance" />,
}) => {
  return (
    <div className={`crypto-stats-desktop ${property1} ${className}`}>
      <div className="crypto-stats-header">
        {property1 === "open-snipes" && (
          <div className="crypto-stats-header-2">
            <div className="div-2">
              <div className="column">
                <div className="text-wrapper-2">Open Snipes</div>
              </div>
              <div className="column-2">
                <div className="text-wrapper-3">Previous Snipes</div>
              </div>
              <div className="column-2">
                <div className="text-wrapper-3">Snipe History</div>
              </div>
              <div className="column-2">
                <div className="text-wrapper-3">Wallets</div>
              </div>
              <div className="column-2">
                <div className="text-wrapper-3">Open Trades</div>
              </div>
              <div className="column-2">
                <div className="text-wrapper-3">Nuke Value</div>
              </div>
            </div>
            <div className="crypto-stats-header-3">
              <Checkbox label={"Hide Other Pairs"}/>
              <img className="img" alt="Frame" src="/img/crypto-stats-cross.svg" />
            </div>
          </div>
        )}

        {property1 === "wallets" && (
          <>
            <div className="crypto-stats-header-wrapper">
              <div className="crypto-stats-header-4">
                <div className="column-3">
                  <div className="text-wrapper-3">Open Snipes</div>
                </div>
                <div className="column-3">
                  <div className="text-wrapper-3">Previous Snipes</div>
                </div>
                <div className="column-3">
                  <div className="text-wrapper-3">Snipe History</div>
                </div>
                <div className="column-4">
                  <div className="text-wrapper-2">Wallets</div>
                </div>
                <div className="column-3">
                  <div className="text-wrapper-3">Wallets</div>
                </div>
                <div className="column-3">
                  <div className="text-wrapper-3">Open Trades</div>
                </div>
                <div className="column-3">
                  <div className="text-wrapper-3">Nuke Value</div>
                </div>
              </div>
            </div>
            <div className="group">
              <div className="text-wrapper-5">Hide Other Pairs</div>
              <div className="rectangle-2" />
            </div>
          </>
        )}
      </div>
      {property1 === "wallets" && (
        <div className="frame-3">
          <div className="frame-4">
            <div className="frame-5">
              <div className="text-wrapper-6">Wallet 1</div>
            </div>
            <div className="frame-6">
              <div className="text-wrapper-7">Wallet 2</div>
            </div>
            <div className="frame-6">
              <div className="text-wrapper-7">Wallet 3</div>
            </div>
          </div>
          <div className="frame-7">
            <div className="div-2">
              <div className="column-5">
                <div className="text-wrapper-8">Token Name:</div>
                <div className="text-wrapper-9">Balance:</div>
                <div className="text-wrapper-9">ETH Value:</div>
              </div>
              <div className="column-5">
                <div className="text-wrapper-10">Token Name Here</div>
                <div className="text-wrapper-11">£ 459</div>
                <div className="text-wrapper-11">£ 459</div>
              </div>
            </div>
            <div className="frame-8">
              <p className="p">
                <span className="span">BNB/BUSD </span>
                <span className="text-wrapper-12">+8.39 %</span>
                <span className="span">&nbsp;</span>
                <span className="text-wrapper-13">384.8200</span>
              </p>
              <p className="p">
                <span className="span">BTC/BUSD </span>
                <span className="text-wrapper-12">+0.53 %</span>
                <span className="span">&nbsp;</span>
                <span className="text-wrapper-13">39728.27</span>
              </p>
              <p className="p">
                <span className="span">ETH/BUSD </span>
                <span className="text-wrapper-12">+4.53 %</span>
                <span className="span">&nbsp;</span>
                <span className="text-wrapper-13">2728.14</span>
              </p>
            </div>
          </div>
        </div>
      )}

      {property1 === "open-snipes" && (
        <>
          <div className="crypto-stats-body">
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row6}
              override1={row4}
              override2={row2}
              override3={row3}
              override4={row5}
              override5={row}
              override6={row1}
              override7={override}
            />
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row14}
              override1={row12}
              override2={row10}
              override3={row11}
              override4={row13}
              override5={row7}
              override6={row9}
              override7={row8}
            />
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row22}
              override1={row20}
              override2={row18}
              override3={row19}
              override4={row21}
              override5={row15}
              override6={row17}
              override7={row16}
            />
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row30}
              override1={row28}
              override2={row26}
              override3={row27}
              override4={row29}
              override5={row23}
              override6={row25}
              override7={row24}
            />
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row38}
              override1={row36}
              override2={row34}
              override3={row35}
              override4={row37}
              override5={row31}
              override6={row33}
              override7={row32}
            />
            <Row
              visible={false}
              visible1={false}
              className="row-instance"
              override={row46}
              override1={row44}
              override2={row42}
              override3={row43}
              override4={row45}
              override5={row39}
              override6={row41}
              override7={row40}
            />
          </div>
          <div className="frame-9">
            <p className="p">
              <span className="span">BNB/BUSD </span>
              <span className="text-wrapper-12">+8.39 %</span>
              <span className="span">&nbsp;</span>
              <span className="text-wrapper-13">384.8200</span>
            </p>
            <p className="p">
              <span className="span">BTC/BUSD </span>
              <span className="text-wrapper-12">+0.53 %</span>
              <span className="span">&nbsp;</span>
              <span className="text-wrapper-13">39728.27</span>
            </p>
            <p className="p">
              <span className="span">ETH/BUSD </span>
              <span className="text-wrapper-12">+4.53 %</span>
              <span className="span">&nbsp;</span>
              <span className="text-wrapper-13">2728.14</span>
            </p>
          </div>
          <div className="rectangle-3" />
        </>
      )}
    </div>
  );
};

PageContentCryptoStatsDesktop.propTypes = {
  property1: PropTypes.oneOf(["open-snipes", "wallets"]),
};
