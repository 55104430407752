/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini4 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-4 ${className}`}
      fill="none"
      height="102"
      viewBox="0 0 144 102"
      width="144"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.2018 38.5542C-0.386806 38.5542 -10.7278 47.5036 -10.7278 47.5036V100.952H154.728V27.1731C154.728 27.1731 149.351 20.8826 140.847 18.8455C125.52 15.1737 123.381 41.9792 109.917 41.9792C100.027 41.9792 99.344 25.23 86.1574 23.7033C72.9708 22.1765 67.0224 60.6396 45.7647 60.206C29.8401 59.8812 20.1573 38.5542 11.2018 38.5542Z"
        fill="url(#paint0_linear_2004_29718)"
      />
      <path
        className="path"
        d="M-10.4523 47.3888C-10.4523 47.3888 -0.387052 38.5208 11.2016 38.5208C20.8464 38.5208 29.8399 59.8123 45.7644 60.1366C67.0221 60.5694 72.9705 22.1704 86.1571 23.6946C99.3438 25.2188 100.027 41.9401 109.917 41.9401C123.381 41.9401 125.52 15.1793 140.847 18.8449C149.35 20.8786 154.728 27.1587 154.728 27.1587"
        stroke="url(#paint1_linear_2004_29718)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M84.1337 11.3312C84.1337 5.23926 89.0721 0.300781 95.164 0.300781C101.256 0.300781 106.194 5.23925 106.194 11.3312V100.953H84.1337V11.3312Z"
        fill="url(#paint2_linear_2004_29718)"
        opacity="0.3"
      />
      <g className="g" filter="url(#filter0_d_2004_29718)">
        <circle className="circle-4" cx="95.1642" cy="27.601" fill="url(#paint3_linear_2004_29718)" r="6.89399" />
        <circle className="circle-4" cx="95.1642" cy="27.601" r="3.89399" stroke="white" strokeWidth="6" />
      </g>
      <path className="path" d="M-10.7278 100.953H154.728" opacity="0.1" stroke="#454459" />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.7871"
          id="filter0_d_2004_29718"
          width="63.788"
          x="63.2703"
          y="0.707031"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29718" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29718"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29718"
          x1="72"
          x2="72"
          y1="18.5"
          y2="100.952"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#8D79F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29718"
          x1="72.1377"
          x2="72.1377"
          y1="18.5"
          y2="60.1402"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29718"
          x1="95.164"
          x2="95.164"
          y1="0.300781"
          y2="100.953"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.15" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29718"
          x1="95.1642"
          x2="95.1642"
          y1="20.707"
          y2="34.495"
        >
          <stop className="stop" stopColor="#FD95D3" />
          <stop className="stop" offset="1" stopColor="#FF5CBE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
