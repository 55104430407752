/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chart4 = ({ className }) => {
  return (
    <svg
      className={`chart-4 ${className}`}
      fill="none"
      height="102"
      viewBox="0 0 180 102"
      width="180"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.6406 38.2534C-1.96598 38.2534 -15 47.2028 -15 47.2028V100.651H193.544V26.8723C193.544 26.8723 186.767 20.5818 176.049 18.5447C156.731 14.873 154.034 41.6784 137.064 41.6784C124.598 41.6784 123.737 24.9292 107.116 23.4025C90.4957 21.8758 82.9982 60.3389 56.2045 59.9053C36.1328 59.5804 23.9283 38.2534 12.6406 38.2534Z"
        fill="url(#paint0_linear_2041_6245)"
      />
      <path
        className="path"
        d="M-14.6533 47.088C-14.6533 47.088 -1.96687 38.22 12.6397 38.22C24.7963 38.22 36.1319 59.5115 56.2036 59.8358C82.9973 60.2687 90.4948 21.8696 107.116 23.3938C123.736 24.918 124.597 41.6393 137.063 41.6393C154.033 41.6393 156.73 14.8785 176.048 18.5441C186.766 20.5778 193.543 26.8579 193.543 26.8579"
        stroke="url(#paint1_linear_2041_6245)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M104.565 13.903C104.565 6.22458 110.79 0 118.468 0C126.147 0 132.371 6.22457 132.371 13.903V100.652H104.565V13.903Z"
        fill="url(#paint2_linear_2041_6245)"
        opacity="0.3"
      />
      <g className="g" filter="url(#filter0_d_2041_6245)">
        <ellipse
          className="ellipse-5"
          cx="118.469"
          cy="27.3002"
          fill="url(#paint3_linear_2041_6245)"
          rx="8.68935"
          ry="6.89399"
        />
        <path
          className="path"
          d="M124.158 27.3002C124.158 28.8406 122.295 31.1942 118.469 31.1942C114.642 31.1942 112.779 28.8406 112.779 27.3002C112.779 25.7599 114.642 23.4062 118.469 23.4062C122.295 23.4062 124.158 25.7599 124.158 27.3002Z"
          stroke="white"
          strokeWidth="6"
        />
      </g>
      <path className="path" d="M-15 100.652H193.544" opacity="0.1" stroke="#454459" />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.7881"
          id="filter0_d_2041_6245"
          width="67.3789"
          x="84.7793"
          y="0.40625"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2041_6245" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2041_6245"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2041_6245"
          x1="89.2722"
          x2="89.2722"
          y1="18.1992"
          y2="100.651"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#8D79F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2041_6245"
          x1="89.4451"
          x2="89.4451"
          y1="18.1992"
          y2="59.8394"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2041_6245"
          x1="118.468"
          x2="118.468"
          y1="0"
          y2="100.652"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.15" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2041_6245"
          x1="118.469"
          x2="118.469"
          y1="20.4062"
          y2="34.1942"
        >
          <stop className="stop" stopColor="#FD95D3" />
          <stop className="stop" offset="1" stopColor="#FF5CBE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
