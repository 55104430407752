/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArcGraph = ({ className }) => {
  return (
    <svg
      className={`arc-graph-11 ${className}`}
      fill="none"
      height="194"
      viewBox="0 0 186 194"
      width="186"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M127.253 159.07C140.825 151.234 151.277 136.025 157.102 121.475C162.928 106.926 163.695 90.8407 159.281 75.8028C154.867 60.7648 145.527 47.6465 132.762 38.554C119.996 29.4615 104.546 24.9225 88.8912 25.6657C73.2364 26.4088 58.2855 32.3911 46.4389 42.652C34.5924 52.9128 26.5376 66.8569 23.5678 82.2454C20.598 97.6339 22.8855 113.574 30.0631 127.506C37.2406 141.439 48.8918 152.555 63.1458 159.07"
        stroke="#00FBCE"
        strokeLinecap="round"
        strokeWidth="12"
      />
      <path
        className="path"
        d="M159.281 75.8028C154.867 60.7648 145.527 47.6465 132.762 38.554C119.996 29.4615 104.546 24.9225 88.8912 25.6657C73.2364 26.4088 58.2855 32.3911 46.4389 42.652C34.5924 52.9128 26.5376 66.8569 23.5678 82.2454C20.598 97.6339 22.8855 113.574 30.0631 127.506C37.2407 141.439 48.8918 152.555 63.1458 159.07"
        stroke="#65C6F7"
        strokeLinecap="round"
        strokeWidth="12"
      />
    </svg>
  );
};
