/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chart3 = ({ className }) => {
  return (
    <svg
      className={`chart-3 ${className}`}
      fill="none"
      height="114"
      viewBox="0 0 180 114"
      width="180"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20.2277 52.3258C2.3634 51.4049 -21.8135 65.838 -21.8135 65.838V113.131H202.219V52.3258C202.219 52.3258 185.003 60.7979 173.258 59.3577C154.509 57.0587 165.112 33.2019 146.912 29.162C133.729 26.2358 125.167 37.9602 112.345 34.2635C99.4422 30.544 103.837 17.8257 91.6043 13.0301C63.9382 2.18415 86.6775 66.2903 55.3555 65.838C39.8882 65.6147 35.6603 53.1213 20.2277 52.3258Z"
        fill="url(#paint0_linear_2041_6226)"
      />
      <path
        className="path"
        d="M-21.8135 65.838C-21.8135 65.838 2.3634 51.4049 20.2277 52.3258C35.6603 53.1213 39.8882 65.6146 55.3555 65.838C86.6775 66.2903 63.9382 2.18415 91.6043 13.0301C103.837 17.8257 99.4422 30.544 112.345 34.2635C125.167 37.9602 133.729 26.2358 146.912 29.162C165.112 33.2019 154.509 57.0587 173.258 59.3576C185.003 60.7979 202.219 52.3258 202.219 52.3258"
        stroke="url(#paint1_linear_2041_6226)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M-22 36.1942C-22 36.1942 -3.33341 46.212 9.39075 45.4321C25.2871 44.4579 26.1498 27.8153 42.0894 27.3699C61.0238 26.8409 60.7658 54.1034 79.0857 50.5337C89.3745 48.5289 89.0921 39.3251 99.2655 37.0215C116.901 33.0283 121.705 56.4193 139.999 54.6701C155.11 53.2251 154.941 36.0827 170.081 34.8154C183.04 33.7308 187.459 40.1927 202.406 43.2261"
        stroke="url(#paint2_linear_2041_6226)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M28.0762 112.304V2"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M58.9062 112.304V2"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M89.7363 113.131V2.82715"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M120.566 113.131V2.82715"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M151.396 113.131V2.82715"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <g className="g" filter="url(#filter0_d_2041_6226)">
        <mask className="mask" fill="white" id="path-9-inside-1_2041_6226">
          <ellipse className="ellipse-5" cx="89.7357" cy="11.9273" rx="7.47399" ry="5.51519" />
        </mask>
        <ellipse
          className="ellipse-5"
          cx="89.7357"
          cy="11.9273"
          fill="url(#paint3_linear_2041_6226)"
          rx="7.47399"
          ry="5.51519"
        />
        <path
          className="path"
          d="M89.2097 11.9273C89.2097 10.3112 90.1147 9.505 90.2705 9.38999C90.429 9.27307 90.2661 9.44249 89.7357 9.44249V25.4425C93.3331 25.4425 96.9072 24.3773 99.7707 22.2643C102.632 20.1531 105.21 16.5893 105.21 11.9273H89.2097ZM89.7357 9.44249C89.2054 9.44249 89.0424 9.27307 89.2009 9.38999C89.3567 9.505 90.2617 10.3112 90.2617 11.9273H74.2617C74.2617 16.5893 76.8398 20.1531 79.7007 22.2643C82.5642 24.3773 86.1383 25.4425 89.7357 25.4425V9.44249ZM90.2617 11.9273C90.2617 13.5433 89.3567 14.3496 89.2009 14.4646C89.0424 14.5815 89.2054 14.4121 89.7357 14.4121V-1.58789C86.1383 -1.58789 82.5642 -0.522686 79.7007 1.59033C76.8398 3.70145 74.2617 7.26529 74.2617 11.9273H90.2617ZM89.7357 14.4121C90.2661 14.4121 90.429 14.5815 90.2705 14.4646C90.1147 14.3496 89.2097 13.5433 89.2097 11.9273H105.21C105.21 7.26529 102.632 3.70145 99.7707 1.59033C96.9072 -0.522686 93.3331 -1.58789 89.7357 -1.58789V14.4121Z"
          fill="white"
          mask="url(#path-9-inside-1_2041_6226)"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0303"
          id="filter0_d_2041_6226"
          width="64.9482"
          x="57.2617"
          y="-13.5879"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2041_6226" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2041_6226"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2041_6226"
          x1="90.2029"
          x2="90.2029"
          y1="11.7939"
          y2="113.131"
        >
          <stop className="stop" stopColor="#FF9364" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#F25F33" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2041_6226"
          x1="90.2029"
          x2="90.2029"
          y1="11.7939"
          y2="65.8404"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2041_6226"
          x1="90.2032"
          x2="90.2032"
          y1="27.3623"
          y2="54.7637"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2041_6226"
          x1="89.7357"
          x2="89.7357"
          y1="6.41211"
          y2="17.4425"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
      </defs>
    </svg>
  );
};
