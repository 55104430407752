/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoX = ({ className }) => {
  return (
    <svg
      className={`logo-x ${className}`}
      fill="none"
      height="75"
      viewBox="0 0 76 75"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M57.2239 7.03125H67.5614L44.977 32.8438L71.5457 67.9688H50.7395L34.4458 46.6656L15.802 67.9688H5.45825L29.6145 40.3594L4.13013 7.03125H25.4582L40.1864 26.5031L57.2176 7.03125H57.2239ZM53.5957 61.7813H59.3239L22.3489 12.8937H16.202L53.5957 61.7813Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};
