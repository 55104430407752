/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { SettingsInput } from "../SettingsInput";
import "./style.css";

export const FormFieldCompact = ({ className, text = "Label" }) => {
  return (
    <div className={`form-field-compact ${className}`}>
      <div className="label-2">{text}</div>
      <SettingsInput className="design-component-instance-node" />
    </div>
  );
};

FormFieldCompact.propTypes = {
  text: PropTypes.string,
};
