/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ConfigurationWrapper = ({
  className,
  delimiter = "/img/delimiter-3.svg",
  img = "/img/delimiter-3.svg",
}) => {
  return (
    <div className={`configuration-wrapper ${className}`}>
      <div className="div-wrapper-2">
        <div className="amount-value">
          <div className="buy">Buy</div>
          <div className="text-wrapper-13">14.9</div>
        </div>
      </div>
      <img className="delimiter" alt="Delimiter" src={delimiter} />
      <div className="div-wrapper-2">
        <div className="text-wrapper-14">Amount</div>
      </div>
      <img className="delimiter" alt="Delimiter" src={img} />
      <div className="div-wrapper-2">
        <div className="text-wrapper-14">Sell</div>
      </div>
    </div>
  );
};

ConfigurationWrapper.propTypes = {
  delimiter: PropTypes.string,
  img: PropTypes.string,
};
