/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini3 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-3 ${className}`}
      fill="none"
      height="112"
      viewBox="0 0 136 112"
      width="136"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.3638 50.6266C3.18137 49.7057 -14.6592 64.1388 -14.6592 64.1388V111.432H150.659V50.6266C150.659 50.6266 137.955 59.0987 129.287 57.6584C115.452 55.3595 123.277 31.5027 109.846 27.4628C100.118 24.5366 93.8007 36.261 84.3385 32.5643C74.8176 28.8447 78.0607 16.1265 69.0338 11.3309C48.6185 0.484931 65.3982 64.5911 42.2851 64.1388C30.8716 63.9154 27.7517 51.4221 16.3638 50.6266Z"
        fill="url(#paint0_linear_2041_5567)"
      />
      <path
        className="path"
        d="M-14.6592 64.1388C-14.6592 64.1388 3.18137 49.7056 16.3638 50.6266C27.7517 51.4221 30.8716 63.9154 42.2851 64.1388C65.3982 64.5911 48.6185 0.484932 69.0338 11.3309C78.0607 16.1265 74.8175 28.8447 84.3385 32.5643C93.8007 36.261 100.118 24.5366 109.846 27.4628C123.277 31.5027 115.452 55.3595 129.287 57.6584C137.955 59.0987 150.659 50.6266 150.659 50.6266"
        stroke="url(#paint1_linear_2041_5567)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M-14.7969 34.495C-14.7969 34.495 -1.02247 44.5128 8.36691 43.7329C20.0971 42.7587 20.7337 26.116 32.4959 25.6707C46.4678 25.1417 46.2774 52.4042 59.796 48.8345C67.3884 46.8296 67.18 37.6259 74.687 35.3223C87.7003 31.3291 91.2456 54.7201 104.745 52.9709C115.896 51.5259 115.771 34.3834 126.943 33.1162C136.506 32.0316 139.766 38.4935 150.797 41.5269"
        stroke="url(#paint2_linear_2041_5567)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M22.1553 110.605V0.300781"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M44.9053 110.605V0.300781"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M67.6553 111.432V1.12793"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M90.4053 111.432V1.12793"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M113.155 111.432V1.12793"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M135.906 111.432V1.12793"
        opacity="0.3"
        stroke="#171724"
        strokeDasharray="0 1 14 0 1 14"
        strokeLinecap="round"
      />
      <g className="g" filter="url(#filter0_d_2041_5567)">
        <circle className="circle" cx="67.6548" cy="10.2281" fill="url(#paint3_linear_2041_5567)" r="5.51519" />
        <circle className="circle" cx="67.6548" cy="10.2281" r="4" stroke="white" strokeWidth="3.03038" />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="61.0303"
          id="filter0_d_2041_5567"
          width="61.0303"
          x="37.1396"
          y="-15.2871"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2041_5567" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2041_5567"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2041_5567"
          x1="67.9997"
          x2="67.9997"
          y1="10.0947"
          y2="111.432"
        >
          <stop className="stop" stopColor="#FF9364" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#F25F33" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2041_5567"
          x1="67.9997"
          x2="67.9997"
          y1="10.0947"
          y2="64.1412"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2041_5567"
          x1="67.9999"
          x2="67.9999"
          y1="25.6631"
          y2="53.0645"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2041_5567"
          x1="67.6548"
          x2="67.6548"
          y1="4.71289"
          y2="15.7433"
        >
          <stop className="stop" stopColor="#FF9364" />
          <stop className="stop" offset="1" stopColor="#F25F33" />
        </linearGradient>
      </defs>
    </svg>
  );
};
