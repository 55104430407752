/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArcGraphSimple = ({ className }) => {
  return (
    <svg
      className={`arc-graph-simple-16 ${className}`}
      fill="none"
      height="193"
      viewBox="0 0 194 193"
      width="194"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M131.123 158.82C144.743 150.996 155.232 135.811 161.078 121.285C166.924 106.758 167.694 90.6982 163.264 75.6838C158.834 60.6695 149.462 47.5717 136.651 38.4935C123.841 29.4153 108.336 24.8834 92.6264 25.6254C76.9165 26.3674 61.9129 32.3403 50.0246 42.585C38.1364 52.8298 30.0532 66.752 27.0729 82.1164C24.0927 97.4807 26.3883 113.396 33.5911 127.306C40.7939 141.217 52.4861 152.315 66.7903 158.82"
        stroke="#00FBCE"
        strokeLinecap="round"
        strokeWidth="12"
      />
    </svg>
  );
};
