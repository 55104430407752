/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import {ProductsMenu} from "../ProductsMenu";


export const DropdownMenu = ({currentProject = 'none', onMenuItemClick = () => {}}) => {
  return (
    <div className="dropdown-menu" id="header-dropdown-menu">
      <ProductsMenu onProductClick={onMenuItemClick} currentProject={currentProject}/>
    </div>
  );
};
