export const SCREEN_MOBILE = "mobile";
export const SCREEN_DESKTOP = "desktop";

export const PROJECT_MEV = 'mev-ception';
export const PROJECT_TRADE = 'trade-ception';
export const PROJECT_MARKET = 'market-ception';
export const PROJECT_GAME = 'game-ception';
export const PROJECT_COPY = 'copy-ception';
export const PROJECT_NONE = 'none';

export const PAGE_HOME = 'home';
export const PAGE_CONFIGURATION = 'advanced-sniper';
export const PAGE_WALLET_MANAGEMENT = 'wallet-management';

export const PAGE_MEV_OVERVIEW = 'mev-ception-overview';
export const PAGE_MEV_MISSIONS = 'mev-ception-missions';
export const PAGE_MEV_SETTINGS = 'mev-ception-settings';
export const PAGE_MEV_WALLET_MANAGEMENT = 'mev-ception-wallet-management';
export const PAGE_MEV_COPY_WALLET = 'mev-ception-copy-wallet';
export const PAGE_MEV_VIEW_COPIED_WALLET = 'mev-ception-view-copied-wallet';

export const PAGE_GAME_OVERVIEW = 'game-ception-overview';
export const PAGE_GAME_LEADERBOARD = 'game-ception-leaderboard';
export const PAGE_GAME_MISSIONS = 'game-ception-missions';
export const PAGE_GAME_SOCIAL_MISSIONS = 'game-ception-social-missions';
export const PAGE_GAME_REFERRALS = 'game-ception-referrals';
export const PAGE_GAME_TRADER_STATISTICS = 'game-ception-trader-statistics';

export const PAGE_COPY_OVERVIEW = 'copy-ception-overview';
export const PAGE_COPY_MISSIONS = 'copy-ception-missions';
export const PAGE_COPY_COPY_WALLET = 'copy-ception-copy-wallet';
export const PAGE_COPY_VIEW_COPIED_WALLET = 'copy-ception-view-copied-wallet';
export const PAGE_COPY_WALLET_MANAGEMENT = 'copy-ception-wallet-management';

export const PAGE_MARKET_OVERVIEW = 'market-ception-overview';
export const PAGE_MARKET_WALLET_MANAGEMENT = 'market-ception-wallet-management';
export const PAGE_MARKET_SETTINGS = 'market-ception-settings';
export const PAGE_MARKET_POINTS = 'market-ception-points';


export const PAGE_TELEGRAM = 'telegram';

export const ADVANCED_SNIPER_TAB_AUTO_SNIPES = 'auto-snipes';
export const ADVANCED_SNIPER_TAB_FAST_CONFIG = 'fast-config-sniper';
export const ADVANCED_SNIPER_TAB_ADVANCED_SNIPER = 'advanced-sniper';
export const ADVANCED_SNIPER_TAB_TRADE_MONITOR = 'trade-monitor';

export const MOBILE_HEADER_DROPDOWN_MENU = 'mobile-header-dropdown-menu';
export const MOBILE_HEADER_SETTINGS = 'mobile-header-settings-state';
export const MOBILE_HEADER_STATISTICS = 'statistics';
export const MOBILE_HEADER_NONE = 'none';


export const getPageCaption = pageName => ({
  [PAGE_HOME]: 'Dashboard',
  [PAGE_CONFIGURATION]: 'Configuration',
  [PAGE_WALLET_MANAGEMENT]: 'Wallet Management',

  [PAGE_MEV_OVERVIEW]: 'Overview',
  [PAGE_MEV_MISSIONS]: 'MevCeption missions',
  [PAGE_MEV_SETTINGS]: 'Settings',
  [PAGE_MEV_WALLET_MANAGEMENT]: 'Wallet Management',
  [PAGE_MEV_COPY_WALLET]: 'Copy wallet',
  [PAGE_MEV_VIEW_COPIED_WALLET]: 'View copied wallet',

  [PAGE_GAME_OVERVIEW]: 'Overview',
  [PAGE_GAME_LEADERBOARD]: "Leaderboard",
  [PAGE_GAME_MISSIONS]: 'GameCeption missions',
  [PAGE_GAME_SOCIAL_MISSIONS]: 'Social missions',
  [PAGE_GAME_REFERRALS]: 'Referrals',
  [PAGE_GAME_TRADER_STATISTICS]: 'Trader Statistics',

  [PAGE_COPY_OVERVIEW]: 'Overview',
  [PAGE_COPY_MISSIONS]: 'CopyCeption Missions',
  [PAGE_COPY_COPY_WALLET]: 'Copy wallet',
  [PAGE_COPY_VIEW_COPIED_WALLET]: 'View copied wallet',
  [PAGE_COPY_WALLET_MANAGEMENT]: 'Wallet Management',

  [PAGE_MARKET_OVERVIEW]: 'Overview',
  [PAGE_MARKET_WALLET_MANAGEMENT]: 'Wallet Management',
  [PAGE_MARKET_POINTS]: 'MarketCeption Missions',
  [PAGE_MARKET_SETTINGS]: 'Settings'
}[pageName]);

export const getProjectCaption = projectName => ({
  [PROJECT_MEV]: 'MevCeption',
  [PROJECT_COPY]: 'CopyCeption',
  [PROJECT_GAME]: 'GameCeption',
  [PROJECT_TRADE]: 'TradeCeption',
  [PROJECT_MARKET]: 'MarketCeption'
}[projectName])
