import React from "react";
import "./style.css";

export const SwapIcon = ({className = '', onClick = () => {}}) => {
  return (
    <svg className={`swap-icon ${className}`} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="black"/>
      <path d="M3.67906 13L3.15206 13.532C3.29252 13.6712 3.48229 13.7494 3.68006 13.7494C3.87783 13.7494 4.0676 13.6712 4.20806 13.532L3.67906 13ZM5.88806 11.866C6.02942 11.7257 6.10926 11.535 6.11001 11.3358C6.11076 11.1367 6.03236 10.9454 5.89206 10.804C5.82259 10.734 5.74001 10.6784 5.64905 10.6403C5.55808 10.6022 5.4605 10.5824 5.36189 10.5821C5.16272 10.5813 4.97142 10.6597 4.83006 10.8L5.88806 11.866ZM2.52806 10.8C2.38605 10.6639 2.19624 10.589 1.99953 10.5915C1.80282 10.594 1.61497 10.6736 1.47646 10.8133C1.33794 10.953 1.25986 11.1416 1.25904 11.3383C1.25822 11.535 1.33472 11.7242 1.47206 11.865L2.52806 10.8ZM18.6161 7.39201C18.6663 7.47858 18.7334 7.55421 18.8134 7.61444C18.8934 7.67466 18.9846 7.71828 19.0816 7.7427C19.1787 7.76712 19.2797 7.77186 19.3787 7.75664C19.4776 7.74142 19.5725 7.70655 19.6578 7.65407C19.743 7.6016 19.8169 7.53259 19.8751 7.45111C19.9332 7.36963 19.9745 7.27734 19.9964 7.17966C20.0183 7.08198 20.0205 6.98091 20.0027 6.88238C19.985 6.78386 19.9477 6.68989 19.8931 6.60601L18.6161 7.39201ZM12.0791 2.25001C7.03206 2.25001 2.92906 6.31101 2.92906 11.333H4.42906C4.42906 7.15101 7.84906 3.75001 12.0791 3.75001V2.25001ZM2.92906 11.333V13H4.42906V11.333H2.92906ZM4.20906 13.533L5.88806 11.866L4.83006 10.8L3.15006 12.467L4.20906 13.533ZM4.20906 12.468L2.52806 10.8L1.47106 11.865L3.15106 13.531L4.20906 12.468ZM19.8931 6.60801C19.0721 5.27411 17.9228 4.17305 16.5549 3.41006C15.187 2.64707 13.6453 2.24767 12.0791 2.25001V3.75001C13.3893 3.74729 14.6793 4.0808 15.8239 4.71863C16.9684 5.35646 17.9301 6.27728 18.6171 7.39301L19.8931 6.60801Z" fill="#A8B0C1"/>
      <path opacity="0.5" d="M20.313 11.0001L20.841 10.4671C20.7006 10.3282 20.511 10.2502 20.3135 10.2502C20.116 10.2502 19.9264 10.3282 19.786 10.4671L20.313 11.0001ZM18.1 12.1331C18.0299 12.2024 17.9742 12.2848 17.936 12.3756C17.8978 12.4664 17.8778 12.5638 17.8772 12.6624C17.8761 12.8613 17.9541 13.0526 18.094 13.1941C18.2339 13.3356 18.4243 13.4157 18.6232 13.4168C18.8222 13.418 19.0135 13.34 19.155 13.2001L18.1 12.1331ZM21.473 13.2001C21.5426 13.2712 21.6257 13.3277 21.7174 13.3663C21.8092 13.405 21.9076 13.425 22.0072 13.4252C22.1067 13.4254 22.2052 13.4058 22.2971 13.3675C22.389 13.3292 22.4723 13.273 22.5422 13.2022C22.6122 13.1314 22.6673 13.0474 22.7045 12.9551C22.7416 12.8628 22.76 12.764 22.7586 12.6645C22.7572 12.565 22.736 12.4667 22.6962 12.3755C22.6564 12.2843 22.5989 12.2019 22.527 12.1331L21.473 13.2001ZM5.31798 16.6061C5.21348 16.4368 5.046 16.3159 4.85237 16.27C4.65874 16.2242 4.45482 16.2571 4.28548 16.3616C4.11614 16.4661 3.99525 16.6336 3.94939 16.8272C3.90354 17.0208 3.93649 17.2248 4.04098 17.3941L5.31798 16.6061ZM11.883 21.7501C16.945 21.7501 21.063 17.6921 21.063 12.6671H19.563C19.563 16.8471 16.133 20.2501 11.883 20.2501V21.7501ZM21.063 12.6671V11.0001H19.563V12.6671H21.063ZM19.786 10.4671L18.1 12.1331L19.155 13.2001L20.841 11.5331L19.786 10.4671ZM19.786 11.5331L21.473 13.2001L22.527 12.1331L20.841 10.4671L19.786 11.5331ZM4.04098 17.3941C4.8674 18.7294 6.02198 19.8299 7.39471 20.5926C8.76745 21.3553 10.3126 21.7538 11.883 21.7501V20.2501C10.5686 20.2538 9.27521 19.9208 8.126 19.2829C6.97679 18.645 6.01008 17.7235 5.31798 16.6061L4.04098 17.3941Z" fill="#A8B0C1"/>
    </svg>
  )
}
