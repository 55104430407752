/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { CircleGraph } from "../../icons/CircleGraph";
import { CircleGraph } from "../../icons/CircleGraph";
import { Row } from "../Row";
import { Type } from "../Type";
import { TypeDefault } from "../TypeDefault";
import "./style.css";
import {Checkbox} from "../Checkbox/Checkbox";

export const PageContentMevCeptionTotals = ({
  screen,
  row = <Type className="type-type" contentClassName="type-type4" text="Time" textClassName="cell-3" />,
  override = <TypeDefault className="design-component-instance-node" text="Revenue" textClassName="cell-3" />,
  row1 = <TypeDefault className="design-component-instance-node" text="Token" textClassName="type-default-instance" />,
  row2 = <TypeDefault className="design-component-instance-node" text="TX" textClassName="type-default-instance" />,
  row3 = <TypeDefault className="design-component-instance-node" text="Profit" textClassName="cell-3" />,
  row4 = <TypeDefault className="design-component-instance-node" text="Cost" textClassName="cell-3" />,
  row5 = <Type className="type-type" contentClassName="type-type4" text="2023-12-03 14:19:35" textClassName="cell-3" />,
  row6 = <TypeDefault className="design-component-instance-node" text="$179.14" textClassName="cell-3" />,
  row7 = <TypeDefault className="design-component-instance-node" text="Pepe" textClassName="type-default-instance" />,
  row8 = (
    <TypeDefault className="design-component-instance-node" text="0x76f…96696" textClassName="type-default-instance" />
  ),
  row9 = <TypeDefault className="design-component-instance-node" text="$44.92" textClassName="cell-3" />,
  row10 = <TypeDefault className="design-component-instance-node" text="$134.22" textClassName="cell-3" />,
  row11 = (
    <Type className="type-type" contentClassName="type-type4" text="2023-12-03 14:19:35" textClassName="cell-3" />
  ),
  row12 = <TypeDefault className="design-component-instance-node" text="$179.14" textClassName="cell-3" />,
  row13 = <TypeDefault className="design-component-instance-node" text="Pepe" textClassName="type-default-instance" />,
  row14 = (
    <TypeDefault className="design-component-instance-node" text="0x76f…96696" textClassName="type-default-instance" />
  ),
  row15 = <TypeDefault className="design-component-instance-node" text="$44.92" textClassName="cell-3" />,
  row16 = <TypeDefault className="design-component-instance-node" text="$134.22" textClassName="cell-3" />,
  row17 = (
    <Type className="type-type" contentClassName="type-type4" text="2023-12-03 14:19:35" textClassName="cell-3" />
  ),
  row18 = <TypeDefault className="design-component-instance-node" text="$179.14" textClassName="cell-3" />,
  row19 = <TypeDefault className="design-component-instance-node" text="Pepe" textClassName="type-default-instance" />,
  row20 = (
    <TypeDefault className="design-component-instance-node" text="0x76f…96696" textClassName="type-default-instance" />
  ),
  row21 = <TypeDefault className="design-component-instance-node" text="$44.92" textClassName="cell-3" />,
  row22 = <TypeDefault className="design-component-instance-node" text="$134.22" textClassName="cell-3" />,
  row23 = (
    <Type className="type-type" contentClassName="type-type4" text="2023-12-03 14:19:35" textClassName="cell-3" />
  ),
  row24 = <TypeDefault className="design-component-instance-node" text="$179.14" textClassName="cell-3" />,
  row25 = <TypeDefault className="design-component-instance-node" text="Pepe" textClassName="type-default-instance" />,
  row26 = (
    <TypeDefault className="design-component-instance-node" text="0x76f…96696" textClassName="type-default-instance" />
  ),
  row27 = <TypeDefault className="design-component-instance-node" text="$44.92" textClassName="cell-3" />,
  row28 = <TypeDefault className="design-component-instance-node" text="$134.22" textClassName="cell-3" />,
}) => {
  return (
    <div className={`page-content-mev ${screen}`}>
      <div className="frame">
        {screen === "mobile" && (
          <>
            <div className="text-wrapper">Overview</div>
            <div className="element-columns">
              <div className="div">
                <div className="text-wrapper-2">Profit</div>
                <img className="circle-graph" alt="Circle graph" src="/img/circle-graph.svg" />
                <div className="text-wrapper-3">$50,478</div>
              </div>
              <div className="div">
                <div className="text-wrapper-2">Cost</div>
                <div className="group">
                  <img className="ellipse" alt="Ellipse" src="/img/circle-graph-part.svg" />
                </div>
                <div className="text-wrapper-3">$256,085</div>
              </div>
            </div>
            <div className="element-columns">
              <div className="div">
                <div className="text-wrapper-2">Total Participated MEVS</div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="text-wrapper-4">3705</div>
                    <img className="arc-graph" alt="Arc graph" src="/img/arc-graph.svg" />
                  </div>
                </div>
              </div>
              <div className="div">
                <div className="text-wrapper-2">Total Conis Activated</div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="text-wrapper-5">6</div>
                    <img className="arc-graph" alt="Arc graph" src="/img/arc-graph.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-6">Activate Tokens</div>
            <div className="frame-2">
              <div className="column-2">
                <div className="element-columns-wrapper">
                  <div className="element-columns-2">
                    <div className="frame-3">
                      <div className="frame-wrapper">
                        <div className="div-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="div-wrapper">
                          <div className="text-wrapper-7">Token Name</div>
                        </div>
                      </div>
                    </div>
                    <div className="column-4">
                      <div className="frame-4">
                        <div className="text-wrapper-8">Balance</div>
                      </div>
                    </div>
                    <div className="column-5">
                      <div className="frame-5">
                        <div className="text-wrapper-7">Authorized</div>
                      </div>
                      <div className="frame-6" />
                    </div>
                  </div>
                </div>
                <div className="element-columns-3">
                  <div className="element-columns-4">
                    <div className="frame-7">
                      <div className="frame-wrapper">
                        <div className="div-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="div-wrapper">
                          <div className="text-wrapper-9">Pepe</div>
                        </div>
                      </div>
                    </div>
                    <div className="column-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">300,000,000,000</div>
                      </div>
                    </div>
                    <div className="column-7">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">300,000,000,000</div>
                      </div>
                      <div className="frame-6" />
                    </div>
                  </div>
                </div>
                <div className="element-columns-3">
                  <div className="element-columns-4">
                    <div className="frame-7">
                      <div className="frame-wrapper">
                        <div className="div-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="div-wrapper">
                          <div className="text-wrapper-9">SHIB</div>
                        </div>
                      </div>
                    </div>
                    <div className="column-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">100,000,000</div>
                      </div>
                    </div>
                    <div className="column-7">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">75,000,000</div>
                      </div>
                      <div className="frame-6" />
                    </div>
                  </div>
                </div>
                <div className="element-columns-3">
                  <div className="element-columns-4">
                    <div className="frame-7">
                      <div className="frame-wrapper">
                        <div className="div-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="div-wrapper">
                          <div className="text-wrapper-9">GROK</div>
                        </div>
                      </div>
                    </div>
                    <div className="column-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">1,000,000</div>
                      </div>
                    </div>
                    <div className="column-7">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">1,000,000</div>
                      </div>
                      <div className="frame-6" />
                    </div>
                  </div>
                </div>
                <div className="element-columns-3">
                  <div className="element-columns-4">
                    <div className="frame-7">
                      <div className="frame-wrapper">
                        <div className="div-wrapper">
                          <Checkbox/>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="div-wrapper">
                          <div className="text-wrapper-10">Froge</div>
                        </div>
                      </div>
                    </div>
                    <div className="column-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">376,366,023,862</div>
                      </div>
                    </div>
                    <div className="column-7">
                      <div className="div-wrapper">
                        <div className="text-wrapper-9">300,000,000,000</div>
                      </div>
                      <div className="frame-6" />
                    </div>
                  </div>
                </div>
                <div className="frame-8">
                  <div className="deactivate-wrapper">
                    <div className="deactivate">DEACTIVATE</div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-6">Past Participated MEVS</div>
              <div className="column-8">
                <div className="row-wrapper">
                  <Row
                    className="row-instance"
                    override={row}
                    override1={row2}
                    override2={row1}
                    override3={row3}
                    override4={row4}
                    override5={override}
                    visible={false}
                    visible1={false}
                  />
                </div>
                <div className="frame-9">
                  <Row
                    className="row-instance"
                    override={row5}
                    override1={row8}
                    override2={row7}
                    override3={row9}
                    override4={row10}
                    override5={row6}
                    visible={false}
                    visible1={false}
                  />
                </div>
                <div className="frame-9">
                  <Row
                    className="row-instance"
                    override={row11}
                    override1={row14}
                    override2={row13}
                    override3={row15}
                    override4={row16}
                    override5={row12}
                    visible={false}
                    visible1={false}
                  />
                </div>
                <div className="frame-9">
                  <Row
                    className="row-instance"
                    override={row17}
                    override1={row20}
                    override2={row19}
                    override3={row21}
                    override4={row22}
                    override5={row18}
                    visible={false}
                    visible1={false}
                  />
                </div>
                <div className="frame-9">
                  <Row
                    className="row-instance"
                    override={row23}
                    override1={row26}
                    override2={row25}
                    override3={row27}
                    override4={row28}
                    override5={row24}
                    visible={false}
                    visible1={false}
                  />
                </div>
                <div className="rectangle-2" />
              </div>
            </div>
          </>
        )}

        {screen === "desktop" && (
          <>
            <div className="text-wrapper-11">Overview</div>
            <div className="frame-10">
              <div className="frame-11">
                <div className="column-9">
                  <div className="frame-2">
                    <div className="text-wrapper-12">Profit</div>
                  </div>
                  <div className="circle-graph-wrapper">
                    <CircleGraph className="instance-node" />
                  </div>
                  <div className="frame-12">
                    <div className="text-wrapper-13">$5.984</div>
                  </div>
                </div>
                <div className="column-9">
                  <div className="frame-2">
                    <div className="text-wrapper-12">Cost</div>
                  </div>
                  <div className="circle-graph-wrapper">
                    <CircleGraph className="instance-node" />
                  </div>
                  <div className="frame-12">
                    <div className="text-wrapper-13">$5.984</div>
                  </div>
                </div>
                <div className="column-9">
                  <div className="frame-2">
                    <div className="text-wrapper-12">Total Participated MEVS</div>
                  </div>
                  <div className="arc-graph-wrapper">
                    <img className="img" alt="Arc graph" src="/img/arc-graph-with-number-3705.svg" />
                  </div>
                </div>
                <div className="column-9">
                  <div className="frame-2">
                    <div className="text-wrapper-12">Total Tokens Activated</div>
                  </div>
                  <div className="arc-graph-wrapper">
                    <img className="img" alt="Arc graph" src="/img/arc-graph-with-number-99.svg" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {screen === "desktop" && (
        <div className="frame-13">
          <div className="text-wrapper-11">Activate Tokens</div>
          <div className="column-10">
            <div className="element-columns-wrapper">
              <div className="element-columns-5">
                <div className="frame-14">
                  <div className="column-11">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <div className="column-12">
                    <div className="div-wrapper">
                      <div className="text-wrapper-14">Token Name</div>
                    </div>
                  </div>
                </div>
                <div className="column-12">
                  <div className="div-wrapper">
                    <div className="text-wrapper-14">Balance</div>
                  </div>
                </div>
                <div className="column-13">
                  <div className="div-wrapper">
                    <div className="text-wrapper-14">Authorized</div>
                  </div>
                  <div className="frame-15" />
                </div>
              </div>
            </div>
            <div className="element-columns-3">
              <div className="element-columns-5">
                <div className="frame-14">
                  <div className="column-11">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <div className="column-12">
                    <div className="div-wrapper">
                      <div className="text-wrapper-9">Pepe</div>
                    </div>
                  </div>
                </div>
                <div className="column-12">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">300,000,000,000</div>
                  </div>
                </div>
                <div className="column-13">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">300,000,000,000</div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            <div className="element-columns-3">
              <div className="element-columns-5">
                <div className="frame-14">
                  <div className="column-11">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <div className="column-12">
                    <div className="div-wrapper">
                      <div className="text-wrapper-9">SHIB</div>
                    </div>
                  </div>
                </div>
                <div className="column-12">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">100,000,000</div>
                  </div>
                </div>
                <div className="column-13">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">300,000,000,000</div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            <div className="element-columns-3">
              <div className="element-columns-5">
                <div className="frame-14">
                  <div className="column-11">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <div className="column-12">
                    <div className="div-wrapper">
                      <div className="text-wrapper-9">GROK</div>
                    </div>
                  </div>
                </div>
                <div className="column-12">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">1,000,000</div>
                  </div>
                </div>
                <div className="column-13">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">1,000,000</div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            <div className="element-columns-3">
              <div className="element-columns-5">
                <div className="frame-14">
                  <div className="column-11">
                    <div className="div-wrapper">
                      <Checkbox/>
                    </div>
                  </div>
                  <div className="column-12">
                    <div className="div-wrapper">
                      <div className="text-wrapper-9">Froge</div>
                    </div>
                  </div>
                </div>
                <div className="column-12">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">376,366,023,862</div>
                  </div>
                </div>
                <div className="column-13">
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">300,000,000,000</div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            <div className="frame-17">
              <div className="frame-18">
                <div className="deactivate">DEACTIVATE</div>
              </div>
            </div>
          </div>
          <div className="column-14">
            <div className="text-wrapper-15">Past Participated MEVS</div>
          </div>
          <div className="column-15">
            <div className="row-wrapper">
              <div className="row-2">
                <TypeDefault className="cell" text="Time" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="TX" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="Token" textClassName="cell-3" />
                <TypeDefault className="cell-4" text="Profit" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="Cost" textClassName="cell-3" />
                <div className="content-2">
                  <div className="text-wrapper-16">Revenue</div>
                </div>
              </div>
            </div>
            <div className="frame-9">
              <div className="row-2">
                <TypeDefault className="cell" text="2023-12-03 14:19:35" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="0x76f…96696" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="Pepe" textClassName="cell-3" />
                <TypeDefault className="cell-4" text="$10" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$30" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$40" textClassName="cell-3" />
              </div>
            </div>
            <div className="frame-9">
              <div className="row-2">
                <TypeDefault className="cell" text="2023-12-03 14:19:35" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="0x76f…96696" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="Pepe" textClassName="cell-3" />
                <TypeDefault className="cell-4" text="$8" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$35" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$43" textClassName="cell-3" />
              </div>
            </div>
            <div className="frame-9">
              <div className="row-2">
                <TypeDefault className="cell" text="2023-12-03 14:19:35" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="0x76f…96696" textClassName="type-default-instance" />
                <TypeDefault className="cell-2" text="Pepe" textClassName="cell-3" />
                <TypeDefault className="cell-4" text="$207" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$100" textClassName="cell-3" />
                <TypeDefault className="cell-2" text="$307" textClassName="cell-3" />
              </div>
            </div>
            <div className="rectangle-2" />
          </div>
        </div>
      )}
    </div>
  );
};

PageContentMevCeptionTotals.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
