/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { TypeHeader } from "../TypeHeader";
import "./style.css";

export const Row = ({
  className,
  override = <TypeHeader className="type-header-instance" />,
  override1 = <TypeHeader className="type-header-instance" />,
  override2 = <TypeHeader className="type-header-instance" />,
  override3 = <TypeHeader className="type-header-instance" />,
  override4 = <TypeHeader className="type-header-instance" />,
  override5 = <TypeHeader className="type-header-instance" />,
  override6 = <TypeHeader className="type-header-instance" />,
  override7 = <TypeHeader className="type-header-instance" />,
  visible = true,
  visible1 = true,
}) => {
  return (
    <div className={`row ${className}`}>
      {visible && <TypeHeader className="type-header-instance" />}

      {override}
      {override1}
      {override2}
      {override3}
      {override4}
      {override5}
      {override6}
      {override7}

      {visible1 && <TypeHeader className="type-header-instance" />}
    </div>
  );
};
