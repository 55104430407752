/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormField } from "../FormField";
import { FormFieldCompact } from "../FormFieldCompact";
import "./style.css";

export const PageContentMarketCeptionSettings = ({ screen }) => {
  return (
    <div className={`page-content-market-ception-settings ${screen}`}>
      {screen === "desktop" && (
        <div className="market-ception">
          <div className="div">
            <div className="text-wrapper">Settings</div>
            <div className="frame">
              <div className="text-wrapper-2">USE DEFAULT SETTINGS</div>
            </div>
          </div>
          <div className="market-ception-2">
            <FormField className="instance-node" label="Contract To Active" />
            <FormField className="instance-node" label="Amount of balance WETH to allocate" />
            <FormField className="instance-node" label="Amount to trade with each time" />
            <FormField className="instance-node" label="(DCA in buy and sell Zones)" />
            <FormField className="instance-node" label="Gas Input" />
            <FormField className="instance-node" label="Buy Zone" />
            <FormField className="instance-node" label="Price" />
            <FormField className="instance-node" label="Sell Zone" />
            <FormField className="instance-node" label="Market Cap" />
            <FormField className="instance-node" label="Price Change" />
            <button className="form-button">
              <div className="text-wrapper-2">SAVE SETTINGS</div>
            </button>
          </div>
        </div>
      )}

      {screen === "mobile" && (
        <>
          <div className="market-ception-3">
            <div className="text-wrapper-3">Settings</div>
            <div className="use-default-settings-wrapper">
              <div className="text-wrapper-2">USE DEFAULT SETTINGS</div>
            </div>
          </div>
          <div className="market-ception-4">
            <FormFieldCompact className="instance-node" text="Contract To Active" />
            <FormFieldCompact className="instance-node" text="Amount of balance WETH to allocate" />
            <FormFieldCompact className="instance-node" text="Amount to trade with each time" />
            <FormFieldCompact className="instance-node" text="(DCA in buy and sell Zones)" />
            <FormFieldCompact className="instance-node" text="Gas Input" />
            <FormFieldCompact className="instance-node" text="Buy Zone" />
            <FormFieldCompact className="instance-node" text="Price" />
            <FormFieldCompact className="instance-node" text="Sell Zone" />
            <FormFieldCompact className="instance-node" text="Market Cap" />
            <FormFieldCompact className="instance-node" text="Price Change" />
          </div>
          <div className="save-settings-wrapper">
            <div className="text-wrapper-2">SAVE SETTINGS</div>
          </div>
        </>
      )}
    </div>
  );
};

PageContentMarketCeptionSettings.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
