/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useState} from "react";
import "./style.css";

export const Toggle = ({ state }) => {
  const [enabled, setEnabled] = useState(state === 'enabled')
  const handleClick = () => setEnabled(!enabled);
  return (
    <div onClick={handleClick} className={`toggle ${enabled ? 'enabled' : 'disabled'}`} >
      <div className="rectangle" />
    </div>
  );
};

Toggle.propTypes = {
  state: PropTypes.oneOf(["disabled", "enabled"]),
};
