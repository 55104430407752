import React from "react";
import { Vector } from "../../icons/Vector";
import "./style.css";

export const PageContentCryptoMarket = ({ className }) => {
  return (
    <div className={`page-content-crypto-market ${className}`}>
      <div className="div">
        <div className="crypto-market-name-wrapper">
          <div className="crypto-market-name">
            <div className="text-wrapper">#</div>
            <div className="name-and-price">
              <div className="text-wrapper-2">Name</div>
              {/*<div className="text-wrapper-3">Price</div>*/}
            </div>
          </div>
        </div>
        <div className="div-2">
          <div className="frame">
            <div className="text-wrapper-2">Price</div>
          </div>
          <div className="frame">
            <div className="text-wrapper-2">1h%</div>
          </div>
          <div className="frame">
            <div className="text-wrapper-2">24h%</div>
          </div>
          <div className="frame">
            <div className="text-wrapper-2">6d%</div>
          </div>
          <div className="frame">
            <div className="text-wrapper-2">Market Cap</div>
          </div>
          <div className="frame">
            <div className="text-wrapper-2">Volume (24h)</div>
          </div>
        </div>
        <div className="div-3">
          <div className="frame-2">
            <div className="text-wrapper-2">Circulating Supply</div>
            <Vector className="info" color="#A8B0C1" />
          </div>
        </div>
      </div>
      <div className="page-content-crypto-market-wrapper">
        <div className="div-4">
          <div className="div-wrapper">
            <div className="crypto-market-name-2">
              <div className="text-wrapper-4">1</div>
              <div className="name-and-price">
                <div className="name-caption">
                  <img className="image" alt="Image" src="/img/image.png" />
                  <div className="text-wrapper-5">Bitcoin</div>
                  <div className="text-wrapper-5">BTC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
                <div className="text-wrapper-7">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-3">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-8">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-4">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-9">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-10">18,648 BTC</div>
                <Vector className="info" color="#A8B0C1" />
              </div>
              <img className="graph" alt="Graph" src="/img/crypto-market-progress.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-crypto-market-wrapper">
        <div className="div-4">
          <div className="div-wrapper">
            <div className="crypto-market-name-2">
              <div className="text-wrapper-4">2</div>
              <div className="name-and-price">
                <div className="name-caption">
                  <img className="image" alt="Image" src="/img/image.png" />
                  <div className="text-wrapper-5">Bitcoin</div>
                  <div className="text-wrapper-5">BTC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
                <div className="text-wrapper-7">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-3">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-8">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-4">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-9">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-10">18,648 BTC</div>
                <Vector className="info" color="#A8B0C1" />
              </div>
              <img className="graph" alt="Graph" src="/img/crypto-market-progress.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-crypto-market-wrapper">
        <div className="div-4">
          <div className="div-wrapper">
            <div className="crypto-market-name-2">
              <div className="text-wrapper-4">3</div>
              <div className="name-and-price">
                <div className="name-caption">
                  <img className="image" alt="Image" src="/img/image.png" />
                  <div className="text-wrapper-5">Bitcoin</div>
                  <div className="text-wrapper-5">BTC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
                <div className="text-wrapper-7">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-3">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-8">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-4">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-9">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-10">18,648 BTC</div>
                <Vector className="info" color="#A8B0C1" />
              </div>
              <img className="graph" alt="Graph" src="/img/crypto-market-progress.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-crypto-market-wrapper">
        <div className="div-4">
          <div className="div-wrapper">
            <div className="crypto-market-name-2">
              <div className="text-wrapper-4">4</div>
              <div className="name-and-price">
                <div className="name-caption">
                  <img className="image" alt="Image" src="/img/image.png" />
                  <div className="text-wrapper-5">Bitcoin</div>
                  <div className="text-wrapper-5">BTC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
                <div className="text-wrapper-7">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-3">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-8">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-4">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-9">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-10">18,648 BTC</div>
                <Vector className="info" color="#A8B0C1" />
              </div>
              <img className="graph" alt="Graph" src="/img/crypto-market-progress.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-crypto-market-wrapper">
        <div className="div-4">
          <div className="div-wrapper">
            <div className="crypto-market-name-2">
              <div className="text-wrapper-4">5</div>
              <div className="name-and-price">
                <div className="name-caption">
                  <img className="image" alt="Image" src="/img/image.png" />
                  <div className="text-wrapper-5">Bitcoin</div>
                  <div className="text-wrapper-5">BTC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-5">
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
                <div className="text-wrapper-7">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-3">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-8">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-4">
                <img className="img" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-9">0.65%</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
            <div className="asset-change-value">
              <div className="asset-change-value-2">
                <div className="text-wrapper-6">0.001053</div>
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-10">18,648 BTC</div>
                <Vector className="info" color="#A8B0C1" />
              </div>
              <img className="graph" alt="Graph" src="/img/crypto-market-progress.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
