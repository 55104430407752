/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartMini1 = ({ className }) => {
  return (
    <svg
      className={`chart-mini-1 ${className}`}
      fill="none"
      height="102"
      viewBox="0 0 128 102"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.20181 38.3804C-8.38681 38.3804 -18.7278 47.3298 -18.7278 47.3298V100.778H146.728V26.9993C146.728 26.9993 141.351 20.7088 132.847 18.6717C117.52 14.9999 115.381 41.8053 101.917 41.8054C92.0272 41.8054 91.344 25.0562 78.1574 23.5294C64.9708 22.0027 59.0224 60.4658 37.7647 60.0322C21.8401 59.7074 12.1573 38.3804 3.20181 38.3804Z"
        fill="url(#paint0_linear_2004_29742)"
      />
      <path
        className="path"
        d="M-18.4523 47.215C-18.4523 47.215 -8.38705 38.347 3.20156 38.347C12.8464 38.347 21.8399 59.6385 37.7644 59.9627C59.0221 60.3956 64.9705 21.9966 78.1571 23.5208C91.3438 25.045 92.0269 41.7663 101.917 41.7663C115.381 41.7662 117.52 15.0055 132.847 18.6711C141.35 20.7048 146.728 26.9848 146.728 26.9848"
        stroke="url(#paint1_linear_2004_29742)"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        className="path"
        d="M76.1337 11.1573C76.1337 5.06543 81.0721 0.126953 87.164 0.126953C93.256 0.126953 98.1944 5.06542 98.1944 11.1573V100.779H76.1337V11.1573Z"
        fill="url(#paint2_linear_2004_29742)"
        opacity="0.3"
      />
      <g className="g" filter="url(#filter0_d_2004_29742)">
        <circle className="circle-4" cx="87.1642" cy="27.4272" fill="url(#paint3_linear_2004_29742)" r="6.89399" />
        <circle className="circle-4" cx="87.1642" cy="27.4272" r="3.89399" stroke="white" strokeWidth="6" />
      </g>
      <path className="path" d="M-18.7278 100.779H146.728" opacity="0.1" stroke="#454459" />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="63.7871"
          id="filter0_d_2004_29742"
          width="63.788"
          x="55.2703"
          y="0.533203"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="5" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="12.5" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2004_29742" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_2004_29742"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_29742"
          x1="64"
          x2="64"
          y1="18.3262"
          y2="100.778"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.3" />
          <stop className="stop" offset="1" stopColor="#8D79F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2004_29742"
          x1="64.1377"
          x2="64.1377"
          y1="18.3262"
          y2="59.9664"
        >
          <stop className="stop" stopColor="#B09FFF" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2004_29742"
          x1="87.164"
          x2="87.164"
          y1="0.126953"
          y2="100.779"
        >
          <stop className="stop" stopColor="#B09FFF" stopOpacity="0.15" />
          <stop className="stop" offset="1" stopColor="#8D79F6" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2004_29742"
          x1="87.1642"
          x2="87.1642"
          y1="20.5332"
          y2="34.3212"
        >
          <stop className="stop" stopColor="#FD95D3" />
          <stop className="stop" offset="1" stopColor="#FF5CBE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
