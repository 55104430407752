/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AdvancedSniperPropertyIcon15 = ({ className }) => {
  return (
    <svg
      className={`advanced-sniper-property-icon-15 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2050_11936)">
        <path
          className="path"
          d="M11.1013 2.30309L11.534 1.87042C11.8783 1.52626 12.3451 1.33295 12.8319 1.33301C13.3187 1.33307 13.7855 1.5265 14.1297 1.87075C14.4738 2.21501 14.6671 2.68188 14.6671 3.16866C14.667 3.65544 14.4736 4.12226 14.1293 4.46642L13.696 4.89909C13.696 4.89909 12.7773 4.84509 11.9667 4.03375C11.1553 3.22309 11.1013 2.30375 11.1013 2.30375L7.12467 6.27975C6.85533 6.54909 6.72067 6.68375 6.60467 6.83242C6.468 7.00709 6.35133 7.19709 6.25533 7.39775C6.17467 7.56775 6.11467 7.74842 5.994 8.10975L5.608 9.26642M5.608 9.26642L5.35867 10.0144C5.32934 10.1017 5.32494 10.1953 5.34595 10.2849C5.36695 10.3746 5.41254 10.4565 5.47758 10.5216C5.54263 10.5867 5.62455 10.6324 5.71413 10.6535C5.80371 10.6746 5.8974 10.6703 5.98467 10.6411L6.73333 10.3918M5.608 9.26642L6.73333 10.3918M13.6967 4.89842L9.72 8.87509C9.45067 9.14442 9.316 9.27909 9.16733 9.39509C8.99213 9.53171 8.80257 9.64885 8.602 9.74442C8.432 9.82509 8.25133 9.88509 7.89 10.0058L6.73333 10.3918"
          stroke="#A8B0C1"
        />
        <path
          className="path"
          d="M14.6667 7.99967C14.6667 11.6817 11.682 14.6663 8 14.6663C4.318 14.6663 1.33333 11.6817 1.33333 7.99967C1.33333 4.31767 4.318 1.33301 8 1.33301"
          stroke="#A8B0C1"
          strokeLinecap="round"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2050_11936">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
