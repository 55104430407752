/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const MarketStatsBlock = ({
  value = "$00.00M",
  caption = "CAPTION",
  className,
  divClassName,
  divClassNameOverride,
}) => {
  return (
    <div className={`market-stats-block ${className}`}>
      <div className={`CAPTION ${divClassNameOverride}`}>{caption}</div>
      <div className={`element ${divClassName}`}>{value}</div>
    </div>
  );
};

MarketStatsBlock.propTypes = {
  value: PropTypes.string,
  caption: PropTypes.string,
};
