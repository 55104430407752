/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {PageContentMevCeptionTotals} from "../PageContentMevCeptionTotals";
import {PageContentChart} from "../PageContentChart";
import {PageContentCryptoMarket} from "../PageContentCryptoMarket";
import {PageContentCryptoMarketMobile} from "../PageContentCryptoMarketMobile";
import {PageContentCryptoStatsDesktop} from "../PageContentCryptoStatsDesktop";
import {PageContentCryptoStatsMobile} from "../PageContentCryptoStatsMobile";
import {PageContentGameCeptionMissions} from "../PageContentGameCeptionMissions";
import {PageContentMevCeptionMissions} from "../PageContentMevCeptionMissions";
import {PageContentMarketCeptionMissions} from "../PageContentMarketCeptionMissions";
import {PageContentCopyCeptionMissions} from "../PageContentCopyCeptionMissions";
import {PageContentGameCeptionSocialMissions} from "../PageContentGameCeptionSocialMissions";
import {PageContentMarketCeptionSettings} from "../PageContentMarketCeptionSettings";
import {PageContentGameCeptionLeaderboard} from "../PageContentGameCeptionLeaderboard";
import {WalletManagement} from "../WalletManagement";
import {PageContentGameCeptionReferrrals} from "../PageContentGameCeptionReferrals";
import {PageContentMevCeptionSettings} from "../PageContentMevCeptionSettings";
import {PageContentGameCeptionOverview} from "../PageContentGameCeptionOverview";
import {PageContentCopyCeptionCopyWallet} from "../PageContentCopyCeptionCopyWallet";
import {PageContentCopyCeptionCopyWalletData} from "../PageContentCopyCeptionCopyWalletData";
import {PageContentCopyCeptionDashboard} from "../PageContentCopyCeptionDashboard";
import {PageContentGameCeptionTradeStatistics} from "../PageContentGameCeptionTradeStatistics";
import {
  PAGE_CONFIGURATION,
  PAGE_COPY_COPY_WALLET, PAGE_COPY_MISSIONS,
  PAGE_COPY_OVERVIEW, PAGE_COPY_VIEW_COPIED_WALLET,
  PAGE_COPY_WALLET_MANAGEMENT,
  PAGE_GAME_LEADERBOARD, PAGE_GAME_MISSIONS, PAGE_GAME_OVERVIEW, PAGE_GAME_REFERRALS,
  PAGE_GAME_SOCIAL_MISSIONS, PAGE_GAME_TRADER_STATISTICS,
  PAGE_HOME, PAGE_MARKET_OVERVIEW, PAGE_MARKET_POINTS, PAGE_MARKET_SETTINGS,
  PAGE_MARKET_WALLET_MANAGEMENT, PAGE_MEV_COPY_WALLET,
  PAGE_MEV_MISSIONS,
  PAGE_MEV_OVERVIEW,
  PAGE_MEV_SETTINGS, PAGE_MEV_VIEW_COPIED_WALLET,
  PAGE_MEV_WALLET_MANAGEMENT,
  PAGE_WALLET_MANAGEMENT, SCREEN_DESKTOP, SCREEN_MOBILE
} from "../../constants";
import {PageContentAdvancedSniper} from "../PageContentAdvancedSniper";
import {PageContentSnipes} from "../PageContentSnipes";
import {AddressForm} from "../AddressForm";
import {PageContentTradingView} from "../PageContentTradingView";
import {PageContentChartSectionDesktop} from "../PageContentChartSectionDesktop/PageContentChartSectionDesktop";
import TradingViewWidget from "../PageContentTradingView/PageContentTradingView";

export const PageContent = ({currentPage = "", screen}) => {
  const testMode = false;
  return (
    <>
      {currentPage === PAGE_HOME && (
        <>
          <AddressForm/>
          {/*<TradingViewWidget/>*/}
          <PageContentChart/>
          <PageContentSnipes screen={screen}/>
          {screen === SCREEN_MOBILE && <PageContentCryptoMarketMobile/>}
          {screen === SCREEN_DESKTOP && <PageContentCryptoMarket/>}
        </>)
      }

      {currentPage === PAGE_CONFIGURATION && <PageContentAdvancedSniper/>}

      {currentPage === PAGE_MEV_OVERVIEW && <PageContentMevCeptionTotals screen={screen}/>}
      {currentPage === PAGE_MEV_MISSIONS && <PageContentMevCeptionMissions screen={screen}/>}
      {currentPage === PAGE_MEV_SETTINGS && <PageContentMevCeptionSettings screen={screen}/>}
      {currentPage === PAGE_MEV_COPY_WALLET && <PageContentCopyCeptionCopyWallet screen={screen}/> }
      {currentPage === PAGE_MEV_VIEW_COPIED_WALLET && <PageContentCopyCeptionCopyWalletData screen={screen}/>}

      {currentPage === PAGE_GAME_OVERVIEW && <PageContentGameCeptionOverview screen={screen}/>}
      {currentPage === PAGE_GAME_LEADERBOARD && <PageContentGameCeptionLeaderboard screen={screen}/>}
      {currentPage === PAGE_GAME_MISSIONS && <PageContentGameCeptionMissions screen={screen}/>}
      {currentPage === PAGE_GAME_SOCIAL_MISSIONS && <PageContentGameCeptionSocialMissions screen={screen}/>}
      {currentPage === PAGE_GAME_TRADER_STATISTICS && <PageContentGameCeptionTradeStatistics screen={screen}/>}
      {currentPage === PAGE_GAME_REFERRALS && <PageContentGameCeptionReferrrals/>}


      {currentPage === PAGE_COPY_OVERVIEW && <PageContentCopyCeptionDashboard screen={screen}/>}
      {currentPage === PAGE_COPY_MISSIONS && <PageContentCopyCeptionMissions screen={screen}/>}
      {currentPage === PAGE_COPY_COPY_WALLET && <PageContentCopyCeptionCopyWallet screen={screen}/> }
      {currentPage === PAGE_COPY_VIEW_COPIED_WALLET && <PageContentCopyCeptionCopyWalletData screen={screen}/>}

      {currentPage === PAGE_MARKET_OVERVIEW && <PageContentCopyCeptionDashboard screen={screen}/>}
      {currentPage === PAGE_MARKET_POINTS && <PageContentMarketCeptionMissions screen={screen}/>}
      {currentPage === PAGE_MARKET_SETTINGS && <PageContentMarketCeptionSettings screen={screen}/>}

      {[PAGE_WALLET_MANAGEMENT, PAGE_MEV_WALLET_MANAGEMENT, PAGE_COPY_WALLET_MANAGEMENT, PAGE_MARKET_WALLET_MANAGEMENT].includes(currentPage) &&
      <WalletManagement screen={screen}/>
      }
      {testMode && <>
        <PageContentCopyCeptionDashboard screen={screen}/>
        <PageContentMevCeptionMissions screen={screen}/>
        <PageContentMevCeptionTotals screen={screen}/>
        <PageContentGameCeptionSocialMissions screen={screen}/>
        <PageContentGameCeptionLeaderboard screen={screen}/>
        <PageContentCopyCeptionMissions screen={screen}/>
        <PageContentMarketCeptionMissions screen={screen}/>
        <PageContentMarketCeptionSettings screen={screen}/>
        <WalletManagement screen={screen}/>
        <PageContentGameCeptionReferrrals/>
        <PageContentMevCeptionSettings screen={screen}/>
        <PageContentGameCeptionOverview screen={screen}/>
        <PageContentCopyCeptionCopyWallet screen={screen}/>
        <PageContentCopyCeptionCopyWalletData screen={screen}/>
        </>}
    </>
  );
};

PageContent.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
  currentPage: PropTypes.string.isRequired
};
