/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CircleGraph = ({ className }) => {
  return (
    <svg
      className={`circle-graph ${className}`}
      fill="none"
      height="150"
      viewBox="0 0 150 150"
      width="150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle-4" cx="75" cy="75" r="73.5" stroke="#02FFFA" strokeWidth="3" />
      <path
        className="path"
        d="M138.313 79.5891C138.313 113.1 109.967 140.266 75.0011 140.266C40.0351 140.266 11.6895 113.1 11.6895 79.5891C11.6895 46.0783 21.5048 74.1634 56.4709 74.1634C90.9577 71.2032 138.313 46.0783 138.313 79.5891Z"
        fill="url(#paint0_linear_2004_27948)"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2004_27948"
          x1="138.313"
          x2="4.96515"
          y1="62.3438"
          y2="134.909"
        >
          <stop className="stop" stopColor="#02FFFA" />
          <stop className="stop" offset="1" stopColor="#006F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
