/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { AdvancedSniperPropertyIcon15 } from "../../icons/AdvancedSniperPropertyIcon15";
import "./style.css";

export const AdvancedSniperProperty = ({ value = "Current value", caption = "Caption", className }) => {
  return (
    <div className={`advanced-sniper ${className}`}>
      <div className="property-caption">
        <div className="caption-wrapper">
          <div className="text-wrapper">{caption}</div>
        </div>
        <AdvancedSniperPropertyIcon15 className="advanced-sniper-property-icon-15" />
      </div>
      <div className="property-value">
        <div className="current-value-wrapper">
          <div className="current-value">{value}</div>
        </div>
      </div>
    </div>
  );
};

AdvancedSniperProperty.propTypes = {
  value: PropTypes.string,
  caption: PropTypes.string,
};
