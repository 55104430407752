/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { TabHeaderItem } from "../TabHeaderItem";
import "./style.css";
import {
  ADVANCED_SNIPER_TAB_ADVANCED_SNIPER,
  ADVANCED_SNIPER_TAB_AUTO_SNIPES,
  ADVANCED_SNIPER_TAB_FAST_CONFIG, ADVANCED_SNIPER_TAB_TRADE_MONITOR
} from "../../constants";

export const AdvancedSniperTabPanel = ({
  className,
  selectedTab,
  onTabChange = () => {}
}) => {
  return (
    <div className={`advanced-sniper-tab ${className}`}>
      <div className="tabs-wrapper">
        <div className="tabs-container">
          <TabHeaderItem onClick={() => onTabChange(ADVANCED_SNIPER_TAB_AUTO_SNIPES)} caption="Auto Snipes" className="tab-header-item-instance" active={selectedTab === ADVANCED_SNIPER_TAB_AUTO_SNIPES} />
          <TabHeaderItem
            onClick={() => onTabChange(ADVANCED_SNIPER_TAB_FAST_CONFIG)}
            caption="Fast Config Sniper"
            className="tab-header-item-instance"
            active={selectedTab === ADVANCED_SNIPER_TAB_FAST_CONFIG}
          />
          <TabHeaderItem onClick={() => onTabChange(ADVANCED_SNIPER_TAB_ADVANCED_SNIPER)} caption="Advanced Sniper" className="tab-header-item-instance" active={selectedTab === ADVANCED_SNIPER_TAB_ADVANCED_SNIPER} />
          <TabHeaderItem onClick={() => onTabChange(ADVANCED_SNIPER_TAB_TRADE_MONITOR)} caption="Trade Monitor" className="tab-header-item-instance" active={selectedTab === ADVANCED_SNIPER_TAB_TRADE_MONITOR} />
        </div>
      </div>
    </div>
  );
};

AdvancedSniperTabPanel.propTypes = {
  tabHeaderItemState: PropTypes.string,
  tabHeaderItemState1: PropTypes.string,
  tabHeaderItemState2: PropTypes.string,
  tabHeaderItemState3: PropTypes.string,
};
