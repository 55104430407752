/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Cell } from "../Cell";
import { Row } from "../Row";
import { Type } from "../Type";
import { TypeDefault } from "../TypeDefault";
import "./style.css";

export const PageContentCryptoStatsMobile = ({
  property1,
  className,
  elementColumnsClassName,
  row = <TypeDefault className="cell-5" text="Break Even" textClassName="cell-6" />,
  override = <TypeDefault className="cell-5" text="DCA Break Even" textClassName="cell-6" />,
  row1 = <TypeDefault className="cell-5" text="Market Cap" textClassName="cell-6" />,
  row2 = <TypeDefault className="cell-5" text="Token Amount" textClassName="cell-7" />,
  row3 = <TypeDefault className="cell-5" text="Amount Spend" textClassName="cell-7" />,
  row4 = <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name" textClassName="cell-6" />,
  row5 = <TypeDefault className="cell-5" text="Profit Loss" textClassName="cell-6" />,
  row6 = <Cell className="cell-8" text="#" textClassName="cell-type-instance" />,
  row7 = <TypeDefault className="cell-5" text="34%" textClassName="cell-9" />,
  row8 = <TypeDefault className="cell-5" text="345" textClassName="cell-9" />,
  row9 = <TypeDefault className="cell-5" text="34" textClassName="cell-9" />,
  row10 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row11 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row12 = (
    <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name Here" textClassName="cell-9" />
  ),
  row13 = <TypeDefault className="cell-5" text="$ 50" textClassName="cell-9" />,
  row14 = <Cell className="cell-8" text="1" textClassName="cell-type3-instance" />,
  row15 = <TypeDefault className="cell-5" text="34%" textClassName="cell-9" />,
  row16 = <TypeDefault className="cell-5" text="345" textClassName="cell-9" />,
  row17 = <TypeDefault className="cell-5" text="34" textClassName="cell-9" />,
  row18 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row19 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row20 = (
    <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name Here" textClassName="cell-9" />
  ),
  row21 = <TypeDefault className="cell-5" text="$ 50" textClassName="cell-9" />,
  row22 = <Cell className="cell-8" text="2" textClassName="cell-type3-instance" />,
  row23 = <TypeDefault className="cell-5" text="34%" textClassName="cell-9" />,
  row24 = <TypeDefault className="cell-5" text="345" textClassName="cell-9" />,
  row25 = <TypeDefault className="cell-5" text="34" textClassName="cell-9" />,
  row26 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row27 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row28 = (
    <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name Here" textClassName="cell-9" />
  ),
  row29 = <TypeDefault className="cell-5" text="$ 50" textClassName="cell-9" />,
  row30 = <Cell className="cell-8" text="3" textClassName="cell-type3-instance" />,
  row31 = <TypeDefault className="cell-5" text="34%" textClassName="cell-9" />,
  row32 = <TypeDefault className="cell-5" text="345" textClassName="cell-9" />,
  row33 = <TypeDefault className="cell-5" text="34" textClassName="cell-9" />,
  row34 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row35 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row36 = (
    <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name Here" textClassName="cell-9" />
  ),
  row37 = <TypeDefault className="cell-5" text="$ 50" textClassName="cell-9" />,
  row38 = <Cell className="cell-8" text="4" textClassName="cell-type3-instance" />,
  row39 = <TypeDefault className="cell-5" text="34%" textClassName="cell-9" />,
  row40 = <TypeDefault className="cell-5" text="345" textClassName="cell-9" />,
  row41 = <TypeDefault className="cell-5" text="34" textClassName="cell-9" />,
  row42 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row43 = <TypeDefault className="cell-5" text="$ 150" textClassName="cell-10" />,
  row44 = (
    <Type className="cell-8" contentClassName="type-type4-instance" text="Token Name Here" textClassName="cell-9" />
  ),
  row45 = <TypeDefault className="cell-5" text="$ 50" textClassName="cell-9" />,
  row46 = <Cell className="cell-8" text="5" textClassName="cell-type3-instance" />,
  ETHBusdClassName,
}) => {
  return (
    <div className={`crypto-stats-mobile ${className}`}>
      <div className={`element-columns property-1-${property1}`}>
        <div className={`element-columns-2 ${elementColumnsClassName}`}>
          {property1 === "open-snipes" && (
            <>
              <div className="column-6">
                <div className="text-wrapper-14">Open Snipes</div>
              </div>
              <div className="column-7">
                <div className="text-wrapper-15">Previous Snipes</div>
              </div>
              <div className="column-7">
                <div className="text-wrapper-15">Snipe History</div>
              </div>
              <div className="column-7">
                <div className="text-wrapper-15">Wallets</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Open Trades</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Nuke Value</div>
              </div>
            </>
          )}

          {property1 === "wallets" && (
            <div className="element-columns-3">
              <div className="column-8">
                <div className="text-wrapper-15">Open Snipes</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Previous Snipes</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Snipe History</div>
              </div>
              <div className="column-9">
                <div className="text-wrapper-14">Wallets</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Wallets</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Open Trades</div>
              </div>
              <div className="column-8">
                <div className="text-wrapper-15">Nuke Value</div>
              </div>
            </div>
          )}
        </div>
        <div className="group-2">
          <div className="text-wrapper-16">Hide Other Pairs</div>
          <div className="rectangle-4" />
        </div>
      </div>
      <div className={`frame-10 property-1-1-${property1}`}>
        {property1 === "open-snipes" && (
          <>
            <Row
              className="instance-node"
              override={row6}
              override1={row4}
              override2={row2}
              override3={row3}
              override4={row5}
              override5={row}
              override6={row1}
              override7={override}
              visible={false}
              visible1={false}
            />
            <Row
              className="instance-node"
              override={row14}
              override1={row12}
              override2={row10}
              override3={row11}
              override4={row13}
              override5={row7}
              override6={row9}
              override7={row8}
              visible={false}
              visible1={false}
            />
            <Row
              className="instance-node"
              override={row22}
              override1={row20}
              override2={row18}
              override3={row19}
              override4={row21}
              override5={row15}
              override6={row17}
              override7={row16}
              visible={false}
              visible1={false}
            />
            <Row
              className="instance-node"
              override={row30}
              override1={row28}
              override2={row26}
              override3={row27}
              override4={row29}
              override5={row23}
              override6={row25}
              override7={row24}
              visible={false}
              visible1={false}
            />
            <Row
              className="instance-node"
              override={row38}
              override1={row36}
              override2={row34}
              override3={row35}
              override4={row37}
              override5={row31}
              override6={row33}
              override7={row32}
              visible={false}
              visible1={false}
            />
            <Row
              className="instance-node"
              override={row46}
              override1={row44}
              override2={row42}
              override3={row43}
              override4={row45}
              override5={row39}
              override6={row41}
              override7={row40}
              visible={false}
              visible1={false}
            />
          </>
        )}

        {property1 === "wallets" && (
          <>
            <div className="frame-11">
              <div className="frame-12">
                <div className="text-wrapper-17">Wallet 1</div>
              </div>
              <div className="frame-13">
                <div className="text-wrapper-18">Wallet 2</div>
              </div>
              <div className="frame-13">
                <div className="text-wrapper-18">Wallet 3</div>
              </div>
            </div>
            <div className="element-columns-4">
              <div className="column-10">
                <div className="text-wrapper-19">Token Name:</div>
                <div className="text-wrapper-20">Balance:</div>
                <div className="text-wrapper-20">ETH Value:</div>
              </div>
              <div className="column-10">
                <div className="text-wrapper-21">Token Name Here</div>
                <div className="text-wrapper-22">£ 459</div>
                <div className="text-wrapper-22">£ 459</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="frame-14">
        <p className="div-3">
          <span className="text-wrapper-23">BNB/BUSD </span>
          <span className="text-wrapper-24">+8.39 %</span>
          <span className="text-wrapper-23">&nbsp;</span>
          <span className="text-wrapper-25">384.8200</span>
        </p>
        <p className="div-3">
          <span className="text-wrapper-23">BTC/BUSD </span>
          <span className="text-wrapper-24">+0.53 %</span>
          <span className="text-wrapper-23">&nbsp;</span>
          <span className="text-wrapper-25">39728.27</span>
        </p>
        <p className={`ETH-BUSD ${ETHBusdClassName}`}>
          <span className="text-wrapper-23">ETH/BUSD </span>
          <span className="text-wrapper-24">+4.53 %</span>
          <span className="text-wrapper-23">&nbsp;</span>
          <span className="text-wrapper-25">2728.14</span>
        </p>
      </div>
      <div className="rectangle-5" />
    </div>
  );
};

PageContentCryptoStatsMobile.propTypes = {
  property1: PropTypes.oneOf(["open-snipes", "wallets"]),
};
