/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TabHeaderItem = ({ caption = "Caption", active = false, className, onClick = () => {} }) => {
  return (
    <div className={`tab-header-item ${className}`} onClick={onClick}>
      <div className={`tab-menu-item ${active ? 'active' : 'default'}`}>
        <div className="caption">{caption}</div>
      </div>
    </div>
  );
};

TabHeaderItem.propTypes = {
  caption: PropTypes.string,
  active: PropTypes.bool,
};
