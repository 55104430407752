/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Mission = ({
  value2 = "2500 Points",
  caption = "Caption",
  silver = true,
  gold = true,
  value1 = "3/10 ETH ",
  bronze = true,
  status = "STATUS",
  format,
}) => {
  return (
    <div className={`mission ${format}`}>
      <div className="mission-status">
        <div className={`STATUS-wrapper`}>
          <div className="STATUS">{status}</div>
        </div>
        {gold && (
          <img
            className={`mission-status-gold`}
            alt="Mission status gold"
            src="/img/mission-status-gold.png"
          />
        )}

        {silver && (
          <img
            className={`img`}
            alt="Mission status"
            src="/img/mission-status-silver.png"
          />
        )}

        {bronze && (
          <img
            className={`mission-status-2`}
            alt="Mission status"
            src="/img/mission-status-bronze.png"
          />
        )}
      </div>
      <div className="caption">{caption}</div>
      <img
        className="mission-progress"
        alt="Mission progress"
        src="/img/mission-progress.svg"
      />
      <div className="progress-values">
        <div className="progress-value">
          <div className="text-wrapper">{value1}</div>
        </div>
        <div className="progress-value">
          <div className="text-wrapper">{value2}</div>
        </div>
      </div>
    </div>
  );
};

Mission.propTypes = {
  value2: PropTypes.string,
  caption: PropTypes.string,
  silver: PropTypes.bool,
  gold: PropTypes.bool,
  value1: PropTypes.string,
  bronze: PropTypes.bool,
  status: PropTypes.string,
  format: PropTypes.oneOf(["small", "default"]),
};
