import React, {useState} from "react";
import {useWindowWidth} from "../../breakpoints";
import {HeaderPanel} from "../../components/HeaderPanel";
import {MobileHeaderPanel} from "../../components/MobileHeaderPanel";
import {MoreButtonOld} from "../../components/MoreButton";
import {SettingsSummaryWrapper} from "../../components/SettingsSummaryWrapper";
import "./style.css";
import {PageContent} from "../../components/PageContent";
import {RightColumn} from "../../components/RightColumn";
import {SidebarMenu} from "../../components/SidebarMenu";
import {
  getPageCaption, getProjectCaption,
  MOBILE_HEADER_NONE,
  PAGE_CONFIGURATION,
  PAGE_COPY_COPY_WALLET, PAGE_COPY_OVERVIEW,
  PAGE_GAME_OVERVIEW, PAGE_HOME,
  PAGE_MARKET_OVERVIEW,
  PAGE_MARKET_SETTINGS,
  PAGE_MEV_OVERVIEW, PAGE_MEV_VIEW_COPIED_WALLET, PAGE_WALLET_MANAGEMENT, PROJECT_COPY, PROJECT_GAME,
  PROJECT_MARKET,
  PROJECT_MEV, PROJECT_NONE, SCREEN_MOBILE
} from "../../constants";
import {Xceptionswap} from "../../components/Xceptionswap";
import {MoreButton} from "../../components/MoreButton/MoreButton";

const DESKTOP_MIN_WIDTH = 1140;

export const MainScreen = () => {
  const screenWidth = useWindowWidth();

  const isMobile = screenWidth < DESKTOP_MIN_WIDTH;
  const isDesktop = screenWidth >= DESKTOP_MIN_WIDTH;

  const screen = isMobile ? 'mobile' : 'desktop';

  const [mobileHeaderState, setMobileHeaderState] = useState(MOBILE_HEADER_NONE);
  const handleMobileHeaderStateChange = newState => {
    if (mobileHeaderState === newState) {
      setMobileHeaderState(MOBILE_HEADER_NONE);
    } else {
      setMobileHeaderState(newState);
    }
  }

  const [currentPage, setCurrentPage] = useState(PAGE_HOME);
  const [isRightColumn, setIsRightColumn] = useState(true);
  const [currentProject, setCurrentProject] = useState('none');
  const handlePageChange = newPage => {
    console.log('new page', newPage);
    setMobileHeaderState(MOBILE_HEADER_NONE)
    setCurrentPage(newPage);
    if (newPage === PAGE_HOME || newPage === PAGE_CONFIGURATION) {
      setIsRightColumn(true);
      setCurrentProject('none');
    } else {
      setIsRightColumn(false);
    }

    if (newPage === PAGE_WALLET_MANAGEMENT) {
      setCurrentProject('none');
    }
  }
  const handleProjectChange = newProject => {
    console.log('new project', newProject);
    setCurrentProject(newProject);
    switch (newProject) {
      case PROJECT_MEV:
        handlePageChange(PAGE_MEV_OVERVIEW);
        break;
      case PROJECT_MARKET:
        handlePageChange(PAGE_MARKET_OVERVIEW);
        break;
      case PROJECT_GAME:
        handlePageChange(PAGE_GAME_OVERVIEW);
        break;
      case PROJECT_COPY:
        handlePageChange(PAGE_COPY_OVERVIEW);
        break;
    }
  }

  const currentPageCaption = currentProject === PROJECT_NONE ? getPageCaption(currentPage) : getProjectCaption(currentProject);

  const handleLogoClick = () => {
    handlePageChange(PAGE_HOME);
  }

  const [sidebarConfigurationInfoExpanded, setSidebarConfigurationInfoExpanded] = useState(false);

  return (
    <div
      className="empty-mobile"
      style={{
        backgroundColor: isMobile ? "#010101" : isDesktop ? "#000000" : undefined,
        flexDirection: isMobile ? "column" : undefined,
        flexWrap: isDesktop ? "wrap" : undefined,
        gap: isMobile ? "10px" : isDesktop ? "-7px 24px" : undefined,
        // minHeight: isMobile ? "926px" : isDesktop ? "2514.11px" : undefined,
        minWidth: isMobile ? "428px" : isDesktop ? DESKTOP_MIN_WIDTH + "px" : undefined,
        padding: isMobile ? "10px" : isDesktop ? "20px 18px" : undefined,
        width: isDesktop ? "100%" : undefined,
      }}
    >
      {isMobile && (
        <>
          <MobileHeaderPanel
            className="instance-node"
            headerPanelMenu="/img/header-panel-menu-4.svg"
            selectedMenuItem={mobileHeaderState}
            currentProject={currentProject}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onProjectChange={handleProjectChange}
            settingsSummaryDivClassName="mobile-header-panel-2"
            settingsSummaryDivClassNameOverride="mobile-header-panel-5"
            settingsSummaryFrameClassName="mobile-header-panel-instance"
            settingsSummaryFrameClassNameOverride="mobile-header-panel-5"
            settingsSummaryGraph="/img/graph-2.svg"
            settingsSummaryGroupClassName="mobile-header-panel-3"
            settingsSummaryMoreButtonIcon="/img/icon-3.svg"
            settingsSummaryMoreButtonMoreButtonClassName="mobile-header-panel-4"
            settingsSummaryOverlapGroupClassName="mobile-header-panel-6"
            onHeaderMenuItemClick={handleMobileHeaderStateChange}
          />
          <PageContent currentPage={currentPage} screen={screen}/>
        </>
      )}

      {isDesktop && (
        <div className="page">
          <div className="left-column">
            <img onClick={handleLogoClick} className="xception-logo" alt="Xception logo" src="/img/xception-logo.png"/>
            <SidebarMenu currentPage={currentPage} currentProject={currentProject} onPageChange={handlePageChange}/>
            {(currentPage === PAGE_HOME || currentPage === PAGE_CONFIGURATION || (currentPage === PAGE_WALLET_MANAGEMENT && currentProject === PROJECT_NONE)) &&
            <>
              {<SettingsSummaryWrapper
                className="settings-summary-block"
                group="/img/group-36762-8.png"
                img="/img/group-36762-8.png"
                marketStatsGraph="/img/graph-5.svg"
              />}
              {<div className="settings-properties-2">
                <div className="frame-29">
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/>
                      </div>
                      <div className="text-wrapper-30">Contract Verification</div>
                    </div>
                    <div className="text-wrapper-31">Yes</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                  </div>
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/>
                      </div>
                      <div className="text-wrapper-30">Honeypot</div>
                    </div>
                    <div className="text-wrapper-31">No</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                  </div>
                </div>
                <div className="frame-29">
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/>
                      </div>
                      <div className="text-wrapper-30">Buy Tax</div>
                    </div>
                    <div className="text-wrapper-31">1%</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                  </div>
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                      <div className="text-wrapper-30">Sell Tax</div>
                    </div>
                    <div className="text-wrapper-31">5%</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                  </div>
                </div>
                <div className={`frame-29${!sidebarConfigurationInfoExpanded ? ' no-border' : ''}`}>
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                      <div className="text-wrapper-30">Transfer Tax</div>
                    </div>
                    <div className="text-wrapper-31">5%</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_close-circle-bold.svg"/>
                  </div>
                  <div className="frame-30">
                    <div className="settings-property-caption-wrapper">
                      <div className="group-6">
                        <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                      <div className="text-wrapper-30">Holders</div>
                    </div>
                    <div className="text-wrapper-31">791</div>
                    <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                  </div>
                </div>
                {sidebarConfigurationInfoExpanded &&
                <>
                  <div className="frame-29">
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Siphoned</div>
                      </div>
                      <div className="text-wrapper-31">0</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Average Tax</div>
                      </div>
                      <div className="text-wrapper-31">0</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                  </div>
                  <div className="frame-29">
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Average Gas</div>
                      </div>
                      <div className="text-wrapper-31">0</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Highest Tax</div>
                      </div>
                      <div className="text-wrapper-31">3</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                  </div>
                  <div className="frame-29">
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Average Gas</div>
                      </div>
                      <div className="text-wrapper-31">0</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Highest Tax</div>
                      </div>
                      <div className="text-wrapper-31">5%</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                  </div>
                  <div className="frame-29">
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">High tax Wallets</div>
                      </div>
                      <div className="text-wrapper-31">0</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Proxy Call Risk</div>
                      </div>
                      <div className="text-wrapper-31">No</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                  </div>
                  <div className={`frame-29${sidebarConfigurationInfoExpanded ? ' no-border' : ''}`}>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Contract Verification</div>
                      </div>
                      <div className="text-wrapper-31">No</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                    <div className="frame-30">
                      <div className="settings-property-caption-wrapper">
                        <div className="group-6">
                          <img className="settings-icon-info" src="/img/ic-info.svg"/></div>
                        <div className="text-wrapper-30">Transfer Blocked</div>
                      </div>
                      <div className="text-wrapper-31">No</div>
                      <img className="untitled-design-2" alt="Untitled design" src="/img/solar_check-circle-bold.svg"/>
                    </div>
                  </div>
                </>}
                <MoreButton isMore={!sidebarConfigurationInfoExpanded}
                            onClick={() => setSidebarConfigurationInfoExpanded(!sidebarConfigurationInfoExpanded)}/>
              </div>
              }
            </>}
          </div>
          <div className="page-wrapper">
            <HeaderPanel caption={currentPageCaption} currentProject={currentProject}
                         className="header-dashboard-instance"
                         currentPage={currentPage} onPageChange={handlePageChange}
                         onProjectChange={handleProjectChange}/>
            <PageContent currentPage={currentPage} screen={screen}/>
          </div>
        </div>
      )}
    </div>
  );
};
