/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useState} from "react";
import { MarketStatsBlock } from "../MarketStatsBlock";
import "./style.css";
import {MoreButton} from "../MoreButton/MoreButton";

export const MarketStats = ({ caption = "MARKET CAP", className, graph = "/img/graph-3.svg" }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`market-stats ${className}`}>
      <div className="market-stats-row">
        <MarketStatsBlock caption="MARKET CAP" className="market-stats-block-instance" value="$37.00M" />
        <MarketStatsBlock caption="LIQUIDITY" className="market-stats-block-instance" value="$531.35k" />
      </div>
      <div className="market-stats-row">
        <MarketStatsBlock caption="CIRC. SUPPLY" className="market-stats-block-instance" value="$37.00M" />
        <MarketStatsBlock caption="HOLDERS" className="market-stats-block-instance" value="$531.35k" />
      </div>
      <div className="market-stats-row">
        <MarketStatsBlock caption="TOTAL MKTCAP" className="market-stats-block-instance" value="$37.00M" />
        <MarketStatsBlock caption="24H VOLUME" className="market-stats-block-instance" value="$531.35k" />
      </div>
      {expanded &&
      <>
        <div className="market-stats-row">
          <div className="market-stats-block-2">
            <div className="text-wrapper-20">% CIRC. SUPPLY</div>
            <div className="market-stats-block-3">
              <div className="value">
                <div className="text-wrapper-21">67.67%</div>
              </div>
              <img className="graph-2" alt="Graph" src={graph} />
            </div>
          </div>
          <MarketStatsBlock
            caption="POOL CREATED"
            className="market-stats-block-instance"
            divClassName="design-component-instance-node"
            value="8/4/2023 22:48"
          />
        </div>
        <div className="market-stats-row">
          <MarketStatsBlock caption="POOLED WETH" className="market-stats-block-instance" value="-" />
          <MarketStatsBlock caption="POOLED PNDC" className="market-stats-block-instance" value="-" />
        </div>
        <div className="market-stats-row">
          <MarketStatsBlock
            caption="% POOLED PNDC"
            className="market-stats-block-instance"
            divClassNameOverride="market-stats-block-4"
            value="-"
          />
          <MarketStatsBlock caption="1 ETH" className="market-stats-block-instance" value="1.01B" />
        </div>
      </>
      }
      <MoreButton isMore={!expanded} onClick={() => setExpanded(!expanded)}/>
    </div>
  );
};

MarketStats.propTypes = {
  caption: PropTypes.string,
  graph: PropTypes.string,
};
