/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Vector } from "../../icons/Vector";
import "./style.css";

export const PageContentCryptoMarketMobile = ({ }) => {
  return (
    <div className={`page-content-crypto-market-mobile`}>
      <div className="div">
        <div className="column">
          <div className="frame">
            <div className="text-wrapper">#</div>
            <div className="text-wrapper">Name</div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper">Price</div>
          </div>
        </div>
        <div className="column-2">
          <div className="div-wrapper">
            <div className="text-wrapper">24h%</div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper">24h%</div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper">6d%</div>
          </div>
        </div>
        <div className="column-3">
          <div className="frame-2">
            <div className="text-wrapper">Market Cap</div>
            <Vector className="info" color="#A8B0C1" />
          </div>
          <div className="frame-2">
            <div className="text-wrapper">Volume(24h)</div>
            <Vector className="info" color="#A8B0C1" />
          </div>
          <div className="frame-2">
            <div className="text-wrapper">Circulating Supply</div>
            <Vector className="info" color="#A8B0C1" />
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="column-4">
          <div className="frame-3">
            <div className="text-wrapper-2">1</div>
            <div className="frame-4">
              <img className="image" alt="Image" src="/img/image.png" />
              <div className="text-wrapper-3">Bitcoin</div>
              <div className="text-wrapper-3">BTC</div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">₩1,502,989,963</div>
          </div>
        </div>
        <div className="column-5">
          <div className="frame-5">
            <div className="auto-layout">
              <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
              <div className="text-wrapper-5">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="group">
              <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
              <div className="text-wrapper-6">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-5">
              <div className="group">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-6">0.65%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-6">
          <div className="frame-6">
            <div className="text-wrapper-7">₩1,502,989,963,439,782</div>
          </div>
          <div className="frame-7">
            <div className="text-wrapper-7">₩51,502,989,963,439</div>
            <div className="text-wrapper-8">932,071 BTC</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="text-wrapper-7">18,648,248 BTC</div>
              <Vector className="info" color="#A8B0C1" />
            </div>
            <img className="graph" alt="Graph" src="/img/crypto-market-graph.svg" />
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="column-4">
          <div className="frame-3">
            <div className="text-wrapper-2">2</div>
            <div className="frame-4">
              <img className="image" alt="Image" src="/img/image.png" />
              <div className="text-wrapper-3">Bitcoin</div>
              <div className="text-wrapper-3">BTC</div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">₩1,502,989,963</div>
          </div>
        </div>
        <div className="column-5">
          <div className="frame-5">
            <div className="auto-layout">
              <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
              <div className="text-wrapper-5">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="group">
              <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
              <div className="text-wrapper-6">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-5">
              <div className="group">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-6">0.65%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-6">
          <div className="frame-6">
            <div className="text-wrapper-7">₩1,502,989,963,439,782</div>
          </div>
          <div className="frame-7">
            <div className="text-wrapper-7">₩51,502,989,963,439</div>
            <div className="text-wrapper-8">932,071 BTC</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="text-wrapper-7">18,648,248 BTC</div>
              <Vector className="info" color="#A8B0C1" />
            </div>
            <img className="graph" alt="Graph" src="/img/crypto-market-graph.svg" />
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="column-4">
          <div className="frame-3">
            <div className="text-wrapper-2">3</div>
            <div className="frame-4">
              <img className="image" alt="Image" src="/img/image.png" />
              <div className="text-wrapper-3">Bitcoin</div>
              <div className="text-wrapper-3">BTC</div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">₩1,502,989,963</div>
          </div>
        </div>
        <div className="column-5">
          <div className="frame-5">
            <div className="auto-layout">
              <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
              <div className="text-wrapper-5">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="group">
              <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
              <div className="text-wrapper-6">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-5">
              <div className="group">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-6">0.65%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-6">
          <div className="frame-6">
            <div className="text-wrapper-7">₩1,502,989,963,439,782</div>
          </div>
          <div className="frame-7">
            <div className="text-wrapper-7">₩51,502,989,963,439</div>
            <div className="text-wrapper-8">932,071 BTC</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="text-wrapper-7">18,648,248 BTC</div>
              <Vector className="info" color="#A8B0C1" />
            </div>
            <img className="graph" alt="Graph" src="/img/crypto-market-graph.svg" />
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="column-4">
          <div className="frame-3">
            <div className="text-wrapper-2">4</div>
            <div className="frame-4">
              <img className="image" alt="Image" src="/img/image.png" />
              <div className="text-wrapper-3">Bitcoin</div>
              <div className="text-wrapper-3">BTC</div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">₩1,502,989,963</div>
          </div>
        </div>
        <div className="column-5">
          <div className="frame-5">
            <div className="auto-layout">
              <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
              <div className="text-wrapper-5">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="group">
              <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
              <div className="text-wrapper-6">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-5">
              <div className="group">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-6">0.65%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-6">
          <div className="frame-6">
            <div className="text-wrapper-7">₩1,502,989,963,439,782</div>
          </div>
          <div className="frame-7">
            <div className="text-wrapper-7">₩51,502,989,963,439</div>
            <div className="text-wrapper-8">932,071 BTC</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="text-wrapper-7">18,648,248 BTC</div>
              <Vector className="info" color="#A8B0C1" />
            </div>
            <img className="graph" alt="Graph" src="/img/crypto-market-graph.svg" />
          </div>
        </div>
      </div>
      <div className="div-2">
        <div className="column-4">
          <div className="frame-3">
            <div className="text-wrapper-2">5</div>
            <div className="frame-4">
              <img className="image" alt="Image" src="/img/image.png" />
              <div className="text-wrapper-3">Bitcoin</div>
              <div className="text-wrapper-3">BTC</div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">₩1,502,989,963</div>
          </div>
        </div>
        <div className="column-5">
          <div className="frame-5">
            <div className="auto-layout">
              <img className="img" alt="Vector" src="/img/crypto-market-arrow-down.svg" />
              <div className="text-wrapper-5">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="group">
              <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
              <div className="text-wrapper-6">0.65%</div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-5">
              <div className="group">
                <img className="vector-2" alt="Vector" src="/img/crypto-market-arrow-up.svg" />
                <div className="text-wrapper-6">0.65%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-6">
          <div className="frame-6">
            <div className="text-wrapper-7">₩1,502,989,963,439,782</div>
          </div>
          <div className="frame-7">
            <div className="text-wrapper-7">₩51,502,989,963,439</div>
            <div className="text-wrapper-8">932,071 BTC</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="text-wrapper-7">18,648,248 BTC</div>
              <Vector className="info" color="#A8B0C1" />
            </div>
            <img className="graph" alt="Graph" src="/img/crypto-market-graph.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};
