import React from "react";

export const LogoYoutube = ({ className }) => {
  return (
    <svg
      className={`logo-youtube ${className}`}
      fill="none"
      height="75"
      viewBox="0 0 75 75"
      width="75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M69.354 21.3168C68.9677 19.8894 68.2142 18.588 67.1686 17.5424C66.123 16.4967 64.8217 15.7432 63.3943 15.3569C58.1685 13.9316 37.1365 13.9316 37.1365 13.9316C37.1365 13.9316 16.1034 13.9748 10.8776 15.4C9.45015 15.7863 8.14881 16.5399 7.10318 17.5856C6.05755 18.6313 5.30412 19.9327 4.9179 21.3602C3.33722 30.6454 2.72405 44.7939 4.9613 53.7077C5.34756 55.1351 6.10102 56.4364 7.14664 57.4821C8.19227 58.5278 9.49358 59.2813 10.921 59.6676C16.1468 61.0928 37.1793 61.0928 37.1793 61.0928C37.1793 61.0928 58.2116 61.0928 63.4371 59.6676C64.8646 59.2813 66.166 58.5279 67.2117 57.4822C68.2573 56.4365 69.0108 55.1351 69.3971 53.7077C71.0643 44.4093 71.5781 30.2695 69.354 21.3168Z"
        fill="#FF0000"
      />
      <path className="path" d="M30.4423 47.6181L47.8899 37.5122L30.4423 27.4062V47.6181Z" fill="white" />
    </svg>
  );
};
