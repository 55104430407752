/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Configuration } from "../Configuration";
import { ConfigurationWrapper } from "../ConfigurationWrapper";
import { DivWrapper } from "../DivWrapper";
import { MarketStatsBlock } from "../MarketStatsBlock";
import { MoreButtonOld } from "../MoreButton";
import "./style.css";
import {
  MOBILE_HEADER_DROPDOWN_MENU,
  MOBILE_HEADER_SETTINGS, MOBILE_HEADER_NONE,
  MOBILE_HEADER_STATISTICS, PAGE_HOME, PAGE_WALLET_MANAGEMENT, PAGE_CONFIGURATION
} from "../../constants";

export const MobileHeaderPanel = ({ selectedMenuItem, currentPage, currentProject, onHeaderMenuItemClick = () => {}, onPageChange = () => {}, onProjectChange = () => {} }) => {
  const handleHeaderMenuItemClick = menuItem => () => {
    onHeaderMenuItemClick(menuItem);
  }
  return (
    <div className="mobile-header-panel">
      <div className={`div ${selectedMenuItem}`}>
        <div className="header-panel-header">
          <img className="xception-logo" alt="Xception logo" src="/img/xception-logo.png" />
          <div className="header-panel-header-menu">
            <img className="img-2" onClick={handleHeaderMenuItemClick(MOBILE_HEADER_STATISTICS)} src={`/img/${selectedMenuItem === MOBILE_HEADER_STATISTICS ? 'solar-chart-square-bold-duotone.svg' : 'solar-chart-square-outline.svg'}`}/>
            <img className="img-2" onClick={handleHeaderMenuItemClick(MOBILE_HEADER_SETTINGS)} src={`/img/${selectedMenuItem === MOBILE_HEADER_SETTINGS ? 'solar-settings-bold-duotone.svg' : 'solar-settings-linear.svg'}`}/>
            <img className="img-2" onClick={handleHeaderMenuItemClick(MOBILE_HEADER_DROPDOWN_MENU)} src={`/img/${selectedMenuItem === MOBILE_HEADER_DROPDOWN_MENU ? 'solar-close-circle-line-duotone.svg' : 'solar-hamburger-menu-outline.svg'}`}/>
          </div>
        </div>
        {[MOBILE_HEADER_DROPDOWN_MENU, MOBILE_HEADER_STATISTICS].includes(selectedMenuItem) && (
          <>
            <div className="header-panel-menu-2">
              {selectedMenuItem === MOBILE_HEADER_DROPDOWN_MENU && (
                <>
                  <div className={`header-panel-menu-3${currentPage === PAGE_HOME ? ' active' : ''}`} onClick={() => onPageChange(PAGE_HOME)}>
                    <div className="text-wrapper">Home</div>
                  </div>
                  <div className={`header-panel-menu-3${currentPage === PAGE_CONFIGURATION ? ' active' : ''}`} onClick={() => onPageChange(PAGE_CONFIGURATION)}>
                    <div className="text-wrapper">Configuration</div>
                  </div>
                  <div className={`header-panel-menu-3${currentPage === PAGE_WALLET_MANAGEMENT ? ' active' : ''}`} onClick={() => onPageChange(PAGE_WALLET_MANAGEMENT)}>
                    <div className="text-wrapper">Wallet Management</div>
                  </div>
                  {/*<div className="header-panel-menu-4">*/}
                  {/*  <div className="text-wrapper-2">Products</div>*/}
                  {/*  <img className="polygon" alt="Polygon" src="/img/polygon-2.svg" />*/}
                  {/*</div>*/}
                  {/*<div className="header-panel-menu-3">*/}
                  {/*  <div className="text-wrapper">MevCeption</div>*/}
                  {/*</div>*/}
                  {/*<div className="header-panel-menu-5">*/}
                  {/*  <div className="text-wrapper-2">TradeCeption</div>*/}
                  {/*</div>*/}
                  {/*<div className="header-panel-menu-3">*/}
                  {/*  <div className="text-wrapper">CopyCeption</div>*/}
                  {/*</div>*/}
                  {/*<div className="header-panel-menu-3">*/}
                  {/*  <div className="text-wrapper">MarketCeptoin</div>*/}
                  {/*</div>*/}
                  {/*<div className="header-panel-menu-3">*/}
                  {/*  <div className="text-wrapper">GameCeption</div>*/}
                  {/*</div>*/}
                </>
              )}

              {selectedMenuItem === MOBILE_HEADER_STATISTICS && (
                <>
                  <Configuration
                    className="configuration-instance"
                    configurationPrice="/img/configuration-price-icon.svg"
                    stopLimitDropdown="/img/stop-limit-dropdown-button.svg"
                    stopLimitDropdownClassName="configuration-content-limit"
                  />
                  <ConfigurationWrapper
                    className="configuration-instance"
                    delimiter="/img/delimiter-1.svg"
                    img="/img/delimiter-1.svg"
                  />
                  <DivWrapper buyIcon="/img/buy-icon.png" className="configuration-instance" />
                </>
              )}
            </div>
          </>
        )}

        {selectedMenuItem === MOBILE_HEADER_SETTINGS && (
          <>
            <div className="mobile-header">
              <div className="market-stats-info">
                <div className="market-stats-info-2">
                  <p className="p">
                    <span className="span">PNDC:</span>
                    <span className="text-wrapper-3"> 0X423...1EEA</span>
                  </p>
                  <img className="group" alt="Group" src="/img/group-36762-2.png" />
                </div>
                <div className="market-stats-info-2">
                  <p className="p">
                    <span className="span">PAIR: </span>
                    <span className="text-wrapper-3">0X423...1EEA</span>
                  </p>
                  <img className="img" alt="Group" src="/img/group-36762-2.png" />
                </div>
              </div>
              <div className="market-stats-info-3">
                <p className="POOL-UNISWAP">
                  <span className="span">POOL </span>
                  <span className="text-wrapper-3">UNISWAP V3</span>
                </p>
                <div className="percent-button">
                  <div className="block">
                    <div className="overlap-group">
                      <div className="text-wrapper-4">0.3%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="market-stats">
                <div className="market-stats-row">
                  <MarketStatsBlock caption="MARKET CAP" className="market-stats-block-instance" value="$37.00M" />
                  <MarketStatsBlock caption="LIQUIDITY" className="market-stats-block-instance" value="$531.35k" />
                </div>
                <div className="market-stats-row">
                  <MarketStatsBlock caption="CIRC. SUPPLY" className="market-stats-block-instance" value="$37.00M" />
                  <MarketStatsBlock caption="HOLDERS" className="market-stats-block-instance" value="$531.35k" />
                </div>
                <div className="market-stats-row">
                  <MarketStatsBlock caption="TOTAL MKTCAP" className="market-stats-block-instance" value="$37.00M" />
                  <MarketStatsBlock caption="24H VOLUME" className="market-stats-block-instance" value="$531.35k" />
                </div>
                <div className="market-stats-row">
                  <MarketStatsBlock caption="% CIRC. SUPPLY" className="market-stats-block-instance" value="$00.00M" />
                  <MarketStatsBlock
                    caption="POOL CREATED"
                    className="market-stats-block-instance"
                    value="8/4/2023 22:48"
                  />
                </div>
                <div className="market-stats-row">
                  <MarketStatsBlock caption="POOLED WETH" className="market-stats-block-instance" value="-" />
                  <MarketStatsBlock caption="POOLED PNDC" className="market-stats-block-instance" value="-" />
                </div>
                <div className="market-stats-row">
                  <MarketStatsBlock caption="% POOLED PNDC" className="market-stats-block-instance" value="-" />
                  <MarketStatsBlock caption="1 ETH" className="market-stats-block-instance" value="1.01B" />
                </div>
              </div>
              <MoreButtonOld caption="Less info" className="more-button-instance" icon="/img/icon-1.svg" />
            </div>
            <div className="mobile-settings">
              <div className="settings-properties">
                <div className="settings-properties-2">
                  <div className="settings-properties-3">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Contract Verification</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">Yes</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-4">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Honeypot</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">No</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-5">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Buy Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">1%</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-6">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Sell Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">5%</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-7">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Transfer Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">5%</div>
                    </div>
                    <img className="img-2" alt="Icon cross" src="/img/icon-cross.png" />
                  </div>
                  <div className="settings-properties-8">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Holders</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">791</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-9">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Siphoned</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">0</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-10">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Average Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">0</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-11">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Average Gas</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">0</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-12">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Highest Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">3</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-13">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Average Gas</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">0</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-14">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Highest Tax</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">5%</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-15">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">High tax Wallets</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">0</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-16">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Proxy Call Risk</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">No</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
                <div className="settings-properties-2">
                  <div className="settings-properties-17">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Contract Verification</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">No</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                  <div className="settings-properties-18">
                    <div className="settings-property">
                      <div className="icon-question">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-5">?</div>
                        </div>
                      </div>
                      <div className="text-wrapper-6">Transfer Blocked</div>
                    </div>
                    <div className="settings-property-2">
                      <div className="text-wrapper-7">No</div>
                    </div>
                    <img className="img-2" alt="Icon tick" src="/img/icon-tick.png" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

MobileHeaderPanel.propTypes = {
  statistics: PropTypes.bool,
  selectedMenuItem: PropTypes.oneOf([MOBILE_HEADER_NONE, MOBILE_HEADER_DROPDOWN_MENU, MOBILE_HEADER_STATISTICS, MOBILE_HEADER_SETTINGS]),
};
