/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {BuySellBlock} from "../BuySellBlock";

export const Configuration = ({
  className,
  stopLimitDropdownClassName,
  stopLimitDropdown = "/img/stop-limit-dropdown-button-1.svg",
  configurationPrice = "/img/configuration-price-icon-1.svg",
}) => {
  return (
    <div className={`configuration ${className}`}>
      <div className="configuration-buy">
        <BuySellBlock/>
        <div className="configuration-spot">
          <div className="spot-option">
            <div className="text-wrapper-10">Limit</div>
          </div>
          <div className="market-wrapper">
            <div className="market">Market</div>
          </div>
          <div className="stop-limit-dropdown-wrapper">
            <div className={`stop-limit-dropdown ${stopLimitDropdownClassName}`}>
              <div className="text-wrapper-11">Stop-limit</div>
              <img className="stop-limit-dropdown-2" alt="Stop limit dropdown" src={stopLimitDropdown} />
            </div>
          </div>
        </div>
      </div>
      <div className="configuration-price">
        <div className="configuration-price-2">
          <img className="configuration-price-3" alt="Configuration price" src={configurationPrice} />
          <div className="text-wrapper-11">3.7 Eth</div>
        </div>
        <div className="price-input-wrapper">
          <div className="price-input">
            <div className="text-wrapper-11">Price</div>
            <div className="price-value">
              <div className="text-wrapper-12">0.0005</div>
              <div className="text-wrapper-11">Eth</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Configuration.propTypes = {
  stopLimitDropdown: PropTypes.string,
  configurationPrice: PropTypes.string,
};
