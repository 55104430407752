/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Circle } from "../Circle";
import "./style.css";

export const PositionBlock = ({
  value = "0",
  caption = "Caption",
  className,
  divClassName,
  positionCircleClassName,
}) => {
  return (
    <div className={`position-block ${className}`}>
      <div className="caption">{caption}</div>
      <div className={`position-circle ${positionCircleClassName}`}>
        <Circle className="circle-instance" />
        <div className={`element ${divClassName}`}>{value}</div>
      </div>
    </div>
  );
};

PositionBlock.propTypes = {
  value: PropTypes.string,
  caption: PropTypes.string,
};
