/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { SettingsInput } from "../SettingsInput";
import "./style.css";

export const FormField = ({ label = "Label", className }) => {
  return (
    <div className={`form-field ${className}`}>
      <div className="label">{label}</div>
      <SettingsInput className="settings-input-instance" />
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
};
