/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ReferralBlock } from "../ReferralBlock";
import "./style.css";

export const PageContentGameCeptionReferrrals = ({ className }) => {
  return (
    <div className={`page-content-game-ception-referrals ${className}`}>
      <div className="referrals-info">
        <div className="referral-summary">
          <div className="text-wrapper">User Refereed</div>
          <div className="frame">
            <div className="div">2</div>
          </div>
        </div>
        <div className="referral-summary-2">
          <div className="text-wrapper">Referral Points Gained</div>
          <div className="frame">
            <div className="overlap-group">
              <div className="text-wrapper-2">562</div>
              <img className="arc-graph" alt="Arc graph" src="/img/arc-graph-3.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="referrals-container">
        <div className="referrals-row">
          <ReferralBlock caption="Refereed Wallet" className="referral-block-instance" type="selected" />
          <ReferralBlock caption="Points Gained From Wallet" className="referral-block-instance" type="default" />
          <ReferralBlock caption="Position of Refereed Wallets" className="referral-block-instance" type="default" />
        </div>
        <div className="referrals-row">
          <ReferralBlock caption="0x8d69...4522c1" className="referral-block-instance" type="default" />
          <ReferralBlock caption="500" className="referral-block-instance" type="default" />
          <ReferralBlock caption="37" className="referral-block-instance" type="default" />
        </div>
        <div className="referrals-row">
          <ReferralBlock caption="0xa905.....41d071" className="referral-block-instance" type="default" />
          <ReferralBlock caption="12" className="referral-block-instance" type="default" />
          <ReferralBlock caption="658" className="referral-block-instance" type="default" />
        </div>
      </div>
    </div>
  );
};
