/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useState} from "react";
import {MoreButtonOld} from "../MoreButton";
import "./style.css";
import {Button} from "../Button";
import {SwapIcon} from "../../icons/SwapIcon";

const STATE_OPENED = "opened";
const STATE_CLOSED = "closed";

export const Xceptionswap = ({withBackground = true, state = STATE_CLOSED, showCollapse = true}) => {
  const [currentState, setCurrentState] = useState(state);
  const handleStateChange = () => setCurrentState(currentState === STATE_OPENED ? STATE_CLOSED : STATE_OPENED);
  const [fromValue, setFromValue] = useState("10");
  const [toValue, setToValue] = useState("");
  const isExpanded = currentState === STATE_OPENED;
  const [swapped, setSwapped] = useState(false);

  const elementFrom = () => (
    <div className="xceptionswap-from">
      <div className="xceptionswap-form">
        <div className="input-container">
          <div className="currency-chooser">
            <div className="text-wrapper-2">Xce...</div>
            <img className="vector" alt="Vector" src="/img/vector-57.svg"/>
          </div>
          <input placeholder={"0.00"} className={"input"} value={fromValue}
                 onChange={({value}) => setFromValue(value)}/>
        </div>
        <div className="balance-container">
          <div className="text-wrapper-3">Balance:</div>
        </div>
      </div>
    </div>
  );

  const elementTo = () => (
    <div className="xceptionswap-to">
      <div className="xceptionswap-form">
        <div className="input-container">
          <div className="currency-chooser">
            <div className="text-wrapper-2">Eth</div>
            <img className="vector" alt="Vector" src="/img/vector-57.svg"/>
          </div>
          <input className="input" placeholder={"0.00"} value={toValue} onChange={({value}) => setToValue(value)}/>
        </div>
        <div className="balance-container">
          <div className="text-wrapper-3">Balance:</div>
        </div>
      </div>
    </div>
  )
  return (
    <div className={`xceptionswap${isExpanded ? ' expanded' : ''}${withBackground ? ' backgrounded' : ''}`}>
      {showCollapse && <div className="xceptionswap-header" onClick={handleStateChange}>
        <img src="/img/xceptionswap-icon.svg"/>
        <span>XCeptionSwap</span>
        <img src={isExpanded ? "/img/xceptionswap-icon-up.svg" : "/img/xceptionswap-icon-down.svg"}/>
      </div>}
      {isExpanded && <div className="xceptionswap-content">
        <div className="xceptionswap-fields-container">
          {swapped ? elementTo() : elementFrom()}
          {swapped ? elementFrom() : elementTo()}
          <SwapIcon onClick={() => setSwapped(!swapped)} className="xceptionswap-change"/>
        </div>
        <div className="xceptionswap-button">
          <Button caption={"CONNECT WALLET"} fullwidth={true}/>
        </div>
        <div className="xceptionswap-info">
          <div className="xceptionswap-info-row">
            <div className="xceptionswap-info-label">Price per XCEPT</div>
            <div className="xceptionswap-info-value">0.2609</div>
          </div>
          <div className="xceptionswap-info-row">
            <div className="xceptionswap-info-label">Slippage</div>
            <div className="xceptionswap-info-value">
              <span>Auto</span>
              <img className="frame-4" alt="Frame" src="/img/xceptionswap-settings-icon.svg"/>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

Xceptionswap.propTypes = {
  state: PropTypes.oneOf([STATE_CLOSED, STATE_OPENED]),
};
