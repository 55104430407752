import React from 'react';

export const DropdownArrow = ({className}) => {
  return (
    <svg className={className} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2011_243)">
        <path d="M11.5 4.56836L6.14286 8.56836L1.5 4.56836" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2011_243">
          <rect width="12" height="12" fill="white" transform="translate(0.5 0.568359)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
