/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ArcGraph5 } from "../../icons/ArcGraph5";
import { Chart1 } from "../../icons/Chart1";
import { Chart2 } from "../../icons/Chart2";
import { ChartMini1 } from "../../icons/ChartMini1";
import { ChartMini12 } from "../../icons/ChartMini12";
import { ChartMini2 } from "../../icons/ChartMini2";
import { ChartMini22 } from "../../icons/ChartMini22";
import { ChartMini31 } from "../../icons/ChartMini31";
import { ChartMini4 } from "../../icons/ChartMini4";
import { CircleGraph7 } from "../../icons/CircleGraph7";
import { Circle } from "../Circle";
import "./style.css";

export const PageContentGameCeptionTradeStatistics = ({ screen, className }) => {
  return (
    <div className={`page-content-game-ception-trade-statistics ${screen} ${className}`}>
      <div className="text-wrapper">Trade Statistics</div>
      {screen === "mobile" && (
        <>
          <div className="trade-statistics">
            <div className="div">
              <div className="chart-block">
                <div className="text-wrapper-2">Win vs Losses</div>
                <div className="frame">
                  <ArcGraph5 className="arc-graph" />
                </div>
                <div className="frame-2">
                  <div className="frame-3">
                    <div className="ellipse" />
                    <div className="text-wrapper-3">Win</div>
                  </div>
                  <div className="frame-4">
                    <div className="ellipse-2" />
                    <div className="text-wrapper-3">Loss</div>
                  </div>
                </div>
              </div>
              <div className="chart-block">
                <div className="text-wrapper-2">Buy vs Sells</div>
                <div className="frame">
                  <ArcGraph5 className="arc-graph" />
                </div>
                <div className="frame-2">
                  <div className="frame-3">
                    <div className="ellipse" />
                    <div className="text-wrapper-3">Bus</div>
                  </div>
                  <div className="frame-4">
                    <div className="ellipse-2" />
                    <div className="text-wrapper-3">Sells</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trade-statistics-2">
            <div className="div-2">
              <div className="div-3">
                <div className="text-wrapper-4">123</div>
                <div className="text-wrapper-5">Day</div>
                <div className="chart-mini-wrapper">
                  <ChartMini12 className="chart-mini" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
              <div className="div-3">
                <div className="text-wrapper-4">123</div>
                <div className="text-wrapper-5">Week</div>
                <div className="chart-mini-2-2-wrapper">
                  <ChartMini22 className="chart-mini-2-2" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
            </div>
            <div className="div-2">
              <div className="div-3">
                <div className="text-wrapper-4">123</div>
                <div className="text-wrapper-5">Month</div>
                <div className="chart-mini-3-1-wrapper">
                  <ChartMini31 className="chart-mini-3-1" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
              <div className="div-3">
                <div className="text-wrapper-4">123</div>
                <div className="text-wrapper-5">Year</div>
                <div className="chart-mini-wrapper">
                  <ChartMini4 className="chart-mini" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
            </div>
          </div>
          <div className="trade-statistics-3">
            <div className="text-wrapper-6">TradeCeption Volume</div>
            <div className="div-2">
              <div className="chart-block-2">
                <div className="chart-mini-1-wrapper">
                  <ChartMini1 className="chart-mini-1" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
              <div className="div-4">
                <div className="chart-mini-2-wrapper">
                  <ChartMini2 className="chart-mini-2" />
                </div>
                <div className="text-wrapper-5">Lorem</div>
              </div>
            </div>
          </div>
          <div className="trade-statistics-2">
            <div className="div-2">
              <div className="div-4">
                <div className="text-wrapper-2">ETH Bought</div>
                <div className="circle-graph-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30098)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
              <div className="div-4">
                <div className="text-wrapper-2">ETH Bought</div>
                <div className="circle-graph-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30110)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
              <div className="div-3">
                <div className="text-wrapper-2">Gas Fees Paid</div>
                <div className="circle-graph-7-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30122)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
            </div>
          </div>
          <div className="trade-statistics-2">
            <div className="div-2">
              <div className="div-3">
                <div className="text-wrapper-2">ETH Bought USD</div>
                <div className="circle-graph-7-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30134)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
              <div className="div-3">
                <div className="text-wrapper-2">ETH Sold USD</div>
                <div className="circle-graph-7-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30146)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
              <div className="div-3">
                <div className="text-wrapper-2">Gas Fees Paid USD</div>
                <div className="circle-graph-7-wrapper">
                  <CircleGraph7 className="circle-graph" color="url(#paint0_linear_2004_30158)" />
                </div>
                <div className="text-wrapper-5">$5.984</div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="trade-statistics-4">
        <div className="trade-statistics-5">
          {screen === "desktop" && (
            <>
              <div className="div-wrapper">
                <div className="text-wrapper-7">Win vs Losses</div>
              </div>
              <div className="arc-graph-wrapper">
                <ArcGraph5 className="arc-graph-5" />
              </div>
              <div className="frame-5">
                <div className="frame-3">
                  <div className="ellipse" />
                  <div className="text-wrapper-8">Win</div>
                </div>
                <div className="frame-4">
                  <div className="ellipse-2" />
                  <div className="text-wrapper-8">Loss</div>
                </div>
              </div>
            </>
          )}

          {screen === "mobile" && (
            <>
              <div className="text-wrapper-2">Biggest Yet</div>
              <div className="frame-6">
                <div className="text-wrapper-9">99%</div>
                <Circle className="circle-instance" />
              </div>
            </>
          )}
        </div>
        <div className="trade-statistics-6">
          <div className="mevception-volume">
            {screen === "desktop" && <>MevCeption Volume</>}

            {screen === "mobile" && <>Biggest Win</>}
          </div>
          <div className="frame-7">
            {screen === "desktop" && (
              <>
                <div className="column">
                  <div className="frame-8">
                    <div className="text-wrapper-10">1</div>
                    <div className="text-wrapper-11">Day</div>
                  </div>
                  <img className="mini-chart" alt="Mini chart" src="/img/mini-chart-6.svg" />
                  <div className="frame-9">
                    <div className="text-wrapper-8">Volume</div>
                  </div>
                </div>
                <div className="column">
                  <div className="frame-8">
                    <div className="text-wrapper-10">1</div>
                    <div className="text-wrapper-11">Week</div>
                  </div>
                  <img className="mini-chart" alt="Mini chart" src="/img/mini-chart-4.svg" />
                  <div className="frame-9">
                    <div className="text-wrapper-8">Volume</div>
                  </div>
                </div>
                <div className="column">
                  <div className="frame-8">
                    <div className="text-wrapper-10">1</div>
                    <div className="text-wrapper-11">Month</div>
                  </div>
                  <img className="mini-chart" alt="Mini chart" src="/img/mini-chart-2.svg" />
                  <div className="frame-9">
                    <div className="text-wrapper-8">Volume</div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="frame-8">
                    <div className="text-wrapper-10">1</div>
                    <div className="text-wrapper-11">Year</div>
                  </div>
                  <img className="mini-chart" alt="Mini chart" src="/img/mini-chart.svg" />
                  <div className="frame-9">
                    <div className="text-wrapper-8">Volume</div>
                  </div>
                </div>
              </>
            )}

            {screen === "mobile" && (
              <>
                <Circle className="circle-instance" />
                <div className="text-wrapper-12">6100%</div>
              </>
            )}
          </div>
        </div>
        <div className="trade-statistics-7">
          {screen === "desktop" && (
            <>
              <div className="div-wrapper">
                <div className="text-wrapper-7">Buy vs Sells</div>
              </div>
              <div className="arc-graph-wrapper">
                <ArcGraph5 className="arc-graph-5" />
              </div>
              <div className="frame-5">
                <div className="frame-3">
                  <div className="ellipse" />
                  <div className="text-wrapper-8">Approved</div>
                </div>
                <div className="frame-4">
                  <div className="ellipse-2" />
                  <div className="text-wrapper-8">Not Approved</div>
                </div>
              </div>
            </>
          )}

          {screen === "mobile" && (
            <>
              <div className="text-wrapper-2">Wallet Connected</div>
              <div className="frame-10">
                <Circle className="circle-instance" />
                <div className="text-wrapper-13">3</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="trade-statistics-8">
        <div className="graph-block">
          {screen === "mobile" && (
            <>
              <div className="text-wrapper-2">Mevception</div>
              <ArcGraph5 className="arc-graph-13" />
              <div className="div">
                <div className="frame-3">
                  <div className="ellipse" />
                  <div className="text-wrapper-3">Win</div>
                </div>
                <div className="frame-11">
                  <div className="ellipse-2" />
                  <div className="text-wrapper-3">Loss</div>
                </div>
              </div>
            </>
          )}

          {screen === "desktop" && (
            <>
              <div className="div-wrapper">
                <div className="text-wrapper-7">Contract Sniped</div>
              </div>
              <div className="group-wrapper">
                <div className="group">
                  <div className="overlap-group">
                    <div className="text-wrapper-14">99</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="graph-block-2">
          <div className="total-market-maker">
            {screen === "mobile" && (
              <>
                Total Market Maker <br />
                Trade
              </>
            )}

            {screen === "desktop" && <>TradeCeption Volume</>}
          </div>
          <div className="group-2">
            {screen === "mobile" && (
              <div className="overlap-group-2">
                <div className="text-wrapper-15">547</div>
                <Circle className="instance-node" />
              </div>
            )}

            {screen === "desktop" && (
              <>
                <div className="frame-12">
                  <Chart1 className="chart" />
                  <div className="text-wrapper-16">Volume</div>
                </div>
                <div className="frame-12">
                  <Chart2 className="chart-2" />
                  <div className="text-wrapper-16">Volume</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="graph-block-3">
          {screen === "mobile" && (
            <>
              <div className="text-wrapper-2">
                Tradeception Copy
                <br />
                Trade
              </div>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <div className="text-wrapper-15">300</div>
                  <Circle className="instance-node" />
                </div>
              </div>
            </>
          )}

          {screen === "desktop" && (
            <>
              <div className="div-wrapper">
                <div className="text-wrapper-7">MEVS</div>
              </div>
              <div className="arc-graph-wrapper">
                <ArcGraph5 className="arc-graph-11" />
              </div>
              <div className="frame-13">
                <div className="frame-3">
                  <div className="ellipse" />
                  <div className="text-wrapper-8">Approved</div>
                </div>
                <div className="frame-4">
                  <div className="ellipse-2" />
                  <div className="text-wrapper-8">Not Approved</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {screen === "desktop" && (
        <>
          <div className="graphs-row">
            <div className="graphs-block">
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">ETH Bought</div>
                </div>
                <div className="frame-15" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">ETH Sold</div>
                </div>
                <div className="frame-16" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">Gas Fees Paid</div>
                </div>
                <div className="frame-17" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
            </div>
            <div className="graphs-block">
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">ETH Bought USD</div>
                </div>
                <div className="frame-18" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">ETH Sold USD</div>
                </div>
                <div className="frame-19" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
              <div className="trade-statistics-9">
                <div className="frame-14">
                  <div className="text-wrapper-7">Gas Fees Paid USD</div>
                </div>
                <div className="frame-20" />
                <div className="frame-9">
                  <div className="text-wrapper-8">$5.984</div>
                </div>
              </div>
            </div>
          </div>
          <div className="graphs-row">
            <div className="graphs-block-2">
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">Biggest Yeet</div>
                </div>
                <div className="frame-21">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-17">99%</div>
                    <Circle className="circle-2" />
                  </div>
                </div>
              </div>
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">Biggest Win</div>
                </div>
                <div className="frame-22">
                  <Circle className="circle-3" />
                  <div className="text-wrapper-18">6100%</div>
                </div>
              </div>
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">Wallets Connected</div>
                </div>
                <div className="frame-22">
                  <Circle className="circle-3" />
                  <div className="text-wrapper-19">3</div>
                </div>
              </div>
            </div>
            <div className="graphs-block-3">
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">Mevception</div>
                </div>
                <div className="arc-graph-5-wrapper">
                  <ArcGraph5 className="arc-graph-3" />
                </div>
                <div className="frame-9">
                  <div className="frame-5">
                    <div className="frame-3">
                      <div className="ellipse" />
                      <div className="text-wrapper-8">Win</div>
                    </div>
                    <div className="frame-4">
                      <div className="ellipse-2" />
                      <div className="text-wrapper-8">Loss</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">
                    Total Market Maker <br />
                    Trade
                  </div>
                </div>
                <div className="frame-23">
                  <div className="text-wrapper-20">547</div>
                  <Circle className="circle-3" />
                </div>
              </div>
              <div className="column-3">
                <div className="frame-14">
                  <div className="text-wrapper-7">Trade</div>
                </div>
                <div className="frame-23">
                  <div className="text-wrapper-21">300</div>
                  <Circle className="circle-3" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PageContentGameCeptionTradeStatistics.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
};
