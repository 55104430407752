import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import TradingViewWidget from "../PageContentTradingView/PageContentTradingView";
import {Xceptionswap} from "../Xceptionswap";

export const PageContentChartSectionDesktop = ({}) => {
  return (
    <div className="page-content-chart-section desktop">
      <TradingViewWidget/>
      <Xceptionswap showCollapse={true}/>
    </div>
  )
}
